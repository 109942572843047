import {
	QueryClient,
	QueryClientProvider as ReactQueryProvider,
} from '@tanstack/react-query';

export const queryClient = new QueryClient();

type QueryClientProviderProps = {
	children: React.ReactNode;
};

export default function QueryClientProvider({
	children,
}: QueryClientProviderProps) {
	return (
		<ReactQueryProvider client={queryClient}>{children}</ReactQueryProvider>
	);
}
