import * as React from 'react';
interface StatsBarIconBlueProps {
	className?: string;
	style?: React.CSSProperties;
	color?: string;
}

const StatsBarIcon = (props: StatsBarIconBlueProps) => (
	<svg
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M27.4286 0C29.95 0 32 2.04643 32 4.57143V27.4286C32 29.95 29.95 32 27.4286 32H4.57143C2.04643 32 0 29.95 0 27.4286V4.57143C0 2.04643 2.04643 0 4.57143 0H27.4286ZM9.14286 13.7143C7.87857 13.7143 6.85714 14.7357 6.85714 16V22.8571C6.85714 24.1214 7.87857 25.1429 9.14286 25.1429C10.4071 25.1429 11.4286 24.1214 11.4286 22.8571V16C11.4286 14.7357 10.4071 13.7143 9.14286 13.7143ZM13.7143 22.8571C13.7143 24.1214 14.7357 25.1429 16 25.1429C17.2643 25.1429 18.2857 24.1214 18.2857 22.8571V9.14286C18.2857 7.87857 17.2643 6.85714 16 6.85714C14.7357 6.85714 13.7143 7.87857 13.7143 9.14286V22.8571ZM22.8571 18.2857C21.5929 18.2857 20.5714 19.3071 20.5714 20.5714V22.8571C20.5714 24.1214 21.5929 25.1429 22.8571 25.1429C24.1214 25.1429 25.1429 24.1214 25.1429 22.8571V20.5714C25.1429 19.3071 24.1214 18.2857 22.8571 18.2857Z"
			fill={props.color !== '' ? props.color : '#009EF7'}
		/>
	</svg>
);

export default StatsBarIcon;
