import * as React from 'react';

const Estudiante = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 34 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0 8C0 3.58172 3.58172 0 8 0H26C30.4183 0 34 3.58172 34 8V28C34 32.4183 30.4183 36 26 36H8C3.58172 36 0 32.4183 0 28V8Z"
			fill="#F1FAFF"
		/>
		<path
			d="M10.4259 12.4922L10.625 12.5391V14.3672C10.4062 14.4969 10.25 14.7281 10.25 15C10.25 15.2617 10.3945 15.4806 10.5975 15.6172L10.1094 17.5625C10.0547 17.7781 10.1759 18 10.3478 18H11.6525C11.8244 18 11.9455 17.7773 11.8908 17.5906L11.4025 15.6188C11.6056 15.4813 11.75 15.2625 11.75 15C11.75 14.7266 11.5938 14.4963 11.375 14.3672V12.7228L13 13.1134L13 15C13 17.2109 14.7891 19 17 19C19.211 19 20.9719 17.2109 20.9719 15L21 13.1131L23.5703 12.4922C24.1406 12.3555 24.1406 11.6484 23.5703 11.5078L17.6203 10.0703C17.2141 9.97656 16.7884 9.97656 16.3819 10.0703L10.4256 11.5078C9.85937 11.6447 9.85938 12.3556 10.4259 12.4922ZM20.225 19.775L17 23L13.775 19.775C11.5897 20.4719 10 22.5 10 24.9156C10 25.5156 10.4853 26 11.0834 26H22.9178C23.5156 26 24 25.5156 24 24.9156C24 22.5 22.4094 20.4719 20.225 19.775Z"
			fill="#009EF7"
		/>
	</svg>
);

export default Estudiante;
