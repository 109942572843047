import types, {
	RegisterConfirmUserAction,
	SocialLoginUserAction,
	UpdatePhotoUserAction,
	UpdateUserAction,
} from './types';
import {
	all,
	call,
	delay,
	put,
	race,
	SagaReturnType,
	select,
	take,
	takeLatest,
} from 'redux-saga/effects';
import {
	setLoginLoadingAction,
	loginUserWithEmailPasswordSuccessAction,
	loginUserWithEmailPasswordErrorAction,
	SetRegisterConfirmUserLoading,
	registerConfirmUserErrorAction,
	registerConfirmUserSuccess,
	registerConfirmUserStatusAction,
	updateUserErrorAction,
	updateUserSuccessAction,
	updatePhotoUserSuccessAction,
	setUpdateLoadingAction,
	updatePhotoUserLoadingAction,
	getUserRoutersSuccessAction,
} from './actions';
import { LoginUserWithEmailPasswordAction } from './types';

import Api, { IUsersApi } from '@Api/index';
import { getErrorInfo } from '@Configs/errors';
import { queryClient } from '@Configs/Providers/QueryClientProvider';
import { StoreType } from '@Redux/store/types';

function* sagaLoginUserWithEmailPassword({
	payload: { email, password },
}: LoginUserWithEmailPasswordAction) {
	try {
		yield put(setLoginLoadingAction(true));
		const userData: SagaReturnType<IUsersApi['loginUserWithEmailAndPassword']> =
			yield call(Api.Users.loginUserWithEmailAndPassword, email, password);
		console.log('afterCall');
		queryClient.invalidateQueries();

		yield put(loginUserWithEmailPasswordSuccessAction(userData));
	} catch (error) {
		const currentError = getErrorInfo(error);
		console.log(currentError);
		yield put(loginUserWithEmailPasswordErrorAction(currentError));
	}
}

function* sagaUserRegisterConfirm({
	payload: { token = '' },
}: RegisterConfirmUserAction) {
	try {
		yield put(SetRegisterConfirmUserLoading(true));

		const userRegisterConfirmData: SagaReturnType<
			IUsersApi['confirmEmailMutation']
		> = yield call(Api.Users.confirmEmailMutation, token ?? '');

		if (userRegisterConfirmData.status === 'success') {
			yield put(
				registerConfirmUserStatusAction(userRegisterConfirmData.status)
			);
			queryClient.invalidateQueries();
		} else {
			throw userRegisterConfirmData.error;
		}

		if (userRegisterConfirmData.values) {
			yield put(registerConfirmUserSuccess(userRegisterConfirmData.values));
		} else {
			throw userRegisterConfirmData.error;
		}
	} catch (error) {
		const currentError = getErrorInfo(error);
		console.log(currentError);
		yield put(registerConfirmUserErrorAction(currentError));
	}
}

export function* sagaSocialLoginUser({
	payload: { code },
}: SocialLoginUserAction) {
	try {
		const data: SagaReturnType<IUsersApi['teacherGoogleLogin']> = yield call(
			Api.Users.teacherGoogleLogin,
			code
		);

		if (!data.token) throw new Error('No token');

		yield put(loginUserWithEmailPasswordSuccessAction(data));
		queryClient.invalidateQueries();
	} catch (e) {
		const currentError = getErrorInfo(e);
		console.log(currentError);
		yield put(loginUserWithEmailPasswordErrorAction(currentError));
	}
}

export function* sagaUpdateUser({ payload: { data, id } }: UpdateUserAction) {
	yield put(setUpdateLoadingAction(true));

	try {
		const dataPromise: SagaReturnType<IUsersApi['PutUser']> = yield call(
			Api.Users.PutUser,
			data,
			id
		);
		yield put(updateUserSuccessAction(dataPromise));
	} catch (e) {
		const currentError = getErrorInfo(e);
		console.log(currentError);
		yield put(updateUserErrorAction(currentError));
	}
}

export function* sagaUpdatePhotoUser({
	payload: { changes },
}: UpdatePhotoUserAction) {
	yield put(updatePhotoUserLoadingAction(true));

	try {
		const teacherId: string = yield select(
			(state: StoreType) => state.user.data?.teacher?.id
		);

		const dataPromise: SagaReturnType<IUsersApi['PutPhotoUserEdit']> =
			yield call(Api.Users.PutPhotoUserEdit, changes, +teacherId);
		yield delay(1000);

		yield put(updatePhotoUserSuccessAction(dataPromise));
	} catch (e) {
		const currentError = getErrorInfo(e);
		console.log(currentError);
		yield put(updateUserErrorAction(currentError));
	}
}

export function* sagaGetUserRouters() {
	try {
		const routers: SagaReturnType<IUsersApi['getUserRouters']> = yield call(
			Api.Users.getUserRouters
		);
		yield put(getUserRoutersSuccessAction(routers));
	} catch (e) {
		const currentError = getErrorInfo(e);
		console.log(currentError);
		yield put(updateUserErrorAction(currentError));
	}
}

export default function* userSaga() {
	yield all([
		takeLatest(
			types.LOGIN_USER_WITH_EMAIL_PASSWORD,
			sagaLoginUserWithEmailPassword
		),
		takeLatest(types.REGISTER_CONFIRM_USER, sagaUserRegisterConfirm),
		takeLatest(types.SOCIAL_LOGIN_USER, sagaSocialLoginUser),
		takeLatest(types.UPDATE_PHOTO_USER, sagaUpdatePhotoUser),
		takeLatest(types.UPDATE_USER, sagaUpdateUser),
		takeLatest(types.GET_USER_ROUTERS, sagaGetUserRouters),
	]);
}
