import axios from 'axios';

export const REST_API_URL = process.env.REACT_APP_API_URL?.replace(
	'/graphql',
	''
);
const restClient = axios.create({
	baseURL: REST_API_URL,
});

export default restClient;
