import * as React from 'react';
const Smile = () => (
	<svg
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16 22C18.325 22 19.95 20.9062 20.9 19.8813C21.4562 19.275 22.4062 19.2313 23.0187 19.7938C23.625 20.3563 23.6625 21.3062 23.1 21.9125C21.7313 23.4062 19.3563 25 16 25C12.6438 25 10.2687 23.4062 8.9 21.9125C8.3375 21.3062 8.31875 20.3563 8.98125 19.7938C9.59375 19.2313 10.5438 19.275 11.1 19.8813C12.05 20.9062 13.675 22 16 22ZM13.025 13C13.025 14.1062 12.125 15 11.025 15C9.91875 15 9.025 14.1062 9.025 13C9.025 11.8938 9.91875 11 11.025 11C12.125 11 13.025 11.8938 13.025 13ZM19.025 13C19.025 11.8938 19.9187 11 21.025 11C22.125 11 23.025 11.8938 23.025 13C23.025 14.1062 22.125 15 21.025 15C19.9187 15 19.025 14.1062 19.025 13ZM32 16C32 24.8375 24.8375 32 16 32C7.1625 32 0 24.8375 0 16C0 7.1625 7.1625 0 16 0C24.8375 0 32 7.1625 32 16ZM16 3C8.81875 3 3 8.81875 3 16C3 23.1812 8.81875 29 16 29C23.1812 29 29 23.1812 29 16C29 8.81875 23.1812 3 16 3Z"
			fill="#7E8299"
		/>
	</svg>
);
export default Smile;
