import { evaluadosAIRoutes } from '@Routing/EvaluadosAIRoutes';
import { evaluadosAppRoutes } from '@Routing/EvaluadosAppRoutes';
import PrivateRoute from '@Routing/PrivateRoute';
import PublicRoute from '@Routing/PublicRoute';
import { lazy } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

const AuthPage = lazy(() => import('@Pages/Auth'));
const ErrorPage = lazy(() => import('@Pages/Error'));
const ProfileEditionPage = lazy(() => import('@Pages/ProfileEdition'));

function SharedFallbackRoute(props: RouteProps) {
	const { pathname } = useLocation();

	console.log(
		'🚀 ~ file: SharedRoutes.tsx:14 ~ SharedFallbackRoute ~ SharedFallbackRoute:',
		SharedFallbackRoute,
		pathname
	);
	let redirect = false;

	const evaluadosRoutes = [...evaluadosAIRoutes, ...evaluadosAppRoutes];

	if (evaluadosRoutes.find((r) => pathname.includes(r.path))) {
		redirect = true;
	}

	return (
		<Route
			{...props}
			render={(props) =>
				redirect ? (
					<Redirect to="/" {...props} />
				) : (
					<Redirect to="/error" {...props} />
				)
			}
		/>
	);
}

export const sharedRoutes = [
	{
		path: '/auth',
		element: (
			<PublicRoute path="/auth" key="/auth" component={AuthPage} restricted />
		),
	},
	{
		path: '/edit-profile',
		element: (
			<PrivateRoute
				path="/edit-profile"
				key="/edit-profile"
				component={ProfileEditionPage}
			/>
		),
	},

	{
		path: '/error',
		element: <PublicRoute path="/error" key="/error" component={ErrorPage} />,
	},
	{
		path: '/',
		element: (
			<Redirect from="/" to="/edit-profile" key="/shared-redirect" exact />
		),
	},
	{
		path: '*',
		element: <SharedFallbackRoute path="*" key="/shared-redirect2" />,
	},
];
