import * as React from 'react';

const CircleExclamation = () => (
	<svg
		width="63px"
		height="63px"
		viewBox="0 0 63 63"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M63 31.5C63 48.897 48.897 63 31.5 63C14.103 63 0 48.897 0 31.5C0 14.103 14.103 0 31.5 0C48.897 0 63 14.103 63 31.5ZM2.83561 31.5C2.83561 47.3309 15.6691 60.1644 31.5 60.1644C47.3309 60.1644 60.1644 47.3309 60.1644 31.5C60.1644 15.6691 47.3309 2.83561 31.5 2.83561C15.6691 2.83561 2.83561 15.6691 2.83561 31.5Z"
			fill="#F1416C"
		/>
		<path
			d="M28 41.6271C28 41.1842 28.0815 40.7456 28.2398 40.3364C28.3981 39.9271 28.6301 39.5553 28.9226 39.2421C29.2151 38.9289 29.5624 38.6805 29.9445 38.511C30.3267 38.3415 30.7363 38.2542 31.15 38.2542C31.5637 38.2542 31.9733 38.3415 32.3555 38.511C32.7376 38.6805 33.0849 38.9289 33.3774 39.2421C33.6699 39.5553 33.9019 39.9271 34.0602 40.3364C34.2185 40.7456 34.3 41.1842 34.3 41.6271C34.3 42.5217 33.9681 43.3796 33.3774 44.0121C32.7866 44.6446 31.9854 45 31.15 45C30.3146 45 29.5134 44.6446 28.9226 44.0121C28.3319 43.3796 28 42.5217 28 41.6271ZM28.3087 21.3729C28.2668 20.9474 28.3088 20.5172 28.4322 20.1102C28.5555 19.7032 28.7574 19.3285 29.0248 19.0105C29.2921 18.6924 29.6189 18.4381 29.9839 18.264C30.3489 18.09 30.7441 18 31.1437 18C31.5433 18 31.9385 18.09 32.3035 18.264C32.6685 18.4381 32.9953 18.6924 33.2626 19.0105C33.53 19.3285 33.7319 19.7032 33.8552 20.1102C33.9786 20.5172 34.0206 20.9474 33.9787 21.3729L32.8762 33.2016C32.8392 33.6663 32.6406 34.0992 32.3198 34.4146C31.999 34.7301 31.5793 34.9051 31.1437 34.9051C30.7081 34.9051 30.2884 34.7301 29.9676 34.4146C29.6468 34.0992 29.4482 33.6663 29.4112 33.2016L28.3087 21.3729Z"
			fill="#F1416C"
		/>
	</svg>
);

export default CircleExclamation;
