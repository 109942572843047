import { NetworkStatusProviderProps } from '@Components/Providers/NetworkStatusProvider/types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

function getRandomString() {
	return Math.random().toString(36).substring(2, 15);
}

async function isOnline() {
	if (!window.navigator.onLine) return false;

	// avoid CORS errors with a request to your own origin
	const url = new URL(window.location.origin);

	// random value to prevent cached responses
	url.searchParams.set('rand', getRandomString());

	try {
		const response = await fetch(url.toString(), { method: 'HEAD' });

		return response.ok;
	} catch {
		return false;
	}
}

enum NetworkStatus {
	Online = 'online',
	Offline = 'offline',
}

export default function NetworkStatusProvider({
	children,
}: NetworkStatusProviderProps) {
	const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(
		NetworkStatus.Online
	);
	useEffect(() => {
		const onOnline = () => {
			isOnline().then((res) => {
				if (res) {
					console.log(NetworkStatus.Online);
					setNetworkStatus(NetworkStatus.Online);
				}
			});
		};

		const onOffline = () => {
			isOnline().then((res) => {
				if (!res) {
					console.log(NetworkStatus.Offline);
					setNetworkStatus(NetworkStatus.Offline);
					toast.warn('"¡No tienes internet! Revisa tu conexión."', {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'dark',
					});
				}
			});
		};

		window.addEventListener('online', onOnline);
		window.addEventListener('offline', onOffline);

		return () => {
			window.removeEventListener('online', onOnline);
			window.removeEventListener('offline', onOffline);
		};
	}, []);

	return <>{children}</>;
}
