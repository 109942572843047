export type User = {
	id: string;
	email: string;
	first_name: string;
	last_name: string;
	photo?: string;
	aiChatSubscriptionData?: AiChatSubscriptionData;
	is_first_use?: boolean | null;
	phone_number?: string | null;
	national_identification?: string | null;
	subscriptions: Subscription[];
	token?: string;

	teacher?: PhotoUserResponse;
	routers?: string[];
};

export const Plans = {
	EVALUADOS_APP: 'EvaluadosAPP',
	EVALUADOS_AI: 'EvaluadosAI',
} as const;

type Subscription = {
	plan: Plan;
	endDate?: string | null;
};

type Plan = {
	id: string;
	name: string;
};

export type AiChatSubscriptionData = {
	expenses: AiChatExpense[];
	subscription: AiChatSubscriptionType;
};

type AiChatExpense = {
	id: string;
	amount: number;
	date: string;
};

export type AiChatSubscriptionType = {
	name: 'basic' | 'admin';
	tokens: number;
};

export type AuthedUser = User & {
	id: string;
	token: string;
	photo?: string;
	teacher?: PhotoUserResponse;
};

export type PhotoUserResponse = {
	profile_picture?: {
		path?: string;
		bucket_name?: string;
	} | null;
	id?: string;
};

function getFullName(
	user?: Pick<User, 'first_name' | 'last_name'> | null
): string {
	if (!user) throw new Error('User is undefined');
	return `${user.first_name} ${user.last_name}`;
}

function getRemainingAiChatEnergy(user: User | null) {
	if (!user) return 0;
	const { aiChatSubscriptionData } = user;
	if (!aiChatSubscriptionData) return 0;
	const { expenses, subscription } = aiChatSubscriptionData;
	const remaining =
		subscription.tokens -
		expenses.reduce((acc, expense) => {
			return acc + expense.amount;
		}, 0);

	return (remaining * 100) / subscription.tokens;
}

export default { getFullName, getRemainingAiChatEnergy };
