import questionnairesApiGraphQL from '@Api/questionnaires';
import bibliographysApiGraphQL from '@Api/bibliographys';

import {
	Questionnaire,
	QuestionnaireWithResults,
} from '@Configs/schemas/Case/Questionnaire';
import { SubjectStudents } from '@Configs/schemas/Subject';
import { Subject, Syllabus } from '@Configs/schemas/Subject/Subject';
import { Survey, SurveyWithResults } from '@Configs/schemas/Case/Survey';
import UserRegister, {
	UserRegisterConfirmResponse,
	UserRegisterResponse,
} from '@Configs/schemas/UserRegister';
import { QuestionnaireFields } from '@Utils/helpers/Questionnaire';
import { SubjectFormSchema } from '@Utils/helpers/Subject';
import { SurveyFields } from '@Utils/helpers/Survey';
import { UpdateCaseDto, UpdateTeacherDto } from './generated';
import subjectsApiGQL from './subjects';
import surveysApiGraphQL from './surveys';
import usersApiGraphQL from './user';
import { Nullable } from '@Utils/types';
import { PhotoUserResponse, User } from '@Configs/schemas/User/User';
import { BibliographysType, BibliographyTypeGet } from './bibliographys/types';
import { AiChatSubscription } from '@Configs/schemas/AiChatSubscription/AiChatSubscription';
import aiChatSubscriptionsGraphQL from '@Api/aiChatSubscriptions';
import { EventSourceMessage } from '@microsoft/fetch-event-source';
import aiApi, { TemplatedSyllabus } from '@Api/ai';
import { ChatMessage } from '@Pages/EvaluadosAI/hooks/useOpenAIStreamCompletion';
import {
	AcademicProduct,
	AcademicProductAnswer,
} from '@Configs/schemas/AcademicProduct/AcademicProduct';
import clientsApiGraphQL from '@Api/clients';
import { Client } from '@Configs/schemas/Client/Client';

import reportsApiGraphQL from './reports';
import { AcademicObjectReport } from '../app/configs/schemas/Reports/AcademicObjectsReport';
import { EvaluadosAppReport } from '../app/configs/schemas/Reports/EvaluadosAppReport';
import { Permissions } from '../app/configs/schemas/Permissions';

type BaseStreamBody = {
	chatHistory: {
		role: string;
		content: string;
	}[];
};

export type StreamBody = BaseStreamBody & {
	academic_object_id?: number;
	syllabus?: TemplatedSyllabus;
	prompt?: string;
};

export interface IUsersApi {
	isExpiredToken(token: string): Promise<boolean>;
	confirmEmailMutation(token: string): Promise<UserRegisterConfirmResponse>;
	loginUserWithEmailAndPassword(email: string, password: string): Promise<User>;
	recoveryPassword(
		token: string,
		password: string
	): Promise<PasswordChangedType>;
	registerUser(data: UserRegister): Promise<UserRegisterResponse>;
	sendRecoveryPasswordEmail(email: string): Promise<RecoveryEmailType>;
	checkIfEmailIsAvailable(email: string): Promise<boolean>;
	teacherGoogleLogin(code: string): Promise<User>;
	PutPhotoUserEdit(
		changes: UpdateTeacherDto,
		putTeacherId: number
	): Promise<PhotoUserResponse>;
	PutUser(
		changes: UpdateTeacherDto,
		putTeacherId: number
	): Promise<Partial<User>>;
	refreshUserData(token: string): Promise<User>;
	getUserRouters(): Promise<string[]>;
}

export interface ISubjectsApi {
	getSubjects(): Promise<{ data: Subject[]; globalPermissions: string[] }>;
	PostSubject(data: SubjectFormSchema): Promise<string>;
	GetSubjectByID(id: number): Promise<Subject>;
	getSubjectStudents(subjectId: string): Promise<SubjectStudents>;
	updateSubject(data: Partial<SubjectFormSchema>): Promise<string>;
	DeleteCaseResultBySubject(subjectId: number): Promise<void>;
	DeleteSubjectById(id: number): Promise<void>;
	DuplicateSubject(id: number, data: SubjectFormSchema): Promise<number>;
	checkIfCodeIsAvailable(code: string, subjectId?: string): Promise<boolean>;
	getTeacherSyllabus(): Promise<Syllabus[]>;
	getSubjectSyllabus(subjectId: string): Promise<Syllabus>;
}
export interface IQuestionnairesApi {
	getQuestionnaireById(id: string): Promise<Questionnaire>;
	getQuestionnairesBySubjectId(
		subjectId: number
	): Promise<{ data: Questionnaire[]; permissions: Permissions }>;
	getQuestionnairesBySubjectIdDashboard(subjectId: string): Promise<{
		id: string;
		students: {
			id: string;
			name: string;
			email: string;
		}[];
		questionnaires: QuestionnaireWithResults[];
	}>;

	getQuestionnaireStatistics(id: string): Promise<QuestionnaireWithResults>;
	getQuestionnaireStatus(id: string): Promise<Nullable<string>>;
	postQuestionnaire(data: QuestionnaireFields): Promise<string>;
	postQuestionnaireDraft(data: QuestionnaireFields): Promise<string>;
	deleteQuestionnaires(ids: number[], caseType: string): Promise<void>;
	putCase(id: number, data: UpdateCaseDto, caseType: string): Promise<string>;
	updateQuestionnaire(id: string, data: QuestionnaireFields): Promise<string>;
	duplicateQuestionnaire(caseId: number): Promise<number>;
	downloadQuestionnairesReport(subjectId: string): Promise<string>;
}

export interface ISurveysApi {
	postSurvey(data: SurveyFields): Promise<string>;
	postSurveyDraft(data: SurveyFields): Promise<string>;
	getSurveysBySubjectId(
		subjectId: number
	): Promise<{ data: Survey[]; permissions: Permissions }>;
	getSurveyById(id: string): Promise<Survey>;
	getSurveyResultsById(surveyId: string): Promise<SurveyWithResults>;
	deleteSurveys(ids: number[]): Promise<void>;
	duplicateSurvey(caseId: number): Promise<string>;
	updateSurvey(data: SurveyFields): Promise<string>;
}

export interface IBibliographysApi {
	postBibliographyWithChapters(payload: BibliographysType): Promise<string>;
	getBibliography(): Promise<BibliographyTypeGet[]>;
	deleteBibliography(id: string): Promise<void>;
}

export interface IAiChatSubscriptionsApi {
	getAiChatSubscription(): Promise<AiChatSubscription>;
	postAiChatExpense(tokens: number): Promise<void>;
	postAcademicProductResult(
		data: Omit<AcademicProductAnswer, 'id'>
	): Promise<string>;
	putAcademicProductResult(data: AcademicProductAnswer): Promise<string>;
	deleteAcademicProductAnswer(id: number): Promise<void>;
	getAcademicObjectsForSubject(subjectId: string): Promise<AcademicProduct[]>;
	getSubjectAcademicObjectAnswers(
		subjectId: string
	): Promise<AcademicProductAnswer[]>;
}

export interface IAIApi {
	submitPrompt(
		body: StreamBody,
		options: {
			onopen?: (event: Response) => void;
			onclose?: () => void;
			onmessage?: (event: EventSourceMessage) => void;
			onerror?: (event: unknown) => void;
			abortSignal?: AbortSignal;
		}
	): Promise<void>;
	// generatePrompt(chatHistory: ChatMessage[]): {
	// 	role: string;
	// 	content: string;
	// }[];
}

export interface IClientsApi {
	getClientSettings(clientId: number): Promise<Client>;
}

export interface IReportsApi {
	getAcademicObjectsDashboardReport(): Promise<AcademicObjectReport[]>;
	getEvaluadosAppDashboardReportHeaders(): Promise<Record<string, string[]>>;
	getEvaluadosAppDashboardReport(args?: {
		year?: string;
		searchArgs?: string[];
		limit?: number;
		offset?: number;
		searchQuery?: string;
	}): Promise<EvaluadosAppReport>;
}

export interface IApi {
	AiChatSubscription: IAiChatSubscriptionsApi;
	Users: IUsersApi;
	Questionnaires: IQuestionnairesApi;
	Subjects: ISubjectsApi;
	Surveys: ISurveysApi;
	Bibliographys: IBibliographysApi;
	Clients: IClientsApi;
	Ai: IAIApi;
	Reports: IReportsApi;
}

const Api: IApi = {
	Ai: aiApi,
	Users: usersApiGraphQL,
	Subjects: subjectsApiGQL,
	Questionnaires: questionnairesApiGraphQL,
	Surveys: surveysApiGraphQL,
	Bibliographys: bibliographysApiGraphQL,
	AiChatSubscription: aiChatSubscriptionsGraphQL,
	Clients: clientsApiGraphQL,
	Reports: reportsApiGraphQL,
};

export default Api;

type PasswordChangedType = {
	values?: string;
	status?: string;
	error?: string;
};

type RecoveryEmailType = {
	values?: string;
	status?: string;
	error?: string;
};
