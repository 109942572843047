import PrivateRoute from '@Routing/PrivateRoute';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
const EvaluadosAIPage = lazy(() => import('@Pages/EvaluadosAI'));

export const evaluadosAIRoutes = [
	{
		path: '/evaluados-ai',
		element: (
			<PrivateRoute
				path="/evaluados-ai"
				key="/evaluados-ai"
				component={EvaluadosAIPage}
			/>
		),
	},
	{
		path: '/',
		element: (
			<Redirect from="/" to="/evaluados-ai" key="evaluados-ai-redirect" exact />
		),
	},
];
