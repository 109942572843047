import * as React from 'react';

const DuplicateQuestionaire = () => (
	<svg
		width={115}
		height={122}
		viewBox="0 0 115 122"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_2462_41293)">
			<path
				d="M115 25.5C115 39.5833 103.583 51 89.5 51C75.4167 51 64 39.5833 64 25.5C64 11.4167 75.4167 0 89.5 0C103.583 0 115 11.4167 115 25.5ZM66.2955 25.5C66.2955 38.3155 76.6845 48.7045 89.5 48.7045C102.315 48.7045 112.705 38.3155 112.705 25.5C112.705 12.6845 102.315 2.29549 89.5 2.29549C76.6845 2.29549 66.2955 12.6845 66.2955 25.5Z"
				fill="#009EF7"
			/>
			<path
				d="M86.7617 33.7935C86.7617 33.4349 86.8277 33.0798 86.9558 32.7486C87.084 32.4173 87.2718 32.1163 87.5086 31.8627C87.7454 31.6092 88.0265 31.4081 88.3359 31.2709C88.6453 31.1336 88.9768 31.063 89.3117 31.063C89.6466 31.063 89.9782 31.1336 90.2876 31.2709C90.5969 31.4081 90.8781 31.6092 91.1148 31.8627C91.3516 32.1163 91.5395 32.4173 91.6676 32.7486C91.7958 33.0798 91.8617 33.4349 91.8617 33.7935C91.8617 34.5176 91.5931 35.2121 91.1148 35.7242C90.6366 36.2362 89.988 36.5239 89.3117 36.5239C88.6354 36.5239 87.9868 36.2362 87.5086 35.7242C87.0304 35.2121 86.7617 34.5176 86.7617 33.7935ZM87.0116 17.3972C86.9777 17.0527 87.0117 16.7045 87.1116 16.375C87.2114 16.0455 87.3749 15.7422 87.5913 15.4848C87.8077 15.2273 88.0722 15.0214 88.3677 14.8805C88.6632 14.7396 88.9831 14.6667 89.3066 14.6667C89.6301 14.6667 89.95 14.7396 90.2455 14.8805C90.541 15.0214 90.8056 15.2273 91.022 15.4848C91.2384 15.7422 91.4018 16.0455 91.5017 16.375C91.6015 16.7045 91.6356 17.0527 91.6016 17.3972L90.7091 26.9728C90.6791 27.349 90.5184 27.6994 90.2587 27.9548C89.999 28.2101 89.6592 28.3518 89.3066 28.3518C88.954 28.3518 88.6143 28.2101 88.3546 27.9548C88.0949 27.6994 87.9341 27.349 87.9041 26.9728L87.0116 17.3972Z"
				fill="#009EF7"
			/>
			<g opacity={0.5}>
				<path
					d="M6.09075 114.579C0.350818 97.8477 -1.68593 66.4306 4.60947 50.6292C14.176 50.3193 37.2715 48.5471 53.1211 43.9368C52.3188 56.8878 54.0099 86.8796 67.1932 103.239C61.083 108.63 29.2356 118.297 6.09075 114.579Z"
					fill="white"
				/>
				<path
					d="M44.5234 60.4002C44.7456 62.6682 44.9678 64.9734 45.153 67.2413C47.3008 66.6836 49.4116 66.0888 51.5595 65.5311C51.2632 63.2631 51.004 60.9579 50.7078 58.6899C48.634 59.2476 46.5972 59.8425 44.5234 60.4002Z"
					fill="#B6B6B6"
				/>
				<path
					d="M43.2266 77.7633C45.4485 77.4659 47.6334 77.2056 49.8553 76.9082C50.1515 79.6223 50.4107 82.3365 50.707 85.0506C48.7073 85.125 46.7076 85.1993 44.7449 85.2737C44.2264 82.7455 43.745 80.2544 43.2266 77.7633Z"
					fill="#B6B6B6"
				/>
				<path
					d="M51.3359 94.2341C52.0395 96.5765 52.7431 98.956 53.4838 101.298C55.4835 100.369 57.4832 99.4393 59.4459 98.5098C58.8163 96.4278 58.1498 94.3829 57.5203 92.3008C55.4835 92.97 53.4097 93.6021 51.3359 94.2341Z"
					fill="#B6B6B6"
				/>
				<path
					opacity={0.14}
					d="M54.3733 43C54.7436 51.477 55.6324 58.504 56.41 63.5605C58.2246 75.1607 59.5577 83.1915 64.2978 92.2262C67.2604 97.8404 70.5932 101.967 73.0003 104.644C68.8157 108.251 63.1869 112.341 55.9657 115.612C37.8201 123.904 20.7854 122.23 12.8236 120.892C12.4163 119.07 12.0089 117.248 11.6016 115.389C12.6014 115.464 14.1568 115.538 16.0454 115.575C22.3408 115.724 35.45 115.947 50.485 110.891C54.7066 109.478 60.6317 107.098 67.2974 103.157C64.6681 99.6251 62.0759 95.4237 59.8169 90.5532C50.8923 71.3311 52.6698 52.6668 54.3733 43Z"
					fill="#6D6C6C"
				/>
				<path
					d="M3.93571 50.7335C-1.95235 71.7774 -1.21172 94.3084 6.00948 114.943C6.23167 115.613 7.3056 115.315 7.08341 114.646C-0.0637261 94.1969 -0.804376 71.8889 5.00962 51.031C5.19478 50.3245 4.12087 50.0271 3.93571 50.7335Z"
					fill="#161616"
				/>
				<path
					d="M4.67657 51.1053C21.1557 51.0309 37.5608 48.8745 53.5215 44.6732C54.2251 44.4873 53.9289 43.409 53.2253 43.5949C37.3756 47.7591 21.0817 49.9155 4.67657 49.9899C3.93593 49.9899 3.93593 51.1053 4.67657 51.1053Z"
					fill="#161616"
				/>
				<path
					d="M53.226 44.0039C52.5594 54.34 53.0038 64.7504 54.8554 74.9748C55.7812 80.1429 56.8922 85.3109 58.9289 90.1443C60.9657 94.9405 63.7801 99.2905 67.0759 103.269C67.5203 103.826 68.335 103.046 67.8536 102.488C64.4837 98.4354 61.6322 93.9366 59.6325 89.0289C57.7439 84.3442 56.707 79.3249 55.8553 74.38C54.0777 64.3786 53.6704 54.1541 54.2999 44.0039C54.374 43.2975 53.263 43.2975 53.226 44.0039Z"
					fill="#161616"
				/>
				<path
					d="M6.15661 115.538C27.1166 117.025 49.0024 114.646 67.3702 103.789C67.9997 103.417 67.4442 102.451 66.8147 102.822C48.558 113.605 26.8944 115.873 6.15661 114.423C5.45301 114.348 5.45301 115.464 6.15661 115.538Z"
					fill="#161616"
				/>
				<path
					d="M28.451 54.4515C27.8215 53.3733 29.0806 52.0348 30.1545 52.89C31.3025 53.8195 30.0804 55.3067 28.8584 54.8233C28.1918 54.5631 27.8956 55.6413 28.5621 55.9016C29.562 56.3105 30.784 55.8644 31.3765 54.9721C32.0431 53.9682 31.7469 52.7041 30.8211 51.9977C28.8214 50.3989 26.3032 52.89 27.4882 55.0092C27.8585 55.6413 28.8214 55.0836 28.451 54.4515Z"
					fill="#161616"
				/>
				<path
					d="M5.04723 67.2786C16.1197 67.0927 27.1922 66.3119 38.2277 64.9734C38.9313 64.8991 38.9313 63.7837 38.2277 63.858C27.2293 65.2337 16.1568 65.9773 5.04723 66.1632C4.34363 66.2004 4.34363 67.3158 5.04723 67.2786Z"
					fill="#161616"
				/>
				<path
					d="M42.1877 59.8054C42.521 61.5156 42.8543 63.2631 43.1876 64.9734C43.3357 65.6798 44.4096 65.3824 44.2615 64.6759C43.9282 62.9657 43.595 61.2182 43.2617 59.5079C43.1506 58.8015 42.0766 59.0989 42.1877 59.8054Z"
					fill="#161616"
				/>
				<path
					d="M42.929 59.6567C44.9658 59.3592 47.0025 59.0618 49.0763 58.7272C49.7799 58.6156 49.4837 57.5374 48.7801 57.6489C46.7433 57.9464 44.7066 58.2438 42.6328 58.5784C41.9292 58.69 42.2254 59.7682 42.929 59.6567Z"
					fill="#161616"
				/>
				<path
					d="M48.7424 58.3924C49.0387 59.9912 49.2979 61.5899 49.5942 63.1515C49.7052 63.8579 50.7792 63.5605 50.6681 62.854C50.3718 61.2553 50.1126 59.6566 49.8163 58.095C49.7052 57.4258 48.6313 57.7232 48.7424 58.3924Z"
					fill="#161616"
				/>
				<path
					d="M44.1165 65.8285C46.3014 65.3823 48.3752 64.7875 50.449 63.9695C51.1155 63.7092 50.8193 62.631 50.1527 62.8913C48.0789 63.6721 45.9681 64.3041 43.8203 64.7503C43.1167 64.899 43.4129 65.9772 44.1165 65.8285Z"
					fill="#161616"
				/>
				<path
					d="M44.302 60.9208C44.7094 61.8503 45.2649 62.5939 46.0796 63.226C46.3758 63.449 46.9313 63.449 47.0053 62.9657C47.3016 60.958 48.0052 59.1734 49.1902 57.5003C49.5976 56.9054 48.6347 56.3477 48.2274 56.9426C47.0053 58.6528 46.2277 60.5862 45.9314 62.6683C46.2277 62.5939 46.561 62.5195 46.8572 62.408C46.1536 61.8503 45.6352 61.1811 45.2648 60.3259C44.9686 59.6939 44.0058 60.2516 44.302 60.9208Z"
					fill="#161616"
				/>
				<path
					d="M44.7091 76.871C44.1166 79.5479 44.3759 82.1505 45.4498 84.6416C45.746 85.3108 46.6718 84.7159 46.4126 84.0839C45.4498 81.8902 45.2646 79.5107 45.7831 77.1684C45.9312 76.462 44.8573 76.1645 44.7091 76.871Z"
					fill="#161616"
				/>
				<path
					d="M45.4485 77.2427C47.6704 76.8709 49.8923 76.5363 52.1142 76.1645C51.8921 75.9414 51.6699 75.7183 51.4477 75.4952C51.0774 77.8748 51.3366 80.1427 52.2624 82.3735C52.5216 83.0428 53.5955 82.7453 53.3363 82.0761C52.4846 80.0684 52.1883 77.9491 52.5216 75.7927C52.5957 75.3465 52.2994 75.0491 51.855 75.1234C49.6331 75.4581 47.4112 75.8299 45.1893 76.2017C44.4487 76.276 44.7449 77.3542 45.4485 77.2427Z"
					fill="#161616"
				/>
				<path
					d="M45.93 84.6787C48.3741 84.6043 50.7072 84.121 53.0031 83.303C53.6697 83.0428 53.3735 81.9645 52.7069 82.2248C50.522 83.0427 48.2631 83.4889 45.93 83.5633C45.2264 83.5633 45.2264 84.6787 45.93 84.6787Z"
					fill="#161616"
				/>
				<path
					d="M46.2644 79.5107C46.5977 80.4774 47.1532 81.1466 48.042 81.6671C48.4493 81.8902 48.8196 81.5928 48.8937 81.1838C49.1159 79.2133 49.6343 77.3171 50.449 75.4953C50.7453 74.8632 49.7825 74.2683 49.4862 74.9376C48.5974 76.9453 48.042 79.0274 47.7827 81.1838C48.079 81.0351 48.3382 80.8492 48.6345 80.7005C48.0049 80.3287 47.6346 79.8825 47.3754 79.1761C47.1162 78.544 46.0422 78.8415 46.2644 79.5107Z"
					fill="#161616"
				/>
				<path
					d="M47.0035 94.1226C47.2628 96.6136 47.9293 98.956 49.0403 101.187C49.3365 101.819 50.2994 101.261 50.0031 100.629C49.0032 98.5842 48.3737 96.3906 48.1515 94.1226C48.0404 93.4162 46.9295 93.4162 47.0035 94.1226Z"
					fill="#161616"
				/>
				<path
					d="M47.5238 94.0108C50.3752 94.271 53.0415 93.7505 55.5967 92.4864C55.3375 92.3749 55.0412 92.2633 54.782 92.1518C55.2634 94.4198 56.2633 96.3903 57.8186 98.1378C58.3 98.6583 59.0777 97.8775 58.5963 97.357C57.1891 95.7582 56.3003 93.9736 55.856 91.8915C55.7819 91.5197 55.3375 91.4082 55.0412 91.5569C52.6712 92.7095 50.1531 93.1556 47.5238 92.9326C46.8202 92.8582 46.8202 93.9736 47.5238 94.0108Z"
					fill="#161616"
				/>
				<path
					d="M50.0392 101.595C52.9277 100.517 55.7791 99.3646 58.5565 98.1005C59.2231 97.803 58.6306 96.8364 58.001 97.1338C55.2977 98.3607 52.5203 99.4761 49.7429 100.517C49.0763 100.777 49.3726 101.856 50.0392 101.595Z"
					fill="#161616"
				/>
				<path
					d="M49.4483 95.9815C50.3371 96.7623 52.3738 99.4393 53.4107 97.4687C53.8921 96.5764 53.781 95.3495 54.0773 94.3456C54.5217 92.9328 55.2623 91.6315 56.2252 90.5161C56.6695 89.9584 55.8919 89.1776 55.4475 89.7353C54.4106 90.9623 53.5959 92.3007 53.1145 93.8623C52.8923 94.5687 52.7071 95.3123 52.596 96.0559C52.4479 96.9854 52.1887 96.7623 51.4851 96.3162C51.0407 96.0187 50.6333 95.5726 50.226 95.2008C49.7076 94.7174 48.9299 95.4982 49.4483 95.9815Z"
					fill="#161616"
				/>
				<path
					d="M6.04723 85.4594C16.4161 85.3478 26.748 84.4927 36.9688 82.8568C37.6724 82.7453 37.3761 81.667 36.6725 81.7786C26.5258 83.3773 16.305 84.2325 6.04723 84.3068C5.34363 84.344 5.34363 85.4594 6.04723 85.4594Z"
					fill="#161616"
				/>
				<path
					d="M4.85973 70.3645C11.5625 70.5504 18.2282 70.3273 24.8939 69.7696C25.5975 69.6952 25.5975 68.5798 24.8939 68.6542C18.2282 69.2119 11.5255 69.3978 4.85973 69.2491C4.15613 69.2119 4.15613 70.3273 4.85973 70.3645Z"
					fill="#161616"
				/>
				<path
					d="M6.45348 87.6532C13.4895 87.9878 20.5256 87.7276 27.4875 86.8353C28.1911 86.7609 28.1911 85.6455 27.4875 85.7199C20.4885 86.575 13.4525 86.8724 6.45348 86.5378C5.74988 86.5006 5.74988 87.616 6.45348 87.6532Z"
					fill="#161616"
				/>
				<path
					d="M29.8597 86.3146C31.8595 86.3518 33.8221 86.0915 35.7478 85.4595C36.4144 85.2364 36.1181 84.1582 35.4515 84.3813C33.637 84.9762 31.7854 85.2364 29.8597 85.1992C29.1561 85.1992 29.1561 86.3146 29.8597 86.3146Z"
					fill="#161616"
				/>
				<path
					d="M10.2313 101.633C18.6745 101.112 27.0807 100.331 35.4499 99.1789C36.1535 99.0674 36.1535 97.952 35.4499 98.0635C27.0807 99.1789 18.6745 99.9968 10.2313 100.517C9.49062 100.555 9.49062 101.67 10.2313 101.633Z"
					fill="#161616"
				/>
				<path
					d="M11.1957 104.236C13.9731 104.236 16.7504 104.236 19.5278 104.236C20.2314 104.236 20.2314 103.12 19.5278 103.12C16.7504 103.12 13.9731 103.12 11.1957 103.12C10.4921 103.12 10.4921 104.236 11.1957 104.236Z"
					fill="#161616"
				/>
				<path
					d="M13.5998 106.727C17.9325 106.466 22.2653 106.057 26.598 105.537C30.079 105.091 33.8192 104.905 36.8187 102.86C37.4113 102.451 36.8558 101.484 36.2633 101.893C33.1156 104.05 28.8939 104.161 25.2278 104.57C21.3765 105.016 17.4882 105.351 13.6368 105.611C12.8962 105.648 12.8592 106.764 13.5998 106.727Z"
					fill="#161616"
				/>
			</g>
			<path
				d="M21.0907 95.7574C15.3508 79.0264 13.3141 47.6093 19.6095 31.8079C29.176 31.498 52.2715 29.7258 68.1211 25.1155C67.3188 38.0665 69.0099 68.0583 82.1932 84.4175C76.083 89.8086 44.2356 99.4754 21.0907 95.7574Z"
				fill="white"
			/>
			<path
				d="M59.5234 41.4002C59.7456 43.6682 59.9678 45.9734 60.153 48.2413C62.3008 47.6836 64.4116 47.0888 66.5595 46.5311C66.2632 44.2631 66.004 41.9579 65.7078 39.6899C63.634 40.2476 61.5972 40.8425 59.5234 41.4002Z"
				fill="#50CD89"
			/>
			<path
				d="M58.2266 58.7633C60.4485 58.4659 62.6334 58.2056 64.8553 57.9082C65.1515 60.6223 65.4107 63.3365 65.707 66.0506C63.7073 66.125 61.7076 66.1993 59.7449 66.2737C59.2264 63.7455 58.745 61.2544 58.2266 58.7633Z"
				fill="#50CD89"
			/>
			<path
				d="M66.3359 75.2339C67.0395 77.5762 67.7431 79.9558 68.4838 82.2981C70.4835 81.3686 72.4832 80.4391 74.4459 79.5096C73.8163 77.4275 73.1498 75.3826 72.5203 73.3005C70.4835 73.9698 68.4097 74.6018 66.3359 75.2339Z"
				fill="#50CD89"
			/>
			<path
				opacity={0.14}
				d="M69.3733 24C69.7436 32.477 70.6324 39.504 71.41 44.5605C73.2246 56.1607 74.5577 64.1915 79.2978 73.2262C82.2604 78.8404 85.5932 82.9674 88.0003 85.6444C83.8157 89.2508 78.1869 93.3406 70.9657 96.6124C52.8201 104.904 35.7854 103.23 27.8236 101.892C27.4163 100.07 27.0089 98.2484 26.6016 96.3894C27.6014 96.4637 29.1568 96.5381 31.0454 96.5753C37.3408 96.724 50.45 96.9471 65.485 91.8906C69.7066 90.4778 75.6317 88.0982 82.2974 84.1572C79.6681 80.6251 77.0759 76.4237 74.8169 71.5532C65.8923 52.3311 67.6698 33.6668 69.3733 24Z"
				fill="#6D6C6C"
			/>
			<path
				d="M18.9357 31.7335C13.0477 52.7774 13.7883 75.3084 21.0095 95.9433C21.2317 96.6125 22.3056 96.3151 22.0834 95.6458C14.9363 75.1969 14.1956 52.8889 20.0096 32.031C20.1948 31.3245 19.1209 31.0271 18.9357 31.7335Z"
				fill="#161616"
			/>
			<path
				d="M19.6766 32.1053C36.1557 32.0309 52.5608 29.8745 68.5215 25.6732C69.2251 25.4873 68.9289 24.409 68.2253 24.5949C52.3756 28.7591 36.0817 30.9155 19.6766 30.9899C18.9359 30.9899 18.9359 32.1053 19.6766 32.1053Z"
				fill="#161616"
			/>
			<path
				d="M68.226 25.0039C67.5594 35.34 68.0038 45.7504 69.8554 55.9748C70.7812 61.1429 71.8922 66.3109 73.9289 71.1443C75.9657 75.9405 78.7801 80.2905 82.0759 84.2688C82.5203 84.8265 83.335 84.0457 82.8536 83.488C79.4837 79.4354 76.6322 74.9366 74.6325 70.0289C72.7439 65.3442 71.707 60.3249 70.8553 55.38C69.0777 45.3786 68.6704 35.1541 69.2999 25.0039C69.374 24.2975 68.263 24.2975 68.226 25.0039Z"
				fill="#161616"
			/>
			<path
				d="M21.1566 96.5382C42.1166 98.0254 64.0024 95.6459 82.3702 84.7893C82.9997 84.4175 82.4442 83.4508 81.8147 83.8226C63.558 94.6048 41.8944 96.8728 21.1566 95.4228C20.453 95.3484 20.453 96.4638 21.1566 96.5382Z"
				fill="#161616"
			/>
			<path
				d="M43.451 35.4515C42.8215 34.3733 44.0806 33.0348 45.1545 33.89C46.3025 34.8195 45.0804 36.3067 43.8584 35.8233C43.1918 35.5631 42.8956 36.6413 43.5621 36.9016C44.562 37.3105 45.784 36.8644 46.3765 35.9721C47.0431 34.9682 46.7469 33.7041 45.8211 32.9977C43.8214 31.3989 41.3032 33.89 42.4882 36.0092C42.8585 36.6413 43.8214 36.0836 43.451 35.4515Z"
				fill="#161616"
			/>
			<path
				d="M20.0472 48.2786C31.1197 48.0927 42.1922 47.3119 53.2277 45.9734C53.9313 45.8991 53.9313 44.7837 53.2277 44.858C42.2293 46.2337 31.1568 46.9773 20.0472 47.1632C19.3436 47.2004 19.3436 48.3158 20.0472 48.2786Z"
				fill="#161616"
			/>
			<path
				d="M57.1877 40.8054C57.521 42.5156 57.8543 44.2631 58.1876 45.9734C58.3357 46.6798 59.4096 46.3824 59.2615 45.6759C58.9282 43.9657 58.595 42.2182 58.2617 40.5079C58.1506 39.8015 57.0766 40.0989 57.1877 40.8054Z"
				fill="#161616"
			/>
			<path
				d="M57.929 40.6567C59.9658 40.3592 62.0025 40.0618 64.0763 39.7272C64.7799 39.6156 64.4837 38.5374 63.7801 38.6489C61.7433 38.9464 59.7066 39.2438 57.6328 39.5784C56.9292 39.69 57.2254 40.7682 57.929 40.6567Z"
				fill="#161616"
			/>
			<path
				d="M63.7424 39.3924C64.0387 40.9912 64.2979 42.5899 64.5942 44.1515C64.7052 44.8579 65.7792 44.5605 65.6681 43.854C65.3718 42.2553 65.1126 40.6566 64.8163 39.095C64.7052 38.4258 63.6313 38.7232 63.7424 39.3924Z"
				fill="#161616"
			/>
			<path
				d="M59.1165 46.8285C61.3014 46.3823 63.3752 45.7875 65.449 44.9695C66.1155 44.7092 65.8193 43.631 65.1527 43.8913C63.0789 44.6721 60.9681 45.3041 58.8203 45.7503C58.1167 45.899 58.4129 46.9772 59.1165 46.8285Z"
				fill="#161616"
			/>
			<path
				d="M59.302 41.9208C59.7094 42.8503 60.2649 43.5939 61.0796 44.226C61.3758 44.449 61.9313 44.449 62.0053 43.9657C62.3016 41.958 63.0052 40.1734 64.1902 38.5003C64.5976 37.9054 63.6347 37.3477 63.2274 37.9426C62.0053 39.6528 61.2277 41.5862 60.9314 43.6683C61.2277 43.5939 61.561 43.5195 61.8572 43.408C61.1536 42.8503 60.6352 42.1811 60.2648 41.3259C59.9686 40.6939 59.0058 41.2516 59.302 41.9208Z"
				fill="#161616"
			/>
			<path
				d="M59.7091 57.871C59.1166 60.5479 59.3759 63.1505 60.4498 65.6416C60.746 66.3108 61.6718 65.7159 61.4126 65.0839C60.4498 62.8902 60.2646 60.5107 60.7831 58.1684C60.9312 57.462 59.8573 57.1645 59.7091 57.871Z"
				fill="#161616"
			/>
			<path
				d="M60.4485 58.2427C62.6704 57.8709 64.8923 57.5363 67.1142 57.1645C66.8921 56.9414 66.6699 56.7183 66.4477 56.4952C66.0774 58.8748 66.3366 61.1427 67.2624 63.3735C67.5216 64.0428 68.5955 63.7453 68.3363 63.0761C67.4846 61.0684 67.1883 58.9491 67.5216 56.7927C67.5957 56.3465 67.2994 56.0491 66.855 56.1234C64.6331 56.4581 62.4112 56.8299 60.1893 57.2017C59.4487 57.276 59.7449 58.3542 60.4485 58.2427Z"
				fill="#161616"
			/>
			<path
				d="M60.93 65.6787C63.3741 65.6043 65.7072 65.121 68.0031 64.303C68.6697 64.0428 68.3735 62.9645 67.7069 63.2248C65.522 64.0427 63.2631 64.4889 60.93 64.5633C60.2264 64.5633 60.2264 65.6787 60.93 65.6787Z"
				fill="#161616"
			/>
			<path
				d="M61.2644 60.5107C61.5977 61.4774 62.1532 62.1466 63.042 62.6671C63.4493 62.8902 63.8196 62.5928 63.8937 62.1838C64.1159 60.2133 64.6343 58.3171 65.449 56.4953C65.7453 55.8632 64.7825 55.2683 64.4862 55.9376C63.5974 57.9453 63.042 60.0274 62.7827 62.1838C63.079 62.0351 63.3382 61.8492 63.6345 61.7005C63.0049 61.3287 62.6346 60.8825 62.3754 60.1761C62.1162 59.544 61.0422 59.8415 61.2644 60.5107Z"
				fill="#161616"
			/>
			<path
				d="M62.0035 75.1223C62.2628 77.6134 62.9293 79.9557 64.0403 82.1865C64.3365 82.8186 65.2994 82.2609 65.0031 81.6288C64.0032 79.5839 63.3737 77.3903 63.1515 75.1223C63.0404 74.4159 61.9295 74.4159 62.0035 75.1223Z"
				fill="#161616"
			/>
			<path
				d="M62.5238 75.011C65.3752 75.2713 68.0415 74.7508 70.5967 73.4866C70.3375 73.3751 70.0412 73.2636 69.782 73.152C70.2634 75.42 71.2633 77.3905 72.8186 79.138C73.3 79.6585 74.0777 78.8777 73.5963 78.3572C72.1891 76.7585 71.3003 74.9738 70.856 72.8918C70.7819 72.52 70.3375 72.4084 70.0412 72.5571C67.6712 73.7097 65.1531 74.1559 62.5238 73.9328C61.8202 73.8585 61.8202 74.9738 62.5238 75.011Z"
				fill="#161616"
			/>
			<path
				d="M65.0392 82.5956C67.9277 81.5174 70.7791 80.3648 73.5565 79.1007C74.2231 78.8033 73.6306 77.8366 73.001 78.134C70.2977 79.361 67.5203 80.4764 64.7429 81.5174C64.0763 81.7777 64.3726 82.8559 65.0392 82.5956Z"
				fill="#161616"
			/>
			<path
				d="M64.4483 76.9815C65.3371 77.7623 67.3738 80.4393 68.4107 78.4687C68.8921 77.5764 68.781 76.3495 69.0773 75.3456C69.5217 73.9328 70.2623 72.6315 71.2252 71.5161C71.6695 70.9584 70.8919 70.1776 70.4475 70.7353C69.4106 71.9623 68.5959 73.3007 68.1145 74.8623C67.8923 75.5687 67.7071 76.3123 67.596 77.0559C67.4479 77.9854 67.1887 77.7623 66.4851 77.3162C66.0407 77.0187 65.6333 76.5726 65.226 76.2008C64.7076 75.7174 63.9299 76.4982 64.4483 76.9815Z"
				fill="#161616"
			/>
			<path
				d="M21.0472 66.4594C31.4161 66.3478 41.748 65.4927 51.9688 63.8568C52.6724 63.7453 52.3761 62.667 51.6725 62.7786C41.5258 64.3773 31.305 65.2325 21.0472 65.3068C20.3436 65.344 20.3436 66.4594 21.0472 66.4594Z"
				fill="#161616"
			/>
			<path
				d="M19.8597 51.3645C26.5625 51.5504 33.2282 51.3273 39.8939 50.7696C40.5975 50.6952 40.5975 49.5798 39.8939 49.6542C33.2282 50.2119 26.5255 50.3978 19.8597 50.2491C19.1561 50.2119 19.1561 51.3273 19.8597 51.3645Z"
				fill="#161616"
			/>
			<path
				d="M21.4535 68.6532C28.4895 68.9878 35.5256 68.7276 42.4875 67.8353C43.1911 67.7609 43.1911 66.6455 42.4875 66.7199C35.4885 67.575 28.4525 67.8724 21.4535 67.5378C20.7499 67.5006 20.7499 68.616 21.4535 68.6532Z"
				fill="#161616"
			/>
			<path
				d="M44.8597 67.3146C46.8595 67.3518 48.8221 67.0915 50.7478 66.4595C51.4144 66.2364 51.1181 65.1582 50.4515 65.3813C48.637 65.9762 46.7854 66.2364 44.8597 66.1992C44.1561 66.1992 44.1561 67.3146 44.8597 67.3146Z"
				fill="#161616"
			/>
			<path
				d="M25.2313 82.6328C33.6745 82.1122 42.0807 81.3315 50.4499 80.1789C51.1535 80.0674 51.1535 78.952 50.4499 79.0635C42.0807 80.1789 33.6745 80.9968 25.2313 81.5174C24.4906 81.5545 24.4906 82.6699 25.2313 82.6328Z"
				fill="#161616"
			/>
			<path
				d="M26.1957 85.2353C28.9731 85.2353 31.7504 85.2353 34.5278 85.2353C35.2314 85.2353 35.2314 84.1199 34.5278 84.1199C31.7504 84.1199 28.9731 84.1199 26.1957 84.1199C25.4921 84.1199 25.4921 85.2353 26.1957 85.2353Z"
				fill="#161616"
			/>
			<path
				d="M28.5998 87.7264C32.9325 87.4661 37.2653 87.0572 41.598 86.5366C45.079 86.0905 48.8192 85.9046 51.8187 83.8597C52.4113 83.4507 51.8558 82.484 51.2633 82.893C48.1156 85.0494 43.8939 85.161 40.2278 85.57C36.3765 86.0161 32.4882 86.3507 28.6368 86.611C27.8962 86.6482 27.8592 87.7636 28.5998 87.7264Z"
				fill="#161616"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2462_41293">
				<rect width={115} height={122} fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default DuplicateQuestionaire;
