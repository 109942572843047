import { StoreType } from '../types';

export const selectToken = (state: StoreType) => state.user.data?.token || '';

export const selectUser = (state: StoreType) => state.user;

export const selectSubscriptions = (state: StoreType) =>
	state.user.data?.subscriptions || [];

export const selectUserRouters = (state: StoreType) =>
	state.user.data?.routers || [];
