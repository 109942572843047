import { UpdateTeacherDto } from '@Api/generated';
import { ErrorType } from '@Configs/schemas';

import { PhotoUserResponse, User } from '@Configs/schemas/User/User';
import types, {
	RegisterConfirmUserStatusAction,
	SocialLoginUserSuccessAction,
	UpdateAfterSuccesAction,
	UpdateLoadingAction,
	UpdatePhotoUserAction,
	UpdatePhotoUserErrorAction,
	UpdatePhotoUserSuccessAction,
	UpdateUserAction,
	UpdateUserErrorAction,
	UpdateUserSuccessAction,
} from './types';

import {
	LoginUserWithEmailPasswordAction,
	LoginUserWithEmailPasswordSuccessAction,
	LoginUserWithEmailPasswordErrorAction,
	SetLoginLoadingAction,
	SetRegisterConfirmUserLoadingAction,
	RegisterConfirmUserAction,
	RegisterConfirmUserSuccessAction,
	RegisterConfirmUserErrorAction,
} from './types';

export const setLoginLoadingAction = (
	loading: boolean
): SetLoginLoadingAction => ({
	type: types.SET_LOGIN_LOADING,
	payload: {
		loading,
	},
});

export const loginUserWithEmailPasswordAction = (
	email: string,
	password: string
): LoginUserWithEmailPasswordAction => ({
	type: types.LOGIN_USER_WITH_EMAIL_PASSWORD,
	payload: {
		email,
		password,
	},
});

export const loginUserWithEmailPasswordSuccessAction = (
	data: User
): LoginUserWithEmailPasswordSuccessAction => {
	return {
		type: types.LOGIN_USER_WITH_EMAIL_PASSWORD_SUCCESS,
		payload: {
			data,
		},
	};
};

export const loginUserWithEmailPasswordErrorAction = (
	error: ErrorType
): LoginUserWithEmailPasswordErrorAction => ({
	type: types.LOGIN_USER_WITH_EMAIL_PASSWORD_ERROR,
	payload: {
		error,
	},
});

export const SetRegisterConfirmUserLoading = (
	loading: boolean
): SetRegisterConfirmUserLoadingAction => ({
	type: types.SET_REGISTER_CONFIRM_LOADING,
	payload: {
		loading,
	},
});

export const registerConfirmUser = (
	token: string | null
): RegisterConfirmUserAction => ({
	type: types.REGISTER_CONFIRM_USER,
	payload: {
		token: token,
	},
});

export const registerConfirmUserSuccess = (
	data: User
): RegisterConfirmUserSuccessAction => {
	return {
		type: types.REGISTER_CONFIRM_USER_SUCCESS,
		payload: {
			data,
			loading: false,
			status: null,
		},
	};
};

export const registerConfirmUserErrorAction = (
	error: ErrorType
): RegisterConfirmUserErrorAction => ({
	type: types.REGISTER_CONFIRM_USER_ERROR,
	payload: {
		error,
		status: 'error',
	},
});

export const registerConfirmUserStatusAction = (
	status: string
): RegisterConfirmUserStatusAction => ({
	type: types.REGISTER_CONFIRM_USER_STATUS,
	payload: {
		status,
		loading: false,
	},
});

export const toggleFirstUseAction = () => ({
	type: types.TOGGLE_FIRST_USE,
});

export const socialLoginUserAction = (code: string) => ({
	type: types.SOCIAL_LOGIN_USER,
	payload: {
		code,
	},
});

export const socialLoginUserSuccessAction = (data: User) =>
	({
		type: types.SOCIAL_LOGIN_USER_SUCCESS,
		payload: {
			data,
		},
	} as SocialLoginUserSuccessAction);

export const setUpdateLoadingAction = (
	loading: boolean
): UpdateLoadingAction => ({
	type: types.UPDATE_USER_LOADING,
	payload: {
		loading,
	},
});

export const updateUserAction = (payload: {
	data: Partial<User>;
	id: number;
}): UpdateUserAction => ({
	type: types.UPDATE_USER,
	payload,
});

export const updateUserSuccessAction = (
	data: Partial<User>
): UpdateUserSuccessAction => {
	return {
		type: types.UPDATE_USER_SUCCESS,
		payload: {
			data,
		},
	};
};

export const updateUserErrorAction = (
	error: ErrorType
): UpdateUserErrorAction => ({
	type: types.UPDATE_USER_ERROR,
	payload: {
		error,
	},
});

export const updatePhotoUserAction = (payload: {
	putTeacherId: number;
	changes: UpdateTeacherDto;
}): UpdatePhotoUserAction => ({
	type: types.UPDATE_PHOTO_USER,
	payload,
});

export const updatePhotoUserSuccessAction = (
	data: PhotoUserResponse
): UpdatePhotoUserSuccessAction => {
	return {
		type: types.UPDATE_PHOTO_USER_SUCCESS,
		payload: data,
	};
};

export const updatePhotoUserErrorAction = (
	error: ErrorType
): UpdatePhotoUserErrorAction => ({
	type: types.UPDATE_PHOTO_USER_ERROR,
	payload: {
		error,
	},
});

export const updatePhotoUserLoadingAction = (
	loading: boolean
): UpdateLoadingAction => ({
	type: types.UPDATE_USER_LOADING,
	payload: {
		loading,
	},
});

export const updateUserAfterSuccesAction = (
	modal: boolean,
	status: string | null
): UpdateAfterSuccesAction => ({
	type: types.UPDATE_USER_AFTER_SUCCES,
	payload: {
		modal,
		status,
	},
});

export const getUserRoutersAction = () => ({
	type: types.GET_USER_ROUTERS,
});

export const getUserRoutersSuccessAction = (data: string[]) => ({
	type: types.GET_USER_ROUTERS_SUCCESS,
	payload: {
		data,
	},
});

export const getUserRoutersErrorAction = (error: ErrorType) => ({
	type: types.GET_USER_ROUTERS_ERROR,
	payload: {
		error,
	},
});
