import * as React from 'react';

const Upload = () => (
	<svg
		width={13}
		height={13}
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1693_13679)">
			<path
				d="M2.57944 4.49878C2.88536 4.80445 3.38118 4.80469 3.68684 4.49881L5.48193 2.70492V8.64449C5.48193 9.07693 5.83263 9.42762 6.26506 9.42762C6.6975 9.42762 7.0482 9.07693 7.0482 8.64449V2.70492L8.84402 4.50074C9.14968 4.80641 9.64551 4.80641 9.95142 4.50071C10.2571 4.19505 10.2571 3.69923 9.95142 3.39332L6.81889 0.260784C6.66642 0.106507 6.46574 0.0300293 6.26506 0.0300293C6.06439 0.0300293 5.86371 0.106507 5.71198 0.259463L2.57944 3.39261C2.27304 3.69852 2.27304 4.19287 2.57944 4.49878ZM11.747 8.64449H7.83133C7.83133 9.50961 7.13018 10.2108 6.26506 10.2108C5.39995 10.2108 4.6988 9.50961 4.6988 8.64449H0.783133C0.350697 8.64449 0 8.99519 0 9.42762L0 11.777C0 12.2095 0.350697 12.5602 0.783133 12.5602H11.747C12.1794 12.5602 12.5301 12.2095 12.5301 11.777V9.42762C12.5301 8.99445 12.1802 8.64449 11.747 8.64449ZM10.5723 11.1897C10.2493 11.1897 9.98494 10.9254 9.98494 10.6023C9.98494 10.2793 10.2493 10.015 10.5723 10.015C10.8953 10.015 11.1596 10.2793 11.1596 10.6023C11.1596 10.9254 10.8953 11.1897 10.5723 11.1897Z"
				fill="#009EF7"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1693_13679">
				<rect
					width={12.5301}
					height={12.5301}
					fill="white"
					transform="translate(0 0.0300293)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default Upload;
