import * as React from 'react';

const IconSurveyQuestion = () => (
	<svg
		width={14}
		height={12}
		viewBox="0 0 14 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.40625 0.3125C2.76992 0.3125 3.0625 0.606445 3.0625 0.96875V2.28125C3.0625 2.64492 2.76992 2.9375 2.40625 2.9375H1.09375C0.731445 2.9375 0.4375 2.64492 0.4375 2.28125V0.96875C0.4375 0.606445 0.731445 0.3125 1.09375 0.3125H2.40625ZM13.125 0.75C13.609 0.75 14 1.14184 14 1.625C14 2.10898 13.609 2.5 13.125 2.5H5.25C4.76602 2.5 4.375 2.10898 4.375 1.625C4.375 1.14184 4.76602 0.75 5.25 0.75H13.125ZM13.125 5.125C13.609 5.125 14 5.51602 14 6C14 6.48398 13.609 6.875 13.125 6.875H5.25C4.76602 6.875 4.375 6.48398 4.375 6C4.375 5.51602 4.76602 5.125 5.25 5.125H13.125ZM13.125 9.5C13.609 9.5 14 9.89102 14 10.375C14 10.859 13.609 11.25 13.125 11.25H5.25C4.76602 11.25 4.375 10.859 4.375 10.375C4.375 9.89102 4.76602 9.5 5.25 9.5H13.125ZM0.4375 5.34375C0.4375 4.98008 0.731445 4.6875 1.09375 4.6875H2.40625C2.76992 4.6875 3.0625 4.98008 3.0625 5.34375V6.65625C3.0625 7.01992 2.76992 7.3125 2.40625 7.3125H1.09375C0.731445 7.3125 0.4375 7.01992 0.4375 6.65625V5.34375ZM2.40625 9.0625C2.76992 9.0625 3.0625 9.35508 3.0625 9.71875V11.0312C3.0625 11.3949 2.76992 11.6875 2.40625 11.6875H1.09375C0.731445 11.6875 0.4375 11.3949 0.4375 11.0312V9.71875C0.4375 9.35508 0.731445 9.0625 1.09375 9.0625H2.40625Z"
			fill="#009EF7"
		/>
	</svg>
);

export default IconSurveyQuestion;
