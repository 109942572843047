import * as React from 'react';
import { SVGProps } from 'react';
const GasBattery = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={23}
		viewBox="0 0 24 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4 0.25C2.52917 0.25 1.33333 1.51123 1.33333 3.0625V19.9375C0.595833 19.9375 0 20.5659 0 21.3438C0 22.1216 0.595833 22.75 1.33333 22.75H13.3333C14.0708 22.75 14.6667 22.1216 14.6667 21.3438C14.6667 20.5659 14.0708 19.9375 13.3333 19.9375V13.6094H14C14.9208 13.6094 15.6667 14.396 15.6667 15.3672V16.7734C15.6667 18.5225 17.0083 19.9375 18.6667 19.9375C20.325 19.9375 21.6667 18.5225 21.6667 16.7734V11.3374C23.0208 10.8892 24 9.55762 24 7.98438V6.57812C24 6.19141 23.7 5.875 23.3333 5.875H22.6667V3.76562C22.6667 3.37891 22.3667 3.0625 22 3.0625C21.6333 3.0625 21.3333 3.37891 21.3333 3.76562V5.875H20V3.76562C20 3.37891 19.7 3.0625 19.3333 3.0625C18.9667 3.0625 18.6667 3.37891 18.6667 3.76562V5.875H18C17.6333 5.875 17.3333 6.19141 17.3333 6.57812V7.98438C17.3333 9.55762 18.3125 10.8892 19.6667 11.3374V16.7734C19.6667 17.3579 19.2208 17.8281 18.6667 17.8281C18.1125 17.8281 17.6667 17.3579 17.6667 16.7734V15.3672C17.6667 13.2314 16.025 11.5 14 11.5H13.3333V3.0625C13.3333 1.51123 12.1375 0.25 10.6667 0.25H4Z"
			fill="#5E6278"
		/>
		<path
			d="M9.94854 4.1648C10.0638 3.88385 9.97783 3.55574 9.74153 3.37528C9.50523 3.19482 9.18299 3.21122 8.96231 3.41219L3.96282 8.00578C3.76753 8.18624 3.69918 8.47539 3.78901 8.73173C3.87884 8.98807 4.11515 9.15623 4.37489 9.15623H6.5524L5.0506 12.8352C4.93538 13.1161 5.02131 13.4443 5.25761 13.6247C5.49392 13.8052 5.81615 13.7888 6.03683 13.5878L11.0363 8.99422C11.2316 8.81376 11.3019 8.52461 11.2101 8.27032C11.1183 8.01603 10.8859 7.84582 10.6243 7.84582H8.44674L9.94854 4.1648Z"
			fill="#FFC700"
		/>
	</svg>
);
export default GasBattery;
