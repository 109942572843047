import * as React from 'react';

const SwitchBlockSVG = () => (
	<svg
		width={48}
		height={30}
		viewBox="0 0 48 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={42.8} height={30} rx={15} fill="#E8EAED" />
		<circle cx={15} cy={15} r={11} fill="white" />
		<path
			d="M11.625 13.5V12.375C11.625 10.511 13.1367 9 15 9C16.8633 9 18.375 10.511 18.375 12.375V13.5H18.75C19.5773 13.5 20.25 14.1727 20.25 15V19.5C20.25 20.3273 19.5773 21 18.75 21H11.25C10.4215 21 9.75 20.3273 9.75 19.5V15C9.75 14.1727 10.4215 13.5 11.25 13.5H11.625ZM13.125 13.5H16.875V12.375C16.875 11.3395 16.0359 10.5 15 10.5C13.9641 10.5 13.125 11.3395 13.125 12.375V13.5Z"
			fill="#B5B5C3"
		/>
	</svg>
);

export default SwitchBlockSVG;
