import * as React from 'react';

const GomaBorrar = () => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1196_4630)">
			<path
				d="M13.0279 11.3055C13.5042 11.3055 13.889 11.6903 13.889 12.1666C13.889 12.6429 13.5042 13.0277 13.0279 13.0277H4.16384C3.70906 13.0277 3.27044 12.8474 2.94752 12.5245L0.793934 10.3717C0.121423 9.69899 0.121423 8.60646 0.793934 7.93371L7.07278 1.65487C7.74552 0.982394 8.83806 0.982394 9.5108 1.65487L13.2055 5.35038C13.8782 6.02312 13.8782 7.11566 13.2055 7.7884L9.6884 11.3055H13.0279ZM7.25307 11.3055L9.0641 9.49448L5.3667 5.79708L2.01187 9.15272L4.16384 11.3055H7.25307Z"
				fill="#A5A9BA"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1196_4630">
				<rect
					width={13.7778}
					height={13.7778}
					fill="white"
					transform="translate(0.111328 0.111084)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default GomaBorrar;
