/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLClient } from 'graphql-request';
import { useEffect } from 'react';
import { ErrorType } from '@Configs/schemas';
import { getSdk } from './generated';
import { GraphQLErrorType } from './types';
import { imgUrlGetter } from '@Utils/functions';

const API_URL =
	process.env.REACT_APP_API_URL ?? 'https://api.test.e-valuados.com/v1/graphql';

const graphQLClient = new GraphQLClient(API_URL);

export function isGraphQLError(
	e: unknown | GraphQLErrorType
): e is GraphQLErrorType {
	return (e as GraphQLErrorType).response?.errors !== undefined;
}

export function GraphQLErrorAdapter(e: GraphQLErrorType): ErrorType {
	if (!isGraphQLError(e)) return e;
	return {
		code: e.response.errors[0].extensions?.code ?? '',
		message: {
			description: e.response.errors[0].message,
			title: 'GraphQL Error',
		},
	};
}

function getImgUrlFromFile(
	files?:
		| {
				__typename?: 'FileType' | undefined;
				bucket_name: string;
				name: string;
		  }[]
		| null
): string | undefined {
	if (!files || files.length === 0) return undefined;
	return imgUrlGetter(files[0].bucket_name, files[0].name);
}

export function setGqlHeader(value: string) {
	graphQLClient.setHeader('Authorization', `Bearer ${value}`);
}

export const useGraphQLConfig = (token: string) => {
	useEffect(() => {
		// graphQLClient.setHeader('Authorization', `Bearer ${token}`);
	}, [token]);
};

export default { ...getSdk(graphQLClient), getImgUrlFromFile };

function _generateDescriptor(
	descriptor: PropertyDescriptor,
	target: string,
	propertyKey: string
): PropertyDescriptor {
	// Save a reference to the original method
	const originalMethod = descriptor.value;

	// Rewrite original method with try/catch wrapper
	descriptor.value = function (...args: any[]) {
		const result = originalMethod.apply(this, args);

		// Return promise
		return result.catch((error: any) => {
			console.error(
				'Caught Error in GraphQL Request:',
				target + '.' + propertyKey,
				{
					cause: error,
				}
			);
			if (isGraphQLError(error)) {
				throw GraphQLErrorAdapter(error);
			}
			throw error;
		});
	};
	return descriptor;
}

// Class decorator to catch all errors in GraphQL requests
export const GraphQLCatch = (): any => {
	return (target: any, propertyKey: string) => {
		// Iterate over class properties except constructor
		for (const propertyName of Reflect.ownKeys(target.prototype).filter(
			(prop) => prop !== 'constructor'
		)) {
			const desc = Object.getOwnPropertyDescriptor(
				target.prototype,
				propertyName
			)!;
			const isMethod = desc.value instanceof Function;
			if (!isMethod) continue;

			Object.defineProperty(
				target.prototype,
				propertyName,
				_generateDescriptor(desc, target.name, propertyName.toString())
			);
		}
	};
};

function _generateLogDescriptor(
	descriptor: PropertyDescriptor,
	target: string,
	propertyKey: string
): PropertyDescriptor {
	// Save a reference to the original method
	const originalMethod = descriptor.value;

	// Rewrite original method with try/catch wrapper
	descriptor.value = function (...args: any[]) {
		const result = originalMethod.apply(this, args);

		// Return promise
		return result.then((data: any) => {
			console.log(
				'✅ GraphQL Response',
				target + '.' + propertyKey + ': \n',
				data
			);
			return data;
		});
	};
	return descriptor;
}

export const LogReturn = (): any => {
	return (target: any, propertyKey: string) => {
		// Iterate over class properties except constructor
		for (const propertyName of Reflect.ownKeys(target.prototype).filter(
			(prop) => prop !== 'constructor'
		)) {
			const desc = Object.getOwnPropertyDescriptor(
				target.prototype,
				propertyName
			)!;
			const isMethod = desc.value instanceof Function;
			if (!isMethod) continue;

			Object.defineProperty(
				target.prototype,
				propertyName,
				_generateLogDescriptor(desc, target.name, propertyName.toString())
			);
		}
	};
};
