import graphQLClient, { GraphQLCatch, LogReturn } from '@Api/graphQLClient';
import { IClientsApi } from '@Api/index';
import { Client } from '@Configs/schemas/Client/Client';

@LogReturn()
@GraphQLCatch()
class ClientsApiGrahpQL implements IClientsApi {
	async getClientSettings(clientId: number): Promise<Client> {
		//TODO: id de continental, cambiar por el id del cliente

		const { GetClientById: client } = await graphQLClient.GetClientSettings({
			getClientByIdId: clientId,
		});
		return {
			settings: client.settings,
			logo_image_url: client.logo_image,
		};
	}
}

export default new ClientsApiGrahpQL();
