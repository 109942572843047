import * as React from 'react';

const GraduadoBlanco = () => (
	<svg
		width={10.5}
		height={12}
		viewBox="0 0 12 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_2803_43300)">
			<path
				d="M1.06945 2.36914L1.21875 2.4043V3.77539C1.05469 3.87266 0.9375 4.04609 0.9375 4.25C0.9375 4.44629 1.0459 4.61047 1.19812 4.71289L0.832031 6.17188C0.791016 6.33359 0.881953 6.5 1.01086 6.5H1.98937C2.11828 6.5 2.2091 6.33301 2.16809 6.19297L1.80187 4.71406C1.95422 4.61094 2.0625 4.44688 2.0625 4.25C2.0625 4.04492 1.94531 3.87219 1.78125 3.77539V2.54211L3 2.83508L3.00002 4.25C3.00002 5.9082 4.34182 7.25 6.00002 7.25C7.65823 7.25 8.97893 5.9082 8.97893 4.25L9 2.83484L10.9277 2.36914C11.3555 2.2666 11.3555 1.73633 10.9277 1.63086L6.46523 0.552734C6.16055 0.482422 5.84133 0.482422 5.53641 0.552734L1.06922 1.63086C0.644531 1.73352 0.644531 2.26672 1.06945 2.36914ZM8.41875 7.83125L6 10.25L3.58125 7.83125C1.94227 8.35391 0.75 9.875 0.75 11.6867C0.75 12.1367 1.11398 12.5 1.56258 12.5H10.4384C10.8867 12.5 11.25 12.1367 11.25 11.6867C11.25 9.875 10.057 8.35391 8.41875 7.83125Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2803_43300">
				<rect
					width={12}
					height={12}
					fill="white"
					transform="translate(0 0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default GraduadoBlanco;
