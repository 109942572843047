import {
	CreateCaseDto,
	CreateFrom,
	GetCaseByIdQuery,
	GetQuestionnaireStatisticsQuery,
	UpdateCaseDto,
} from '@Api/generated';
import graphQLClient from '@Api/graphQLClient';
import { caseStatus } from '@Api/questionnaires';
import {
	Questionnaire,
	QuestionnaireWithResults,
} from '@Configs/schemas/Case/Questionnaire';
import User from '@Configs/schemas/User/User';

import { getClientFromCurrentUrl, sortByCreatedAt } from '@Utils/functions';
import { QuestionnaireFields } from '@Utils/helpers/Questionnaire';
import { PartialBy } from '@Utils/types';

export const createAdaptedQuestionnaire = (
	questionnaire: GetCaseByIdQuery['getCaseByID']
): Questionnaire => {
	const mappedData: Questionnaire = {
		...questionnaire,
		time: questionnaire.time ?? 45,
		topic: questionnaire.topic ?? '',
		description: questionnaire.description ?? '',

		results: {
			...questionnaire.results,
			studentAmount: questionnaire.results?.studentAmount ?? 0,
			responseRate: questionnaire.results?.responseRate ?? 0,
		},
		subjectStudentsAmount: questionnaire.subject?.student_ammount,
		subjectId: questionnaire.subject.id,
		questionnaireSetting: {
			id: questionnaire.questionnaire_setting?.id.toString() ?? '',
			aleatory_options:
				questionnaire.questionnaire_setting?.aleatory_options ?? false,
			show_correct_option:
				questionnaire.questionnaire_setting?.show_correct_option ?? false,
		},
		questions: questionnaire.questions?.map((question) => ({
			...question,
			title: question.name ?? '',
			imageSrc: graphQLClient.getImgUrlFromFile(question.files),
			justification: question.justification?.description ?? '',
			justificationId: question.justification?.id,
			justificationImageSrc: graphQLClient.getImgUrlFromFile(
				question.justification?.files
			),
			duration: question.custom_time ?? undefined,
			created_at: question.created_at,
			options:
				question.options?.map((option) => ({
					...option,
					isCorrect: !!option.isCorrect,
					created_at: option.created_at,
				})) ?? [],
		})),
	};
	return mappedData;
};

export const createAdaptedQuestionnaireStats = (
	q: PartialBy<
		GetQuestionnaireStatisticsQuery['getCaseByID'],
		'questionnaire_setting' | 'caseReviews'
	>
): QuestionnaireWithResults => {
	const mappedQuestionnaire = createAdaptedQuestionnaire(q);
	const withResults: QuestionnaireWithResults = {
		...mappedQuestionnaire,
		questionnaireResults: q.caseResults.map((r) => ({
			createdAt: +r.created_at,
			id: r.id,
			questionnaireId: q.id,
			score: r.score,
			studentId: r.student.id,
		})),

		reviews: q.caseReviews?.reduce(
			(acc, review) => {
				const key: 'positive' | 'negative' =
					review.value > 0 ? 'positive' : 'negative';
				return {
					...acc,
					[key]: acc[key] + 1,
				};
			},
			{
				positive: 0,
				negative: 0,
			}
		) ?? {
			positive: 0,
			negative: 0,
		},
		questions:
			mappedQuestionnaire.questions?.map((question, index) => ({
				...question,
				created_at: question.created_at,
				results:
					q.questions?.[index]?.results
						?.filter((r) => r.case.id !== null)
						.map((result) => ({
							createdAt: +result.created_at,
							id: result.id,
							questionId: question.id,
							outOfTime: result.out_of_time,
							timeSpent: result.time_spent,
							correct: result.correct,
							//TODO Ask what to do when there is no answer
							optionId: result.step_option_results[0]?.step_option?.id ?? '',
							user: {
								id: result.student.id,
								name: User.getFullName(result.student?.user),
								photo: '',
							},
						}))
						.sort(sortByCreatedAt) ?? [],
			})) ?? [],
	};
	return withResults;
};

export const createGraphQLAdaptedQuestionnaire = (
	q: QuestionnaireFields
): CreateCaseDto => {
	const formattedQuestionnaire: CreateCaseDto = {
		create_from: q.create_from as CreateFrom,
		caseType: 1,
		client: getClientFromCurrentUrl(),
		date_from: new Date(q.from as Date).toISOString(),
		date_to: new Date(q.to as Date).toISOString(),
		description: q.description,
		name: q.name,
		order: 3,
		specific_learning_goal: q.specificLearningGoal,
		questionnaireSettings: {
			is_math: false,
			aleatory_options: q.randomQuestions,
			show_correct_option: q.showCorrectAnswers,
		},
		caseStatus: caseStatus.Active,
		steps: q.questions.map((question, index) => ({
			is_math: false,
			title: question.name,
			stepType: 1,
			justification: {
				description: question.justification,
				...(question.justificationImageSrc
					? {
							files: [
								{
									[question.justificationImageSrc?.includes('base64')
										? 'base64'
										: 'contentUrl']: question.justificationImageSrc,
								},
							],
					  }
					: {}),
			},
			custom_time: question.duration,
			order: index,
			...(question.imageSrc
				? {
						files: [
							{
								[question.imageSrc?.includes('base64')
									? 'base64'
									: 'contentUrl']: question.imageSrc,
							},
						],
				  }
				: {}),
			// Todo: justification image handling

			step_options: question.options.map((option) => ({
				correct: option.isCorrect,
				title: option.text,
				is_math: false,
			})),
		})),
		subject: +q.subjectId,
		time: q.questionTime,
		topic: q.topic,
	};
	return formattedQuestionnaire;
};

// Create a function that adapts the questionnaire to the GraphQL format but as update
export const createGraphQLAdaptedQuestionnaireUpdate = (
	q: QuestionnaireFields
): UpdateCaseDto => {
	const formattedAsCreate = createGraphQLAdaptedQuestionnaire(q);
	const formattedQuestionnaire: UpdateCaseDto = {
		...formattedAsCreate,
		questionnaireSettings: {
			...formattedAsCreate.questionnaireSettings,
			id: +q.questionnaireSettings.questionnaireSettingId,
			aleatory_options: q.randomQuestions,
			show_correct_option: q.showCorrectAnswers,
		},
		order: formattedAsCreate.order ?? undefined,
		steps: formattedAsCreate.steps.map((step, questionIndex) => ({
			...step,
			id: +q.questions[questionIndex].id,
			justification: {
				...step.justification,
				id: +(q.questions[questionIndex].justificationId ?? 0),
			},
			step_options: step.step_options.map((option, optionIndex) => ({
				...option,
				id: +q.questions[questionIndex].options[optionIndex].id,
			})),
		})),
	};

	return formattedQuestionnaire;
};
