import * as React from 'react';

const Asignatura = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={36} height={36} rx={8} fill="#F1FAFF" />
		<g clipPath="url(#clip0_258_1730)">
			<path
				d="M12.4727 11.5C12.4727 10.6716 13.1405 10 13.9636 10C14.7867 10 15.4545 10.6716 15.4545 11.5C15.4545 12.3284 14.7867 13 13.9636 13C13.1405 13 12.4727 12.3284 12.4727 11.5ZM12.7212 26C12.1714 26 11.7273 25.5531 11.7273 25V18.0281L10.8399 19.5156C10.5572 19.9875 9.94657 20.1406 9.476 19.8562C9.00543 19.5719 8.85323 18.9594 9.1362 18.4844L10.9461 15.4563C11.485 14.5531 12.4541 14 13.5008 14H17.9394V11.5C17.9394 10.6716 18.6072 10 19.4303 10H26.3879C27.211 10 27.8788 10.6716 27.8788 11.5V18.5C27.8788 19.3281 27.211 20 26.3879 20H19.4303C18.6072 20 17.9394 19.3281 17.9394 18.5V17H19.9273V18H25.8909V12H19.9273V14H20.4242C20.974 14 21.4182 14.4469 21.4182 15C21.4182 15.5531 20.974 16 20.4242 16H16.2V25C16.2 25.5531 15.7558 26 15.2061 26C14.6563 26 14.2121 25.5531 14.2121 25V21H13.7151V25C13.7151 25.5531 13.271 26 12.7212 26Z"
				fill="#009EF7"
			/>
		</g>
		<defs>
			<clipPath id="clip0_258_1730">
				<rect
					width={19.8788}
					height={16}
					fill="white"
					transform="translate(8 10)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default Asignatura;
