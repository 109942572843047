import * as React from 'react';

interface Props {
	color?: string | undefined;
}

const TachitoBasura = ({ color }: Props) => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1196_4704)">
			<path
				d="M4.61036 0.587117C4.75567 0.295362 5.05437 0.111084 5.37998 0.111084H8.61991C8.94552 0.111084 9.24422 0.295362 9.38953 0.587117L9.58328 0.972195H12.1666C12.6429 0.972195 13.0277 1.35781 13.0277 1.83331C13.0277 2.3088 12.6429 2.69442 12.1666 2.69442H1.83328C1.35778 2.69442 0.972168 2.3088 0.972168 1.83331C0.972168 1.35781 1.35778 0.972195 1.83328 0.972195H4.41661L4.61036 0.587117ZM1.80906 3.55553H12.1666V12.1666C12.1666 13.1166 11.3943 13.8889 10.4444 13.8889H3.53128C2.60424 13.8889 1.80906 13.1166 1.80906 12.1666V3.55553ZM3.96184 5.70831V11.7361C3.96184 11.9729 4.17981 12.1666 4.39239 12.1666C4.65342 12.1666 4.82295 11.9729 4.82295 11.7361V5.70831C4.82295 5.4715 4.65342 5.27775 4.39239 5.27775C4.17981 5.27775 3.96184 5.4715 3.96184 5.70831ZM6.54517 5.70831V11.7361C6.54517 11.9729 6.76314 12.1666 6.97573 12.1666C7.23675 12.1666 7.4305 11.9729 7.4305 11.7361V5.70831C7.4305 5.4715 7.23675 5.27775 6.97573 5.27775C6.76314 5.27775 6.54517 5.4715 6.54517 5.70831ZM9.15272 5.70831V11.7361C9.15272 11.9729 9.34647 12.1666 9.58328 12.1666C9.82008 12.1666 10.0138 11.9729 10.0138 11.7361V5.70831C10.0138 5.4715 9.82008 5.27775 9.58328 5.27775C9.34647 5.27775 9.15272 5.4715 9.15272 5.70831Z"
				fill={color ? color : '#A5A9BA'}
			/>
		</g>
		<defs>
			<clipPath id="clip0_1196_4704">
				<rect
					width={13.7778}
					height={13.7778}
					fill="white"
					transform="translate(0.111328 0.111084)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default TachitoBasura;
