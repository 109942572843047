import * as React from 'react';
type Props = {
	color?: string;
};

const CheckSuccess = ({ color }: Props) => (
	<svg
		width={12}
		height={13}
		viewBox="0 1 12 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.1114 2.72573C11.409 3.02623 11.409 3.51184 11.1114 3.81233L5.01583 9.96652C4.71819 10.267 4.23722 10.267 3.93958 9.96652L0.891124 6.88943C0.593584 6.58893 0.593584 6.10333 0.891124 5.80283C1.18871 5.50233 1.67112 5.50233 1.96876 5.80283L4.45628 8.33422L10.0352 2.72573C10.3328 2.42476 10.8138 2.42476 11.1114 2.72573Z"
			fill={color ? color : '#00C26E'}
		/>
	</svg>
);

export default CheckSuccess;
