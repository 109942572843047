import * as React from 'react';

const Calendar = () => (
	<svg
		width={14}
		height={16}
		viewBox="0 0 14 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.75 2H9.25V0.75C9.25 0.335938 9.58438 0 10 0C10.4156 0 10.75 0.335938 10.75 0.75V2H12C13.1031 2 14 2.89531 14 4V14C14 15.1031 13.1031 16 12 16H2C0.895313 16 0 15.1031 0 14V4C0 2.89531 0.895313 2 2 2H3.25V0.75C3.25 0.335938 3.58437 0 4 0C4.41563 0 4.75 0.335938 4.75 0.75V2ZM1.5 14C1.5 14.275 1.72375 14.5 2 14.5H12C12.275 14.5 12.5 14.275 12.5 14V6H1.5V14Z"
			fill="#7E8299"
		/>
	</svg>
);

export default Calendar;
