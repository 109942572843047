import * as React from 'react';

const CopyIcon = () => (
	<svg
		width={16}
		height={16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_346_5425)">
			<path
				d="M15.7066 2.20719L13.7925 0.293125C13.6066 0.105344 13.3503 0 13.0847 0H7.97219C6.8675 0 5.97219 0.895625 5.97219 2L5.9728 10C6.00032 11.1062 6.89719 12 8.00032 12H14.0003C15.1003 12 16.0003 11.1 16.0003 10V2.91406C16.0003 2.64906 15.8941 2.39469 15.7066 2.20719ZM14.5003 10C14.5003 10.2761 14.2764 10.5 14.0003 10.5H7.97219C7.696 10.5 7.47219 10.2761 7.47219 10V2.00406C7.47219 1.72794 7.69607 1.50406 7.97219 1.50406H11.9722L12.0003 3C12.0003 3.55219 12.4481 4 13.0003 4H14.4722V10H14.5003ZM8.50032 14C8.50032 14.2761 8.27644 14.5 8.00032 14.5H1.97219C1.696 14.5 1.47219 14.2761 1.47219 14L1.49969 6.00312C1.49969 5.727 1.72356 5.50312 1.99969 5.50312H5.00031V4H2C0.895313 4 0 4.89531 0 6L0.00030625 14C0.000375 15.1031 0.895938 16 2.00031 16H8.00032C9.10032 16 10.0003 15.1 10.0003 14V13H8.52844L8.50032 14Z"
				fill="#0095E8"
			/>
		</g>
		<defs>
			<clipPath id="clip0_346_5425">
				<rect width={16} height={16} fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default CopyIcon;
