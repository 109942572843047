import { applyMiddleware, createStore as createReduxStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { persistStore } from 'redux-persist';
// import axios from 'axios';

/**
 * TODO: revisar configuracion de reglas de persistencia (State Reconciler)
 * https://github.com/rt2zz/redux-persist
 */

const createStore = () => {
	// create the saga middleware
	const sagaMiddleware = createSagaMiddleware();

	const middlewares = [sagaMiddleware];

	const store = createReduxStore(
		reducers,
		composeWithDevTools(applyMiddleware(...middlewares))
	);

	const persistor = persistStore(store, {});

	// then run the saga
	sagaMiddleware.run(sagas);

	return { store, persistor };
};

// Setup auth in axios
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// function setupAxios(axios: any, store: any) {
// 	axios.defaults.headers.Accept = 'application/json';
// 	axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// 	axios.interceptors.request.use(
// 		(config: any) => {
// 			const {
// 				auth: { accessToken },
// 			} = store.getState();

// 			if (accessToken) {
// 				config.headers.Authorization = `Bearer ${accessToken}`;
// 			}

// 			config.headers.CORS

// 			return config;
// 		},
// 		(err: any) => Promise.reject(err)
// 	);
// }

const { store, persistor } = createStore();
// setupAxios(axios, store);

export { persistor };
export default store;
