import * as React from 'react';

const Chevron = () => (
	<svg
		width={16}
		height={10}
		viewBox="0 0 16 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M-4.99486e-08 1.14269C-3.7166e-08 0.850258 0.111608 0.557684 0.334825 0.334824C0.781259 -0.111609 1.50448 -0.111609 1.95091 0.334824L8.00009 6.3856L14.0491 0.334825C14.4955 -0.111608 15.2187 -0.111608 15.6652 0.334825C16.1116 0.781258 16.1116 1.50448 15.6652 1.95091L8.80795 8.80813C8.36152 9.25456 7.6383 9.25456 7.19187 8.80813L0.334646 1.95091C0.110715 1.72841 -6.27499e-08 1.43555 -4.99486e-08 1.14269Z"
			fill="#7E8299"
		/>
	</svg>
);

export default Chevron;
