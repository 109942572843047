import * as React from 'react';

const EyePassword = () => (
	<svg
		width={20}
		height={16}
		viewBox="0 0 20 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.70937 3.21787C6.09374 2.20713 7.86874 1.4375 9.99999 1.4375C12.525 1.4375 14.5469 2.5168 16.0187 3.79883C17.4812 5.07031 18.4594 6.56738 18.9219 7.63965C19.025 7.87109 19.025 8.12891 18.9219 8.36035C18.5031 9.30664 17.6625 10.6396 16.425 11.8291L19.7125 14.2432C20.0375 14.4834 20.0969 14.9258 19.8406 15.2305C19.5844 15.5352 19.1125 15.5908 18.7875 15.3506L0.287369 1.75654C-0.0386306 1.51689 -0.0957869 1.0751 0.159744 0.769414C0.415307 0.463789 0.886557 0.410205 1.21281 0.649766L4.70937 3.21787ZM6.97187 4.87988L9.79374 6.9541C9.92499 6.70508 9.99999 6.42383 9.99999 6.09863C9.99999 5.78809 9.87812 5.47168 9.73749 5.19922C9.82499 5.19043 9.91249 5.16113 9.99999 5.16113C11.6562 5.16113 13 6.4209 13 7.97363C13 8.40137 12.9094 8.78223 12.7219 9.12793L13.9562 10.0127C14.3031 9.41504 14.5 8.72949 14.5 7.97363C14.5 5.6709 12.4844 3.75488 9.99999 3.75488C8.83437 3.75488 7.76874 4.19727 6.97187 4.87988ZM9.99999 14.5625C7.47499 14.5625 5.45312 13.4844 3.98124 12.2012C2.51937 10.9033 1.54187 9.40625 1.07687 8.36035C0.974369 8.12891 0.974369 7.87109 1.07687 7.63965C1.37499 6.96875 1.88406 6.10156 2.59656 5.23144L5.54374 7.4082C5.51562 7.60156 5.49999 7.79785 5.49999 7.97363C5.49999 10.3291 7.51562 12.2187 9.99999 12.2187C10.5844 12.2187 11.1437 12.1133 11.6562 11.9229L13.9437 13.6104C12.8094 14.1846 11.4937 14.5625 9.99999 14.5625Z"
			fill="#AFB0B9"
		/>
	</svg>
);

export default EyePassword;
