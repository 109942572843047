import types from './types';
import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getSubjectsErrorAction, getSubjectsSuccessAction } from './actions';

import Api, { ISubjectsApi } from '@Api/index';
import { getErrorInfo } from '@Configs/errors';

function* getSubjectsWatcher() {
	try {
		const subjects: SagaReturnType<ISubjectsApi['getSubjects']> = yield call(
			Api.Subjects.getSubjects
		);
		if (subjects) yield put(getSubjectsSuccessAction(subjects));
	} catch (error) {
		const currentError = getErrorInfo(error);
		console.log(currentError);
		yield put(getSubjectsErrorAction({ error: currentError }));
	}
}

export default function* subjectsSaga() {
	yield all([takeLatest(types.GET_SUBJECTS, getSubjectsWatcher)]);
}
