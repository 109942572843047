/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowRight from './ArrowRight';
import Bell from './Bell';
import OjoBloqueado from './OjoBloqueado';
import NotificationDetails from './NotificationDetails';
import Asignatura from './Asignatura';
import BellCentroAyuda from './BellCentroAyuda';
import Calendar from './Calendar';
import Chevron from './Chevron';
import CopyIcon from './CopyIcon';
import MasIcon from './MasIcon';
import Varita from './Varita';
import Cuestionario from './Cuestionario';
import Encuesta from './Encuesta';
import Estudiante from './Estudiante';
import Next from './Next';
import Previous from './Previous';
import Search from './Search';
import SearchFile from './SearchFile';
import Ellipse from './Ellipse';
import Check from './check';
import CheckCircle from './CheckCircle';
import EyePassword from './EyePassword';
import SignAlert from './AlertSign';
import EyeOpen from './eyeOpen';
import MultipleChoice from './MultipleChoice';
import TrueOrFalse from './TrueOrFalse';
import Organize from './Organize';
import FillInEmptySpaces from './FillInEmptySpaces';
import FileImport from './FileImport';
import CircleExclamation from './CircleExclamation';
import OrderedList from './OrderedList';
import TrueFalse from './TrueFalse';
import MultipleOption from './MultipleOption';
import ChevronRight from './ChevronRight';
import TachitoBasura from './TachitoBasura';
import GomaBorrar from './GomaBorrar';
import CopyRelleno from './CopyRelleno';
import Pencil from './Pencil';
import Megafono from './Megafono';
import IconoInformacion from './IconoInformacion';
import PaperQuestion from './PaperQuestion';
import StatsBarIcon from './StatsBarIcon';
import StatsBarIconYellow from './StatsBarIconYellow';
import AlertSignRed from './AlerSignRed';
import EmptyImage from './EmptyImage';
import BlueImage from './BlueImage';
import Upload from './Upload';
import SwitchBlockSVG from './SwitchBlockSVG';
import Heart from './Heart';
import YellowStudent from './YellowStudent';
import PurpleStudents from './PurpleStudents';
import GreenPencil from './GreenPencil';
import MasIconBlue from './MasIconBlue';

import StrokeX from './StrokeX';
import CheckSuccess from './CheckSuccess';
import XmarkDanger from './XmarkDanger';
import GraduadoBlanco from './GraduadoBlanco';
import GraduadoGris from './GraduadoGris';
import DuplicateQuestionaire from './DuplicateQuestionaire';
import EyeOpenSmall from './eyeOpenSmall';
import IconSurveyQuestion from './iconSurveyQuestion';
import CircleSolid1 from './CircleSolid1';
import CirculitoRojoError from './CirculitoRojoError';
import RedCircleWithX from './RedCircleWithX';
import MultipleOptionBlueSmall from './MultipleOptionBlueSmall';
import SadFace from './SadFace';
import Smile from './Smile';
import BigSmile from './BigSmile';
import MehFace from './MehFace';
import PaperPlaneTop from '@Assets/images/icons/PaperPlaneTop';
import GasBattery from '@Assets/images/icons/GasBattery';
import Detective from './Detective';
import LogoContinental from './LogoContinental';
import TXTfile from './TXTfile';

const customIcons = {
	xMarkDanger: (params: { color?: string }) => <XmarkDanger {...params} />,
	redCircleWithx: () => <RedCircleWithX />,

	checkSuccess: (params: { color?: string }) => <CheckSuccess {...params} />,

	graduadoBlanco: () => <GraduadoBlanco />,
	graduadoGris: () => <GraduadoGris />,
	ojoCerrado: () => <OjoBloqueado />,
	bell: (params: { color?: string }) => <Bell {...params} />,
	circleSolid1: (params: { color?: string }) => <CircleSolid1 {...params} />,

	logoContinental: () => <LogoContinental />,

	'notification-details': () => <NotificationDetails />,
	'arrow-right': () => <ArrowRight />,
	bellCentroAyuda: () => <BellCentroAyuda />,
	circulitoRojoError: () => <CirculitoRojoError />,
	mas: () => <MasIcon />,
	varita: () => <Varita />,
	chevron: () => <Chevron />,
	calendar: () => <Calendar />,
	copy: () => <CopyIcon />,
	asignatura: () => <Asignatura />,
	cuestionario: () => <Cuestionario />,
	estudiante: () => <Estudiante />,
	encuesta: () => <Encuesta />,
	search: () => <Search />,
	alertSignRed: () => <AlertSignRed />,
	ellipse: () => <Ellipse />,
	check: () => <Check />,
	checkCircle: () => <CheckCircle />,
	eyePassword: () => <EyePassword />,
	signAlert: () => <SignAlert />,
	eyeOpen: () => <EyeOpen />,
	multipleChoice: (params: { color?: string }) => (
		<MultipleChoice {...params} />
	),
	trueOrFalse: (params: { color?: string }) => <TrueOrFalse {...params} />,
	organize: (params: { color?: string }) => <Organize {...params} />,
	filEmptySpace: (params: { color?: string }) => (
		<FillInEmptySpaces {...params} />
	),
	fileImport: (params: { color?: string }) => <FileImport {...params} />,
	'true-false': () => <TrueFalse />,
	'multiple-option': (params: { color?: string }) => (
		<MultipleOption {...params} />
	),
	'ordered-list': () => <OrderedList />,
	'search-file': () => <SearchFile />,
	'circle-exclamation': () => <CircleExclamation />,
	chevronRight: () => <ChevronRight />,
	tachito: (params: { color?: string }) => <TachitoBasura {...params} />,

	goma: () => <GomaBorrar />,
	copyRelleno: () => <CopyRelleno />,
	pencil: () => <Pencil />,
	megafono: (props: CustomIconOptions) => <Megafono {...props} />,
	circuloI: () => <IconoInformacion />,
	iconSurveyQuestion: () => <IconSurveyQuestion />,
	statsIcon: (params: { color?: string }) => <StatsBarIcon {...params} />,

	statsIconYellow: () => <StatsBarIconYellow />,

	paperQuestion: () => <PaperQuestion />,
	next: (params: CustomIconOptions) => <Next {...params} />,
	previous: (params: CustomIconOptions) => <Previous {...params} />,
	image: () => <EmptyImage />,
	blueImage: () => <BlueImage />,
	upload: () => <Upload />,
	swichblocked: () => <SwitchBlockSVG />,
	yellowStudent: (params: { color?: string }) => <YellowStudent {...params} />,
	heart: (params: { color?: string }) => <Heart {...params} />,
	purpleStudents: (params: { color?: string }) => (
		<PurpleStudents {...params} />
	),
	greenPencil: (params: { color?: string }) => <GreenPencil {...params} />,
	masIconBlue: (params: CustomIconOptions) => <MasIconBlue {...params} />,
	strokeX: () => <StrokeX />,
	duplicateQuestionaire: () => <DuplicateQuestionaire />,
	eyeOpenSmall: (params: { color?: string; key?: string | number }) => (
		<EyeOpenSmall {...params} />
	),
	multipleOptionBlueSmall: () => <MultipleOptionBlueSmall />,
	'sad-face': () => <SadFace />,
	'smile-': () => <Smile />,
	'big-smile': () => <BigSmile />,
	'meh-face': () => <MehFace />,
	paperPlaneTop: () => <PaperPlaneTop />,
	GasBattery: () => <GasBattery />,
	detective: () => <Detective />,
	fileTxt: () => <TXTfile />,
};

export type CustomIconOptions = {
	color?: string;
	disabled?: boolean;
	style?: React.CSSProperties;
	hover?: boolean;
	key?: number | string;
} & Record<string, any>;

export type CustomIconProps = {
	icon?: keyof typeof customIcons;
	color?: string;
	className?: string;
	style?: React.CSSProperties;
	iconOptions?: CustomIconOptions;
} & React.HTMLAttributes<HTMLSpanElement>;

const CustomIcon = ({
	icon,
	iconOptions = {},
	className = '',
	...rest
}: CustomIconProps) => {
	return (
		<span className={className} {...rest}>
			{icon !== undefined && customIcons[icon](iconOptions)}
		</span>
	);
};

export default CustomIcon;
