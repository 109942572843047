import * as React from 'react';

const NotificationDetails = () => (
	<svg
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x={2.5} y={2.5} width={27} height={27} rx={6} fill="#F1FAFF" />
		<g clipPath="url(#clip0_304_2626)">
			<path
				d="M11.0869 10.2222C11.0869 9.48586 11.6805 8.88892 12.4122 8.88892C13.1438 8.88892 13.7374 9.48586 13.7374 10.2222C13.7374 10.9586 13.1438 11.5556 12.4122 11.5556C11.6805 11.5556 11.0869 10.9586 11.0869 10.2222ZM11.3078 23.1111C10.8191 23.1111 10.4243 22.7139 10.4243 22.2222V16.025L9.63549 17.3472C9.38425 17.7667 8.84145 17.9028 8.42316 17.65C8.00488 17.3972 7.8696 16.8528 8.12112 16.4306L9.72992 13.7389C10.2089 12.9361 11.0704 12.4445 12.0008 12.4445H15.9462V10.2222C15.9462 9.48586 16.5398 8.88892 17.2714 8.88892H23.4559C24.1876 8.88892 24.7812 9.48586 24.7812 10.2222V16.4445C24.7812 17.1806 24.1876 17.7778 23.4559 17.7778H17.2714C16.5398 17.7778 15.9462 17.1806 15.9462 16.4445V15.1111H17.7132V16H23.0142V10.6667H17.7132V12.4445H18.1549C18.6436 12.4445 19.0384 12.8417 19.0384 13.3334C19.0384 13.825 18.6436 14.2222 18.1549 14.2222H14.4001V22.2222C14.4001 22.7139 14.0052 23.1111 13.5166 23.1111C13.0279 23.1111 12.633 22.7139 12.633 22.2222V18.6667H12.1913V22.2222C12.1913 22.7139 11.7965 23.1111 11.3078 23.1111Z"
				fill="#0095E8"
			/>
		</g>
		<defs>
			<clipPath id="clip0_304_2626">
				<rect
					width={17.67}
					height={14.2222}
					fill="white"
					transform="translate(7.11108 8.88892)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default NotificationDetails;
