import * as React from 'react';

type Props = {
	disabled?: boolean;
	style?: React.CSSProperties;
	colors?: {
		disabled: {
			background: string;
			fill: string;
		};
		enabled: {
			background: string;
			fill: string;
		};
	};
};

const defaultColors = {
	disabled: {
		background: '#E3E4E9',
		fill: '#A1A5B7',
	},
	enabled: {
		background: '#B2E2FD',
		fill: '#0095E8',
	},
};

const Next = ({ disabled, style = {}, colors = defaultColors }: Props) => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={style}
	>
		<rect
			width={20}
			height={20}
			rx={4}
			fill={disabled ? colors.disabled.background : colors.enabled.background}
		/>
		<path
			d="M7.32131 16.4999C7.09285 16.4999 6.86428 16.4127 6.69017 16.2383C6.3414 15.8895 6.3414 15.3245 6.69017 14.9758L11.4173 10.2499L6.69017 5.52414C6.3414 5.17537 6.3414 4.61035 6.69017 4.26158C7.03894 3.91281 7.60395 3.91281 7.95273 4.26158L13.3099 9.61874C13.6587 9.96751 13.6587 10.5325 13.3099 10.8813L7.95273 16.2385C7.7789 16.4134 7.5501 16.4999 7.32131 16.4999Z"
			fill={disabled ? colors.disabled.fill : colors.enabled.fill}
		/>
	</svg>
);

export default Next;
