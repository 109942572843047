import * as React from 'react';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
	color?: string;
}

const Heart = (props: IProps) => (
	<svg
		width={48}
		height={42}
		viewBox="0 0 48 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0 14.8969V14.3531C0 7.8 4.73625 2.21063 11.1938 1.13438C15.3844 0.422814 19.8188 1.81594 22.875 4.87688L24 6L25.0406 4.87688C28.1813 1.81594 32.5313 0.422814 36.8063 1.13438C43.2656 2.21063 48 7.8 48 14.3531V14.8969C48 18.7875 46.3875 22.5094 43.5375 25.1625L26.5969 40.9781C25.8938 41.6344 24.9656 42 24 42C23.0344 42 22.1063 41.6344 21.4031 40.9781L4.46156 25.1625C1.61531 22.5094 2.8125e-05 18.7875 2.8125e-05 14.8969H0Z"
			fill={props.color ? props.color : '#F1416C'}
		/>
	</svg>
);

export default Heart;
