import * as React from 'react';

const OrderedList = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 16 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.721923 0.751251C0.721923 0.336876 1.08567 0.00125122 1.47192 0.00125122H2.47192C2.91567 0.00125122 3.22192 0.336876 3.22192 0.751251V4.5H3.72192C4.16567 4.5 4.47192 4.8375 4.47192 5.25C4.47192 5.66563 4.16567 6 3.72192 6H1.22192C0.835673 6 0.471923 5.66563 0.471923 5.25C0.471923 4.8375 0.835673 4.5 1.22192 4.5H1.72192V1.50125H1.47192C1.08567 1.50125 0.721923 1.16531 0.721923 0.751251ZM2.70942 9.6625C2.50317 9.43125 2.13755 9.44688 1.95786 9.7L1.61036 10.1844C1.36974 10.5219 0.901298 10.6 0.564111 10.3594C0.226923 10.1188 0.149111 9.65 0.389736 9.31563L0.737236 8.82813C1.47911 7.7875 2.99692 7.7125 3.83755 8.675C4.50317 9.40938 4.48755 10.5781 3.80317 11.3219L2.71255 12.5H3.75005C4.16567 12.5 4.50005 12.8344 4.50005 13.25C4.50005 13.6656 4.16567 14 3.75005 14H1.00005C0.702236 14 0.432548 13.825 0.313173 13.55C0.193486 13.2781 0.246923 12.9594 0.448798 12.7406L2.70005 10.3031C2.86567 10.1219 2.8688 9.84688 2.70942 9.6625ZM15 1C15.5532 1 16 1.44781 16 2C16 2.55313 15.5532 3 15 3H7.00005C6.44692 3 6.00005 2.55313 6.00005 2C6.00005 1.44781 6.44692 1 7.00005 1H15ZM15 6C15.5532 6 16 6.44688 16 7C16 7.55313 15.5532 8 15 8H7.00005C6.44692 8 6.00005 7.55313 6.00005 7C6.00005 6.44688 6.44692 6 7.00005 6H15ZM15 11C15.5532 11 16 11.4469 16 12C16 12.5531 15.5532 13 15 13H7.00005C6.44692 13 6.00005 12.5531 6.00005 12C6.00005 11.4469 6.44692 11 7.00005 11H15Z"
			fill="#009EF7"
		/>
	</svg>
);

export default OrderedList;
1;
