import * as React from 'react';

const EmptyImage = () => (
	<svg
		width={20}
		height={20}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1394_7054)">
			<path
				d="M17.1323 0.444336C15.7811 0.444336 2.79899 0.444336 2.79899 0.444336C1.51273 0.444336 0.443359 1.51374 0.443359 2.83322V17.1666C0.443359 18.486 1.51276 19.5554 2.79865 19.5554H17.132C18.4515 19.5554 19.5209 18.486 19.5209 17.1666V2.83322C19.5212 1.51374 18.4835 0.444336 17.1323 0.444336ZM4.59066 4.02767C5.58018 4.02767 6.38233 4.82981 6.38233 5.81934C6.38233 6.80886 5.6134 7.611 4.59066 7.611C3.56792 7.611 2.79899 6.80886 2.79899 5.81934C2.79899 4.82981 3.63436 4.02767 4.59066 4.02767ZM17.095 15.6586C16.9905 15.8527 16.7889 15.9721 16.5687 15.9721H3.50484C3.28009 15.9721 3.07446 15.846 2.97256 15.6455C2.87066 15.4452 2.88988 15.2047 3.02236 15.0229L5.6352 11.4396C5.74778 11.2839 5.92695 11.1943 6.11731 11.1943C6.30767 11.1943 6.48744 11.2854 6.59994 11.4397L7.81155 13.1011L11.2941 7.90902C11.4065 7.71178 11.5931 7.611 11.7909 7.611C11.9888 7.611 12.1772 7.71074 12.2877 7.87695L17.0655 15.0436C17.1883 15.2256 17.1995 15.4607 17.095 15.6586Z"
				fill="#A5A9BA"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1394_7054">
				<rect
					width={19.1111}
					height={19.1111}
					fill="white"
					transform="translate(0.443848 0.444336)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default EmptyImage;
