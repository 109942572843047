import types, { UserActions, UserStateType } from './types';
import { CLEAR_REDUCERS } from '../types';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { User } from '@Configs/schemas/User/User';
import { imgUrlGetter } from '@Utils/functions';

const initialState: UserStateType = {
	data: null,
	login: {
		loading: false,
		error: null,
	},
	registerConfirm: {
		loading: false,
		status: null,
		error: null,
	},
	updateProfile: {
		loading: false,
		error: null,
		modal: false,
		status: null,
	},
};

const userReducer = (
	state = initialState,
	action: UserActions
): UserStateType => {
	switch (action.type) {
		case types.SET_LOGIN_LOADING:
			return {
				...state,
				login: {
					loading: action.payload.loading,
					error: null,
				},
			};

		case types.LOGIN_USER_WITH_EMAIL_PASSWORD_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				login: {
					loading: false,
					error: null,
				},
			};

		case types.LOGIN_USER_WITH_EMAIL_PASSWORD_ERROR:
			return {
				...state,
				login: {
					loading: false,
					error: action.payload.error,
				},
			};

		case types.SET_REGISTER_CONFIRM_LOADING:
			return {
				...state,
				registerConfirm: {
					loading: action.payload.loading,
					status: null,
					error: null,
				},
			};

		case types.REGISTER_CONFIRM_USER_SUCCESS:
			return {
				...state,
				data: { ...state.data, ...action.payload.data, is_first_use: true },
				registerConfirm: {
					loading: true,
					status: null,
					error: null,
				},
			};

		case types.REGISTER_CONFIRM_USER_ERROR:
			return {
				...state,
				registerConfirm: {
					loading: false,
					status: action.payload.status,
					error: action.payload.error,
				},
			};

		case types.REGISTER_CONFIRM_USER_STATUS:
			return {
				...state,
				data: { ...state.data!, is_first_use: true },
				registerConfirm: {
					loading: false,
					status: action.payload.status,
					error: null,
				},
			};

		case types.TOGGLE_FIRST_USE:
			return {
				...state,

				data: {
					...state.data!,
					is_first_use: !state.data!.is_first_use,
				},
			};

		case types.UPDATE_USER_LOADING:
			return {
				...state,
				updateProfile: {
					error: null,
					loading: action.payload.loading,
					modal: true,
					status: null,
				},
			};

		case types.UPDATE_USER_SUCCESS:
			return {
				...state,
				data: { ...state.data, ...action.payload.data } as User,
				updateProfile: {
					error: null,
					loading: false,
					modal: true,
					status: 'success',
				},
			};

		case types.UPDATE_USER_ERROR:
			return {
				...state,
				updateProfile: {
					loading: false,
					error: action.payload.error,
					modal: true,
					status: null,
				},
			};
		case types.UPDATE_PHOTO_USER_LOADING:
			return {
				...state,
				updateProfile: {
					loading: action.payload.loading,
					error: null,
					modal: true,
					status: null,
				},
			};

		case types.UPDATE_PHOTO_USER_SUCCESS:
			return {
				...state,
				data: {
					...(state.data as User),
					photo:
						action.payload.profile_picture?.path &&
						action.payload.profile_picture?.bucket_name &&
						imgUrlGetter(
							action.payload.profile_picture?.bucket_name,
							action.payload.profile_picture?.path
						),
					teacher: {
						profile_picture: {
							path: action.payload.profile_picture?.path,
							bucket_name: action.payload.profile_picture?.bucket_name,
						},
						id: action.payload.id,
					},
				},
				updateProfile: {
					loading: false,
					error: null,
					modal: true,
					status: 'success',
				},
			};

		case types.UPDATE_PHOTO_USER_ERROR:
			return {
				...state,
				updateProfile: {
					loading: false,
					error: action.payload.error,
					modal: true,
					status: null,
				},
			};
		case types.UPDATE_USER_AFTER_SUCCES:
			return {
				...state,
				updateProfile: {
					loading: false,
					error: null,
					modal: false,
					status: null,
				},
			};

		case types.GET_USER_ROUTERS_SUCCESS:
			return {
				...state,
				data: {
					...state.data!,
					routers: action.payload.data,
				},
			};

		case types.GET_USER_ROUTERS_ERROR:
			return {
				...state,
				data: {
					...state.data!,
					routers: [],
				},
			};

		case CLEAR_REDUCERS:
			return initialState; // it clears the store on logout

		default:
			return state;
	}
};

const persistConfig = {
	key: 'user',
	storage,
	whitelist: ['data'],
};

export default persistReducer(persistConfig, userReducer);
