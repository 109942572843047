import * as React from 'react';

type Props = {
	disabled?: boolean;
	style?: React.CSSProperties;
	colors?: {
		disabled: {
			background: string;
			fill: string;
		};
		enabled: {
			background: string;
			fill: string;
		};
	};
};

const defaultColors = {
	disabled: {
		background: '#E3E4E9',
		fill: '#A1A5B7',
	},
	enabled: {
		background: '#B2E2FD',
		fill: '#0095E8',
	},
};

const Previous = ({ disabled, style = {}, colors = defaultColors }: Props) => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={style}
	>
		<rect
			x={20}
			y={20}
			width={20}
			height={20}
			rx={4}
			transform="rotate(-180 20 20)"
			fill={disabled ? colors.disabled.background : colors.enabled.background}
		/>
		<path
			d="M12.6787 3.50011C12.9072 3.50011 13.1357 3.5873 13.3098 3.76169C13.6586 4.11046 13.6586 4.67548 13.3098 5.02425L8.5827 9.75012L13.3098 14.4759C13.6586 14.8246 13.6586 15.3896 13.3098 15.7384C12.9611 16.0872 12.396 16.0872 12.0473 15.7384L6.69012 10.3813C6.34135 10.0325 6.34135 9.46748 6.69012 9.11871L12.0473 3.76155C12.2211 3.58661 12.4499 3.50011 12.6787 3.50011Z"
			fill={disabled ? colors.disabled.fill : colors.enabled.fill}
		/>
	</svg>
);

export default Previous;
