import * as React from 'react';
interface IProps {
	className?: string;
	style?: React.CSSProperties;
	color?: string;
}
const YellowStudent = (props: IProps) => (
	<svg
		width={48}
		height={39}
		viewBox="0 0 48 39"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M43.2 5.3H16.77V9.02C15.24 8.15 13.68 7.73 11.91 7.67C11.97 6.26 11.91 4.85 12.09 3.5C12.39 1.67 13.95 0.5 15.84 0.5C23.7 0.5 31.53 0.5 39.39 0.5C40.98 0.5 42.6 0.5 44.19 0.5C45.75 0.53 46.98 1.22 47.61 2.69C47.85 3.2 47.97 3.83 47.97 4.4C47.97 12.23 47.97 20.03 47.97 27.86C47.97 29.69 46.95 31.07 45.21 31.58C44.88 31.67 44.49 31.73 44.16 31.73C38.01 31.73 31.86 31.73 25.71 31.73C25.56 31.73 25.32 31.64 25.26 31.52C24.42 29.72 23.22 28.22 21.57 26.96H43.2V5.3ZM21.3 38.93C21.57 38.93 21.84 38.93 22.11 38.87C23.07 38.66 23.76 38.12 23.88 37.16C23.97 36.35 23.94 35.48 23.79 34.67C23.01 30.47 19.65 27.38 15.42 27.05C12.93 26.84 10.44 26.78 7.95 27.14C5.85 27.44 4.05 28.4 2.61 29.96C1.14 31.52 0.330005 33.35 0.0900048 35.45C0.0300049 35.99 4.85778e-06 36.59 0.0900048 37.13C0.270005 38.24 1.26 38.96 2.55 38.96C5.7 38.96 8.82 38.96 11.97 38.96C15.09 38.96 18.21 38.96 21.33 38.96L21.3 38.93ZM4.8 17.27C4.8 21.32 7.8 24.44 11.91 24.5C15.9 24.56 19.08 21.53 19.17 17.39C19.26 13.43 16.08 10.19 12.03 10.13C8.04 10.16 4.83 13.13 4.8 17.27ZM23.7 17.18C24.9 18.38 26.1 19.58 27.3 20.78C28.32 21.77 29.28 21.77 30.3 20.78C31.92 19.16 33.54 17.54 35.16 15.92C35.25 15.83 35.37 15.74 35.49 15.62C36.09 16.22 36.63 16.79 37.2 17.36C37.62 17.78 38.13 17.99 38.73 17.75C39.33 17.51 39.57 17 39.57 16.37C39.57 14.27 39.57 12.14 39.57 10.04C39.57 9.35 39.24 8.99 38.61 8.99C36.45 8.99 34.32 8.99 32.16 8.99C31.32 8.99 30.78 9.5 30.75 10.22C30.72 10.7 30.93 11.09 31.26 11.42C31.8 11.96 32.37 12.53 32.91 13.07L28.68 17.27C28.05 16.61 27.42 15.95 26.79 15.29C26.1 14.57 25.38 13.88 24.66 13.19C23.79 12.35 22.68 12.41 21.87 13.28C21.66 13.52 21.45 13.76 21.21 13.97C21.03 14.15 21.03 14.3 21.09 14.54C21.27 15.41 21.45 16.28 21.57 17.15C21.63 17.66 21.57 18.17 21.57 18.68L23.31 16.85C23.49 17.03 23.58 17.12 23.67 17.21L23.7 17.18Z"
			fill={props.color ? props.color : '#FFC700'}
		/>
	</svg>
);

export default YellowStudent;
