import * as React from 'react';

const Pencil = () => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1196_1689)">
			<path
				d="M9.87148 0.630982C10.5442 -0.0417075 11.6368 -0.0417075 12.3095 0.630982L13.3697 1.69203C14.0425 2.36451 14.0425 3.45596 13.3697 4.12871L12.0673 5.43114L8.56905 1.93341L9.87148 0.630982ZM11.4592 6.0393L5.1838 12.312C4.90394 12.5918 4.55681 12.799 4.17738 12.9093L0.939334 13.862C0.712754 13.9292 0.467607 13.8673 0.300497 13.6763C0.133383 13.5337 0.0709036 13.2888 0.137586 13.06L1.09003 9.8228C1.20197 9.44338 1.40729 9.09624 1.68742 8.81638L7.96089 2.54211L11.4592 6.0393Z"
				fill="#A5A9BA"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1196_1689">
				<rect
					width={13.7778}
					height={13.7778}
					fill="white"
					transform="translate(0.111328 0.111084)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default Pencil;
