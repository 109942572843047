import { ErrorType as ErrorType } from '@Configs/schemas';
import { Subject } from '@Configs/schemas/Subject/Subject';
import { ClearReducersAction } from '../types';

// user login
export const GET_SUBJECTS: 'GET_SUBJECTS' = 'GET_SUBJECTS';
export const GET_SUBJECTS_SUCCESS: 'GET_SUBJECTS_SUCCESS' =
	'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_ERROR: 'GET_SUBJECTS_ERROR' = 'GET_SUBJECTS_ERROR';
export const SELECT_SUBJECT = 'SELECT_SUBJECT' as const;

// ACTION CREATORS TYPES
export type GetSubjectsAction = {
	type: typeof GET_SUBJECTS;
};

export type GetSubjectsSuccessAction = {
	type: typeof GET_SUBJECTS_SUCCESS;
	payload: {
		data: Subject[];
		globalPermissions: string[];
	};
};

export type GetSubjectsErrorAction = {
	type: typeof GET_SUBJECTS_ERROR;
	payload: {
		error: ErrorType;
	};
};

export type SelectSubjectAction = {
	type: typeof SELECT_SUBJECT;
	payload: {
		subjectId: string;
	};
};

export type SubjectsActions =
	| ClearReducersAction
	| GetSubjectsAction
	| GetSubjectsSuccessAction
	| GetSubjectsErrorAction
	| SelectSubjectAction;

// STATE TYPES
export type SubjectStateType = {
	data: Subject[] | null;
	globalPermissions: string[] | null;
	selectedSubjectId: string | null;
	loading: boolean;
	error: ErrorType | null;
};

const reduxTypes = {
	GET_SUBJECTS,
	GET_SUBJECTS_SUCCESS,
	GET_SUBJECTS_ERROR,
	SELECT_SUBJECT,
};

export default reduxTypes;
