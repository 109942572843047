import * as React from 'react';
const BigSmile = () => (
	<svg
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.16875 19.6187C7.90625 18.775 8.6125 18 9.44375 18H22.7812C23.6688 18 24.3687 18.775 24.1125 19.6187C23.0062 23.025 19.8875 25.5 16.1375 25.5C12.3875 25.5 9.21875 23.025 8.16875 19.6187ZM13.025 12C13.025 13.1062 12.125 14 11.025 14C9.91875 14 9.025 13.1062 9.025 12C9.025 10.8938 9.91875 10 11.025 10C12.125 10 13.025 10.8938 13.025 12ZM19.025 12C19.025 10.8938 19.9187 10 21.025 10C22.125 10 23.025 10.8938 23.025 12C23.025 13.1062 22.125 14 21.025 14C19.9187 14 19.025 13.1062 19.025 12ZM32 16C32 24.8375 24.8375 32 16 32C7.1625 32 0 24.8375 0 16C0 7.1625 7.1625 0 16 0C24.8375 0 32 7.1625 32 16ZM16 3C8.81875 3 3 8.81875 3 16C3 23.1812 8.81875 29 16 29C23.1812 29 29 23.1812 29 16C29 8.81875 23.1812 3 16 3Z"
			fill="#7E8299"
		/>
	</svg>
);
export default BigSmile;
