import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReduxProviderPropsType } from './types';
import store, { persistor } from '../store/store';

const ReduxProvider: React.FunctionComponent<ReduxProviderPropsType> = (
	props
) => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{props.children}
			</PersistGate>
		</Provider>
	);
};

export default ReduxProvider;
