import {
	CaseType,
	GetSurveyResultsQuery,
	PostSurveyMutationVariables,
	PutCaseMutationVariables,
} from '@Api/generated';
import { caseStatus } from '@Api/questionnaires';
import Case, { StepOptionType } from '@Configs/schemas/Case/Case';
import {
	SurveyQuestionResult,
	SurveyWithResults,
} from '@Configs/schemas/Case/Survey';
import User from '@Configs/schemas/User/User';
import { getClientFromCurrentUrl } from '@Utils/functions';

import { SurveyFields } from '@Utils/helpers/Survey';

const SURVEY_TYPE = 2;

function surveyFieldsToGraphQL(data: SurveyFields) {
	const survey: PostSurveyMutationVariables['data'] = {
		caseStatus: caseStatus.Active,
		caseType: SURVEY_TYPE,
		client: getClientFromCurrentUrl(),
		date_from: (data.from as Date).toISOString(),
		date_to: (data.to as Date).toISOString(),
		name: data.name,
		subject: +data.subjectId,
		description: data.description,
		order: 0, //Todo: ask backend if this is needed
		steps: data.questions.map((question, index) => ({
			title: question.name,
			order: index,
			stepType: Case.getStepTypeIdByName(question.answer_type),
			open_field: question.openFieldEnabled ?? false,
			step_options: question.options.map((option) => ({
				title: option.text,
			})),
		})),
	};
	return survey;
}

function surveyFieldsToGraphQLUpdate(data: SurveyFields) {
	const _survey = surveyFieldsToGraphQL(data);
	const survey: PutCaseMutationVariables['data'] = {
		..._survey,
		id: +data.id!,
		questionnaireSettings: null,
		steps:
			_survey.steps?.map((step, stepIndex) => ({
				...step,
				id: +data.questions[stepIndex].id!,
				step_options: step.step_options?.map((option, optionIndex) => ({
					...option,
					id: +data.questions[stepIndex].options[optionIndex].id!,
				})),
				justification: null,
			})) ?? [],
	};

	return survey;
}

function adaptSurveyWithResults(_survey: GetSurveyResultsQuery['getCaseByID']) {
	const survey: SurveyWithResults = {
		dateFrom: new Date(+_survey.date_from).toString(),
		dateTo: new Date(+_survey.date_to).toString(),
		description: _survey.description ?? '',
		id: _survey.id,
		name: _survey.name,
		subjectId: _survey.subject?.id ?? '',
		questions:
			_survey.steps?.map((step) => ({
				id: step.id,
				answerType: Case.getStepTypeNameById(step.step_type.id),
				title: step.title,
				created_at: step.created_at,
				openField: step.open_field ?? false,
				options:
					step.step_options?.map((stepOption) => ({
						id: stepOption.id,
						text: stepOption.title,
						created_at: stepOption.created_at,
					})) ?? [],
				results:
					step.step_results?.map(
						(stepResult) =>
							({
								id: stepResult.id,
								questionId: step.id,
								optionIdList: stepResult.step_option_results.map(
									(o) => o.step_option!.id
								),
								textAnswerResult: stepResult.text_answer_results[0]?.value,
								student: {
									id: stepResult.student.id,
									email: stepResult.student.user.email,
									name: User.getFullName(stepResult.student.user),
								},
							} as SurveyQuestionResult)
					) ?? [],
			})) ?? [],
	};
	return survey;
}

export default {
	surveyFieldsToGraphQL,
	surveyFieldsToGraphQLUpdate,
	adaptSurveyWithResults,
};
