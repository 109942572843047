import { Header, Text } from '@Components/Text';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const DivIconCustonContainer = styled.div`
	width: 63px;
	height: 63px;
	margin-bottom: 16px;
	margin-top: 17px;
`;
export const TitleModal = styled.h3`
	font-weight: 700;
	font-size: 1.33rem;
	margin-bottom: 12px;
`;

export const SubTitleModal = styled.span`
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 28px;
`;
