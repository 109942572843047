/* eslint-disable @typescript-eslint/no-explicit-any */
import graphQLClient, { GraphQLCatch, LogReturn } from '@Api/graphQLClient';
import { IReportsApi } from '@Api/index';
import { AcademicObjectReport } from '../../app/configs/schemas/Reports/AcademicObjectsReport';
import { EvaluadosAppReport } from '../../app/configs/schemas/Reports/EvaluadosAppReport';
import { delay, roundObjectNumericValues } from '../../utils/functions';

@LogReturn()
@GraphQLCatch()
class ReportsApiGraphQL implements IReportsApi {
	async getEvaluadosAppDashboardReportHeaders(): Promise<
		Record<string, string[]>
	> {
		const { evaluadosAppReportHeaders } =
			await graphQLClient.GetEvaluadosAppDashboardReportHeaders();
		return evaluadosAppReportHeaders;
	}
	async getEvaluadosAppDashboardReport(args?: {
		year?: string;
		searchArgs?: string[];
		limit?: number;
		offset?: number;
		searchQuery?: string;
	}): Promise<EvaluadosAppReport> {
		const { reportToDashboardEvaluadosApp: data } =
			await graphQLClient.GetEvaluadosAppDashboardReport({
				year: args?.year ?? '2023',
				searchArgs: [],
				limit: args?.limit ?? 25,
				offset: args?.offset,
				searchQuery: args?.searchQuery,
			});
		return data;
	}
	async getAcademicObjectsDashboardReport(): Promise<AcademicObjectReport[]> {
		const { reportToDashboard } =
			await graphQLClient.GetAcademicObjectsDashboardReport();

		return reportToDashboard.map(roundObjectNumericValues);
	}
}

export default new ReportsApiGraphQL();
