import * as React from 'react';

const Check = () => (
	<svg
		width={32}
		height={24}
		viewBox="0 0 32 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M31.3286 0.68542C32.2214 1.59688 32.2214 3.0698 31.3286 3.98125L13.0429 22.6479C12.15 23.5594 10.7071 23.5594 9.81429 22.6479L0.669429 13.3146C-0.223143 12.4031 -0.223143 10.9302 0.669429 10.0188C1.56214 9.1073 3.00929 9.1073 3.90214 10.0188L11.3643 17.6969L28.1 0.68542C28.9929 -0.227497 30.4357 -0.227497 31.3286 0.68542Z"
			fill="#50CD89"
		/>
	</svg>
);

export default Check;
