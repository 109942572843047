import * as React from 'react';

type Props = {
	color?: string;
};

const Bell = ({ color = '#A5A9BA' }: Props) => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 16 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.14286 1.14286V1.82857C11.75 2.35821 13.7143 4.66429 13.7143 7.42857V8.1C13.7143 9.78214 14.3322 11.4 15.4464 12.6571L15.7107 12.9536C16.0107 13.2929 16.0857 13.7714 15.9 14.1821C15.7143 14.5929 15.3072 14.8571 14.8572 14.8571H1.14285C0.69285 14.8571 0.28467 14.5929 0.100313 14.1821C-0.0840444 13.7714 -0.010305 13.2929 0.288706 12.9536L0.553564 12.6571C1.66928 11.4 2.28571 9.78214 2.28571 8.1V7.42857C2.28571 4.66429 4.21785 2.35821 6.85714 1.82857V1.14286C6.85714 0.511786 7.36786 0 8 0C8.63215 0 9.14286 0.511786 9.14286 1.14286ZM8 18.2857C7.39286 18.2857 6.81072 18.0464 6.38214 17.6179C5.95357 17.1893 5.71429 16.575 5.71429 16H10.2857C10.2857 16.575 10.0464 17.1893 9.61786 17.6179C9.18929 18.0464 8.575 18.2857 8 18.2857Z"
			fill={color}
		/>
	</svg>
);

export default Bell;
