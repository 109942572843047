import * as React from 'react';

type Props = {
	color?: string;
	hover?: boolean;
	key?: string | number;
};

const EyeOpenSmall = ({ color, key }: Props) => (
	<svg
		key={key}
		width={12}
		height={11}
		viewBox="0 0 12 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.825 3.50834C5.88333 3.50209 5.94167 3.50001 6 3.50001C7.10417 3.50001 8 4.37709 8 5.50001C8 6.60418 7.10417 7.50001 6 7.50001C4.87708 7.50001 4 6.60418 4 5.50001C4 5.44168 4.00208 5.38334 4.00833 5.32501C4.20208 5.41876 4.42708 5.50001 4.66667 5.50001C5.40208 5.50001 6 4.90209 6 4.16668C6 3.92709 5.91875 3.70209 5.825 3.50834ZM10.0125 2.51251C10.9875 3.41668 11.6396 4.48126 11.9479 5.24376C12.0167 5.40834 12.0167 5.59168 11.9479 5.75626C11.6396 6.50001 10.9875 7.56459 10.0125 8.48751C9.03125 9.40001 7.68333 10.1667 6 10.1667C4.31667 10.1667 2.96875 9.40001 1.98792 8.48751C1.01292 7.56459 0.361253 6.50001 0.0512734 5.75626C-0.0170911 5.59168 -0.0170911 5.40834 0.0512734 5.24376C0.361253 4.48126 1.01292 3.41668 1.98792 2.51251C2.96875 1.60084 4.31667 0.833344 6 0.833344C7.68333 0.833344 9.03125 1.60084 10.0125 2.51251ZM6 2.50001C4.34375 2.50001 3 3.84376 3 5.50001C3 7.15626 4.34375 8.50001 6 8.50001C7.65625 8.50001 9 7.15626 9 5.50001C9 3.84376 7.65625 2.50001 6 2.50001Z"
			key={key}
			fill={color ? color : '#AFB0B9'}
		/>
	</svg>
);

export default EyeOpenSmall;
