import { lazy } from 'react';
import PrivateRoute from './PrivateRoute';
const AdminPage = lazy(() => import('@Pages/Admin'));

export const adminRoutes = [
	{
		path: '/admin',
		element: <PrivateRoute path="/admin" key="/admin" component={AdminPage} />,
	},
];
