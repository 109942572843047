import * as React from 'react';
type Props = {
	color?: string;
};

const CircleSolid1 = ({ color }: Props) => (
	<svg
		width={16}
		height={16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.06009 0C12.5101 0.039975 16.1301 3.6877 15.9901 8.24485C15.8601 12.6021 12.3101 16.02 7.97009 16C3.43009 15.98 -0.0199135 12.3223 8.65124e-05 7.98501C0.0200865 3.43785 3.67009 0 8.06009 0ZM8.76009 10.5034V10.2636C8.76009 8.45472 8.76009 6.65584 8.76009 4.84697C8.76009 4.52717 8.64009 4.26733 8.36009 4.10743C8.09009 3.95753 7.81009 3.98751 7.55009 4.14741C7.02009 4.49719 6.49009 4.84697 5.97009 5.20675C5.74009 5.36665 5.59009 5.58651 5.62009 5.88632C5.67009 6.37601 6.23009 6.83573 6.83009 6.43598C6.95009 6.35603 7.08009 6.27608 7.21009 6.18613V10.4934H7.18009C6.79009 10.4934 6.40009 10.4934 6.01009 10.4934C5.69009 10.4934 5.43009 10.6134 5.30009 10.9232C5.07009 11.4628 5.42009 11.9925 6.01009 11.9925C6.65009 11.9925 7.30009 11.9925 7.95009 11.9925C8.62009 11.9925 9.28009 11.9925 9.95009 11.9925C10.4701 11.9925 10.8201 11.5628 10.7101 11.0831C10.6201 10.7033 10.3501 10.4834 9.94009 10.4834C9.55009 10.4834 9.15009 10.4834 8.74009 10.4834L8.76009 10.5034Z"
			fill={color}
		/>
	</svg>
);

export default CircleSolid1;
