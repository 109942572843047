import * as React from 'react';

const Cuestionario = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 34 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0 8C0 3.58172 3.58172 0 8 0H26C30.4183 0 34 3.58172 34 8V28C34 32.4183 30.4183 36 26 36H8C3.58172 36 0 32.4183 0 28V8Z"
			fill="#F1FAFF"
		/>
		<path
			d="M8 12C8 10.8953 8.89531 10 10 10H15V14C15 14.5531 15.4469 15 16 15H20V16.2063C17.6906 16.8594 16 18.9812 16 21.5C16 23.3469 16.9094 24.9781 18.3031 25.9781C18.2063 25.9906 18.1031 26 18 26H10C8.89531 26 8 25.1031 8 24V12ZM16 14V10L20 14H16ZM17 21.5C17 19.0156 19.0156 17 21.5 17C23.9844 17 26 19.0156 26 21.5C26 23.9844 23.9844 26 21.5 26C19.0156 26 17 23.9844 17 21.5ZM21.5 24.5C21.9156 24.5 22.25 24.1656 22.25 23.75C22.25 23.3344 21.9156 23 21.5 23C21.0844 23 20.75 23.3344 20.75 23.75C20.75 24.1656 21.0844 24.5 21.5 24.5ZM19.5 20.25C19.5 20.525 19.725 20.75 20 20.75C20.275 20.75 20.5 20.525 20.5 20.25V20.05C20.5 19.8844 20.6344 19.75 20.8 19.75H22.0656C22.3063 19.75 22.5 19.9437 22.5 20.1844C22.5 20.3469 22.4094 20.4937 22.2688 20.5719L21.2688 21.0969C21.1031 21.1844 21 21.3531 21 21.5375V22C21 22.275 21.225 22.5 21.5 22.5C21.775 22.5 22 22.275 22 22V21.8406L22.7344 21.4563C23.2063 21.2063 23.5 20.6906 23.5 20.1844C23.5 19.3938 22.8562 18.75 22.0656 18.75H20.8C20.0813 18.75 19.5 19.3313 19.5 20.05V20.25Z"
			fill="#009EF7"
		/>
	</svg>
);

export default Cuestionario;
