import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import fondoPdf from '@Assets/images/Producto_Academico_Diseno.001.png';
import jsPDF from 'jspdf';
import { REST_API_URL } from '../api/restClient';

export interface ConsoleProperties {
	log: () => void;
	error: () => void;
	warn: () => void;
	// Agrega aquí otras propiedades de console que quieras utilizar
}

export function stripHTMLExceptTableTags(html: string) {
	const tagsToKeep = [
		'table',
		'tr',
		'td',
		'th',
		'tbody',
		'thead',
		'tfoot',
		'br',
		'ol',
		'ul',
		'li',
	];
	return html.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, (all, tag) => {
		return tagsToKeep.indexOf(tag.toLowerCase()) > -1 ? all : '';
	});
}

const colors = ['#009EF7', '#E800A7', '#5222D0', '#FF8A00'];

export const numberToDate = (date: string) => {
	console.log(date);
	let fromParse: string | number = date;
	if (!date.toString().includes('-')) {
		fromParse = parseInt(date);
	}
	const newDate = new Date(fromParse);
	console.log(newDate);

	const newDateTime =
		newDate.getDate() < 9 ? '0' + newDate.getDate() : newDate.getDate();
	const newMonthTime =
		newDate.getMonth() < 9
			? '0' + (newDate.getMonth() + 1)
			: newDate.getMonth() + 1;

	const newDateFrom =
		newDateTime + '/' + newMonthTime + '/' + newDate.getFullYear();
	console.log(newDateFrom);
	return newDateFrom;
};

export function converseMonth(month: number) {
	switch (month) {
		case 1:
			return 'Enero';
		case 2:
			return 'Febrero';
		case 3:
			return 'Marzo';
		case 4:
			return 'Abril';
		case 5:
			return 'Mayo';
		case 6:
			return 'Junio';
		case 7:
			return 'Julio';
		case 8:
			return 'Agosto';
		case 9:
			return 'Septiembre';
		case 10:
			return 'Octubre';
		case 11:
			return 'Noviembre';
		case 12:
			return 'Diciembre';
		default:
			return 'Error';
	}
}

export function mapSecondsToTime(seconds: number) {
	let minutes, secondsLeft;
	if (seconds < 3600) {
		minutes = Math.floor(seconds / 60);
		secondsLeft = seconds % 60;
	}
	if (minutes === 0) {
		return `${seconds} seg`;
	}
	return `${minutes}${secondsLeft !== 0 ? `:${secondsLeft}` : ''} min`;
}

export function getShrinkedString(str: string, len: number) {
	return str.length > len ? (str = str.substring(0, len) + '...') : str;
}

export function getColorFromArr(index: number) {
	return colors[index % colors.length];
}

export function blobToBase64(blob: Blob) {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
}

export async function obtenerBlob(url: string) {
	const dowloadedImage = new Image();
	dowloadedImage.crossOrigin = 'Anonymous';
	dowloadedImage.src = url;

	const blob = await fetch(dowloadedImage.src).then((r) => r.blob());
	return blob;
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function sortByCreatedAt<T extends { createdAt: number }>(
	a: T,
	b: T,
	asc = true
): number {
	return asc ? a.createdAt - b.createdAt : b.createdAt - a.createdAt;
}
export const getRoundedNumber = (x: number) => {
	return Math.round(x);
};

export function imgUrlGetter(bucketName: string, imgName: string): string {
	const url = `https://storage.googleapis.com/${bucketName}/${imgName}`;
	return url;
}

export function handleConsoleLog(
	propertiesToDisabled: (keyof ConsoleProperties)[]
) {
	if (process.env.NODE_ENV !== 'development') {
		propertiesToDisabled.forEach(
			(property) =>
				(console[property] = function () {
					return;
				})
		);
	}
}

const clientNameToId = {
	evaluados: 1,
	continental: 2,
	siglo21: 3,
	UGM: 4,
	UPAEP: 5,
	Anahuac: 6,
	ute: 7,
	uistmo: 8,
	infossep: 9,
	hemisferios: 10,
	upn: 11,
	ieu: 12,
	udla: 13,
	ulatina: 14,
	'udla-cl': 15,
	unimet: 16,
	unisabana: 17,
	areandina: 18,
	unicooperativa: 19,
	ubp: 20,
	uautonoma: 21,
	univalle: 22,
};

const clientNames = Object.keys(clientNameToId);

export function getClientFromCurrentUrl() {
	const client = window.location.hostname.split('.')[0];
	const clientName = clientNames.includes(client) ? client : 'evaluados';
	return clientNameToId[clientName as keyof typeof clientNameToId];
}

export function getClientNameFromCurrentUrl() {
	const client = window.location.hostname.split('.')[0];
	const clientName = clientNames.includes(client) ? client : 'evaluados';
	return clientName;
}

export const getDecimalsNumber = (x: number) => {
	return x.toFixed(1);
};

export const exportAnswersToDocs = async (content: string) => {
	const contentWithoutHTML = stripHTMLExceptTableTags(content);
	const answers = contentWithoutHTML.split('\n').map((e) => e);

	const doc = new Document({
		sections: [
			{
				properties: {},
				children: answers.flatMap((answer) => {
					return new Paragraph({
						children: [new TextRun(answer)],
					});
				}),
			},
		],
	});

	Packer.toBlob(doc).then((blob) => {
		saveAs(blob, `Mi Objeto Academico ${new Date().toISOString()}.docx`);
	});
};

export function exportAnswersPDF(content: string, callback: () => void) {
	//const answers = content.split('\n').map((e) => e);
	const answerText = content.replace(/\n\s*\n/g, '<br/>');

	const maxWidth = 180;
	const marginTop = 40;
	const marginBottom = 20;
	const marginLeft = 15;

	const element = document.createElement('div');
	element.innerHTML = answerText;
	element.style.fontSize = '14px';
	element.style.fontFamily = 'Nunito';
	element.style.position = 'absolute';
	element.style.left = '-9999px';
	element.style.marginTop = '5px';
	element.style.width = '180mm';
	element.style.overflowWrap = 'break-word';
	const tables = element.getElementsByTagName('table');
	for (let i = 0; i < tables.length; i++) {
		const table = tables[i];
		table.style.borderCollapse = 'collapse';

		const ths = table.getElementsByTagName('th');
		for (let j = 0; j < ths.length; j++) {
			const th = ths[j];
			th.style.border = '1px solid #828282';
			th.style.background = '#0878d0';
			th.style.color = '#ffffff';
			th.style.padding = '8px';
			th.style.fontWeight = '700';
		}

		const tds = table.getElementsByTagName('td');
		for (let j = 0; j < tds.length; j++) {
			const td = tds[j];
			td.style.border = '1px solid #828282';
			td.style.padding = '8px';
		}
	}
	document.body.appendChild(element);

	const loadImage = (src: string): Promise<HTMLImageElement> => {
		return new Promise((resolve) => {
			const img = new Image();
			img.src = src;
			img.onload = () => resolve(img);
		});
	};

	Promise.all([
		html2canvas(element, {
			backgroundColor: null,
			y: 0,
			x: 0,
			width: element.clientWidth,
			height: element.clientHeight,
			scrollY: 0,
			scrollX: 0,
			windowWidth: element.clientWidth,
			windowHeight: element.clientHeight,
			scale: 0.95,
		}),
		loadImage(fondoPdf),
	]).then(([canvas, backgroundImage]) => {
		const imgWidth = maxWidth;
		const pageHeight = (imgWidth * canvas.height) / canvas.width;

		const doc = new jsPDF({
			orientation: 'p',
			unit: 'mm',
			format: 'a4',
			compress: true,
		});

		const pdfPageHeight =
			doc.internal.pageSize.height - marginTop - marginBottom;

		let currentPageHeight = 0;

		while (currentPageHeight < pageHeight) {
			// Dibujar la imagen de fondo en la página actual
			doc.addImage(
				backgroundImage,
				'PNG',
				0,
				0,
				doc.internal.pageSize.width,
				doc.internal.pageSize.height
			);

			const sliceHeight = Math.min(
				pdfPageHeight,
				pageHeight - currentPageHeight
			);
			const sWidth = canvas.width;
			const sHeight = (sliceHeight * canvas.width) / imgWidth;
			const sx = 0;
			const sy = (currentPageHeight * canvas.width) / imgWidth;

			const tempCanvas = document.createElement('canvas');
			tempCanvas.width = sWidth;
			tempCanvas.height = sHeight;
			tempCanvas
				.getContext('2d')!
				.drawImage(canvas, sx, sy, sWidth, sHeight, 0, 0, sWidth, sHeight);

			const slicedImgData = tempCanvas.toDataURL('image/png');

			// Agregar la porción de la imagen del contenido sobre la imagen de fondo
			doc.addImage(
				slicedImgData,
				'PNG',
				marginLeft,
				marginTop,
				imgWidth,
				sliceHeight,
				'',
				'FAST'
			);

			currentPageHeight += pdfPageHeight;

			if (currentPageHeight < pageHeight) {
				doc.addPage();
			}
		}

		doc.save('TusRespuestas.pdf');
		document.body.removeChild(element);
	});
	callback();
}

export function getSurveyReportURL(caseId: string) {
	return REST_API_URL + '/reports/case/' + caseId;
}

export function getSubjectReportURL(subjectId: string) {
	return REST_API_URL + '/reports/subject/' + subjectId;
}

const isMermaidCode = (str?: string | React.ReactNode): str is string => {
	if (!str || typeof str !== 'string') return false;
	const mermaidRegex = /```mermaid[^`]*```/gs;
	const result = str.match(mermaidRegex);
	return !!result;
};

export function getMermaidCode(str: unknown) {
	if (!isMermaidCode(str)) return null;
	const mermaidRegex = /```mermaid[^`]*```/gs;
	const result = str.match(mermaidRegex);
	if (!result) return;
	const mermaidCode = result[0].replace(/```mermaid|```/g, '');
	return mermaidCode.trim().replace(/(\[|\{)(.*?)(\]|\})/g, '$1"$2"$3');
}

export function removeMermaidCode(str: string) {
	// Verificar si la cadena es un código Mermaid
	if (!isMermaidCode(str)) return str; // Si no es un código Mermaid, devolver la cadena original

	// Definir la expresión regular para el código Mermaid
	const mermaidRegex = /```mermaid[^`]*```/gs;

	// Reemplazar cualquier código Mermaid en la cadena con un espacio vacío
	const result = str.replace(mermaidRegex, '');

	return result;
}

export const createAndDownloadTxTFile = (text: string, fileName: string) => {
	const file = new Blob([text], { type: 'text/plain' });
	saveAs(file, fileName);
};
export function isEqual(a: {}, b: {}) {
	return (
		JSON.stringify(a).split('').sort().join('') ===
		JSON.stringify(b).split('').sort().join('')
	);
}

export function roundObjectNumericValues<T extends {}>(obj: T) {
	const keys = Object.keys(obj) as Array<keyof T>;
	return keys.reduce((acc, key) => {
		if (typeof obj[key] === 'number') {
			acc[key] = getRoundedNumber(obj[key] as number) as T[keyof T];
		} else {
			acc[key] = obj[key];
		}
		return acc;
	}, {} as T);
}
