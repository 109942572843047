import * as React from 'react';

const Varita = () => (
	<svg
		width={16}
		height={15}
		viewBox="0 0 16 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.15799 1.05224C7.18738 0.972003 7.26483 0.918854 7.3503 0.918854C7.43577 0.918854 7.51323 0.972003 7.54261 1.05224L7.91921 2.05854L8.92616 2.43568C9.00628 2.46586 9.0597 2.54252 9.0597 2.62825C9.0597 2.71399 9.00628 2.79065 8.92616 2.82083L7.91921 3.19797L7.54261 4.20411C7.51323 4.28424 7.43577 4.33766 7.3503 4.33766C7.26483 4.33766 7.18738 4.28424 7.15799 4.20411L6.78139 3.19797L5.75041 2.82083C5.67028 2.79065 5.6409 2.71399 5.6409 2.62825C5.6409 2.54252 5.67028 2.46586 5.75041 2.43568L6.78139 2.05854L7.15799 1.05224ZM11.9283 1.29439C12.4304 0.793691 13.2424 0.793691 13.7419 1.29439L14.666 2.218C15.1655 2.71853 15.1655 3.5305 14.666 4.0305L4.47904 14.2175C3.97958 14.7169 3.16708 14.7169 2.66654 14.2175L1.74293 13.2933C1.24213 12.7939 1.24213 11.9819 1.74293 11.4798L11.9283 1.29439ZM12.8364 2.50299L10.0293 5.30721L10.6516 5.92954L13.4561 3.12451L12.8364 2.50299ZM0.712775 4.04919L2.2221 3.48295L2.78807 1.97361C2.83321 1.85315 2.94833 1.77355 3.0768 1.77355C3.205 1.77355 3.31985 1.85315 3.36526 1.97361L3.9315 3.48295L5.44058 4.04919C5.56077 4.0946 5.6409 4.20945 5.6409 4.33766C5.6409 4.46586 5.56077 4.58071 5.44058 4.62612L3.9315 5.19236L3.36526 6.70144C3.31985 6.82163 3.205 6.90176 3.0768 6.90176C2.94833 6.90176 2.83321 6.82163 2.78807 6.70144L2.2221 5.19236L0.712775 4.62612C0.592423 4.58071 0.512695 4.46586 0.512695 4.33766C0.512695 4.20945 0.592423 4.0946 0.712775 4.04919ZM10.1147 10.8868L11.6238 10.3206L12.19 8.81148C12.2355 8.66725 12.3503 8.61116 12.4785 8.61116C12.6067 8.61116 12.7216 8.66725 12.767 8.81148L13.3332 10.3206L14.8423 10.8868C14.9625 10.9322 15.0426 11.0471 15.0426 11.1753C15.0426 11.3035 14.9625 11.4183 14.8423 11.4637L13.3332 12.03L12.767 13.5391C12.7216 13.6592 12.6067 13.7394 12.4785 13.7394C12.3503 13.7394 12.2355 13.6592 12.19 13.5391L11.6238 12.03L10.1147 11.4637C9.97049 11.4183 9.9144 11.3035 9.9144 11.1753C9.9144 11.0471 9.97049 10.9322 10.1147 10.8868Z"
			fill="#7E8299"
		/>
	</svg>
);

export default Varita;
