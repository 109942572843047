import ScreenMovileIlustration from '@Assets/images/illustrations/ScreenMovileIlustration';
import EvaluadosLogo from '@Assets/images/logos/EvaluadosLogo';
import { useIntl } from 'react-intl';
import { ScreenForMobilesProps } from './types';
import { Modal } from 'react-bootstrap';

const ScreenForMobiles = ({ show }: ScreenForMobilesProps) => {
	const { formatMessage } = useIntl();
	return (
		<Modal
			show={show}
			backdrop="static"
			dialogClassName="w-100"
			fullscreen
			centered
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						marginTop: '64px',
						marginBottom: '56px',
					}}
				>
					<EvaluadosLogo />
				</div>
				<h1
					style={{
						fontWeight: 'bold',
					}}
				>
					{formatMessage({ id: 'MOVILESCREEN_TITLE' })}
				</h1>
				<h5
					style={{
						fontWeight: 'bold',
						margin: '48px 32px 20px 32px',
						textAlign: 'center',
						maxWidth: '296px',
					}}
				>
					{formatMessage({ id: 'MOVILESCREEN_SUBTITLE' })}
				</h5>
				<ScreenMovileIlustration />
			</div>
		</Modal>
	);
};

export default ScreenForMobiles;
