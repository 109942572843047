import * as React from 'react';

const PaperQuestion = () => (
	<svg
		width={32}
		height={30}
		viewBox="0 0 32 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0 4.33339C0 2.3695 1.59167 0.777832 3.55556 0.777832H12.4444V7.88894C12.4444 8.87228 13.2389 9.66672 14.2222 9.66672H21.3333V11.8112C17.2278 12.9723 14.2222 16.7445 14.2222 21.2223C14.2222 24.5056 15.8389 27.4056 18.3167 29.1834C18.1444 29.2056 17.9611 29.2223 17.7778 29.2223H3.55556C1.59167 29.2223 0 27.6278 0 25.6667V4.33339ZM14.2222 7.88894V0.777832L21.3333 7.88894H14.2222ZM16 21.2223C16 16.8056 19.5833 13.2223 24 13.2223C28.4167 13.2223 32 16.8056 32 21.2223C32 25.6389 28.4167 29.2223 24 29.2223C19.5833 29.2223 16 25.6389 16 21.2223ZM24 26.5556C24.7389 26.5556 25.3333 25.9612 25.3333 25.2223C25.3333 24.4834 24.7389 23.8889 24 23.8889C23.2611 23.8889 22.6667 24.4834 22.6667 25.2223C22.6667 25.9612 23.2611 26.5556 24 26.5556ZM20.4444 19.0001C20.4444 19.4889 20.8444 19.8889 21.3333 19.8889C21.8222 19.8889 22.2222 19.4889 22.2222 19.0001V18.6445C22.2222 18.3501 22.4611 18.1112 22.7556 18.1112H25.0056C25.4333 18.1112 25.7778 18.4556 25.7778 18.8834C25.7778 19.1723 25.6167 19.4334 25.3667 19.5723L23.5889 20.5056C23.2944 20.6612 23.1111 20.9612 23.1111 21.2889V22.1112C23.1111 22.6001 23.5111 23.0001 24 23.0001C24.4889 23.0001 24.8889 22.6001 24.8889 22.1112V21.8278L26.1944 21.1445C27.0333 20.7001 27.5556 19.7834 27.5556 18.8834C27.5556 17.4778 26.4111 16.3334 25.0056 16.3334H22.7556C21.4778 16.3334 20.4444 17.3667 20.4444 18.6445V19.0001Z"
			fill="#009EF7"
		/>
	</svg>
);

export default PaperQuestion;
