import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { StoreType } from '@Redux/store/types';
import { PublicRoutePropsType } from './types';
import { selectToken } from '@Redux/store/user/selectors';

const PublicRoute: React.FC<PublicRoutePropsType> = ({
	component: Component,
	restricted = false,
	redirectTo = '/dashboard',
	...rest
}) => {
	const isAuthorized = useSelector(selectToken) ? true : false;

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthorized && restricted ? (
					<Redirect to={redirectTo} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default PublicRoute;
