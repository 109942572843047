import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { PrivateRoutePropsType } from './types';
import { selectToken } from '@Redux/store/user/selectors';

const PrivateRoute: React.FC<PrivateRoutePropsType> = ({
	component: Component,
	redirectTo = '/auth',
	...rest
}) => {
	const isAuthorized = useSelector(selectToken) ? true : false;

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthorized ? <Component {...props} /> : <Redirect to={redirectTo} />
			}
		/>
	);
};

export default PrivateRoute;
