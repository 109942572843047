import * as React from 'react';

const Search = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.6343 13.8656L11.8937 10.125C12.7443 8.86217 13.164 7.28436 12.9393 5.60311C12.5562 2.74343 10.2125 0.416239 7.34996 0.053833C3.09403 -0.484698 -0.484723 3.09405 0.0538389 7.34999C0.416339 10.2137 2.74384 12.5594 5.60384 12.9406C7.28509 13.1653 8.86321 12.7457 10.1257 11.895L13.8663 15.6356C14.3545 16.1237 15.146 16.1237 15.6341 15.6356C16.1218 15.1469 16.1218 14.3531 15.6343 13.8656ZM2.47184 6.49999C2.47184 4.29436 4.26621 2.49999 6.47184 2.49999C8.67746 2.49999 10.4718 4.29436 10.4718 6.49999C10.4718 8.70561 8.67746 10.5 6.47184 10.5C4.26621 10.5 2.47184 8.70624 2.47184 6.49999Z"
			fill="#A5A9BA"
		/>
	</svg>
);

export default Search;
