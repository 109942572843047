import * as React from 'react';
type Props = {
	color?: string;
};
const Organize = ({ color }: Props) => (
	<svg
		width={25}
		height={22}
		viewBox="0 0 25 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.58288 1.62695C1.58288 1.00539 2.12851 0.501953 2.70788 0.501953H4.20789C4.87351 0.501953 5.33289 1.00539 5.33289 1.62695V7.25008H6.08289C6.74851 7.25008 7.20789 7.75633 7.20789 8.37508C7.20789 8.99852 6.74851 9.50008 6.08289 9.50008H2.33288C1.75351 9.50008 1.20788 8.99852 1.20788 8.37508C1.20788 7.75633 1.75351 7.25008 2.33288 7.25008H3.08288V2.75195H2.70788C2.12851 2.75195 1.58288 2.24805 1.58288 1.62695ZM4.56413 14.9938C4.25476 14.647 3.70632 14.6704 3.43679 15.0501L2.91554 15.7766C2.5546 16.2829 1.85195 16.4001 1.34617 16.0391C0.840385 15.6782 0.723666 14.9751 1.0846 14.4735L1.60585 13.7423C2.71867 12.1813 4.99539 12.0688 6.25632 13.5126C7.25476 14.6141 7.23132 16.3673 6.20476 17.4829L4.56882 19.2501H6.12507C6.74851 19.2501 7.25007 19.7516 7.25007 20.3751C7.25007 20.9985 6.74851 21.5001 6.12507 21.5001H2.00007C1.55335 21.5001 1.14882 21.2376 0.96976 20.8251C0.790228 20.4173 0.870385 19.9391 1.1732 19.611L4.55007 15.9548C4.79851 15.6829 4.8032 15.2704 4.56413 14.9938ZM23.0001 2.00008C23.8298 2.00008 24.5001 2.6718 24.5001 3.50008C24.5001 4.32977 23.8298 5.00008 23.0001 5.00008H11.0001C10.1704 5.00008 9.50007 4.32977 9.50007 3.50008C9.50007 2.6718 10.1704 2.00008 11.0001 2.00008H23.0001ZM23.0001 9.50008C23.8298 9.50008 24.5001 10.1704 24.5001 11.0001C24.5001 11.8298 23.8298 12.5001 23.0001 12.5001H11.0001C10.1704 12.5001 9.50007 11.8298 9.50007 11.0001C9.50007 10.1704 10.1704 9.50008 11.0001 9.50008H23.0001ZM23.0001 17.0001C23.8298 17.0001 24.5001 17.6704 24.5001 18.5001C24.5001 19.3298 23.8298 20.0001 23.0001 20.0001H11.0001C10.1704 20.0001 9.50007 19.3298 9.50007 18.5001C9.50007 17.6704 10.1704 17.0001 11.0001 17.0001H23.0001Z"
			fill={color ? color : '#009EF7'}
		/>
	</svg>
);

export default Organize;
