import { CustomIconOptions } from '@Assets/images/icons';
import * as React from 'react';

const MasIconBlue = (props: CustomIconOptions) => (
	<svg
		width={25}
		height={24}
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M24.5 12C24.5 13.0206 23.6733 13.8467 22.6538 13.8467H14.3462V22.1544C14.3462 23.175 13.5194 24 12.5 24C11.4806 24 10.6538 23.175 10.6538 22.1544V13.8467H2.34615C1.32673 13.8467 0.5 13.0206 0.5 12C0.5 10.9794 1.32673 10.1544 2.34615 10.1544H10.6538V1.84673C10.6538 0.826154 11.4806 0 12.5 0C13.5194 0 14.3462 0.826154 14.3462 1.84673V10.1544H22.6538C23.675 10.1538 24.5 10.9788 24.5 12Z"
			fill={props.color || '#009EF7'}
		/>
	</svg>
);

export default MasIconBlue;
