import * as React from 'react';

const EyeOpen = () => (
	<svg
		width={18}
		height={14}
		viewBox="0 0 18 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.7375 4.0125C8.825 4.00312 8.9125 4 9 4C10.6563 4 12 5.31563 12 7C12 8.65625 10.6563 10 9 10C7.31563 10 6 8.65625 6 7C6 6.9125 6.00313 6.825 6.0125 6.7375C6.30313 6.87813 6.64063 7 7 7C8.10313 7 9 6.10313 9 5C9 4.64062 8.87813 4.30312 8.7375 4.0125ZM15.0188 2.51875C16.4813 3.875 17.4594 5.47188 17.9219 6.61562C18.025 6.8625 18.025 7.1375 17.9219 7.38437C17.4594 8.5 16.4813 10.0969 15.0188 11.4812C13.5469 12.85 11.525 14 9 14C6.475 14 4.45313 12.85 2.98188 11.4812C1.51938 10.0969 0.541879 8.5 0.0769101 7.38437C-0.0256367 7.1375 -0.0256367 6.8625 0.0769101 6.61562C0.541879 5.47188 1.51938 3.875 2.98188 2.51875C4.45313 1.15125 6.475 0 9 0C11.525 0 13.5469 1.15125 15.0188 2.51875ZM9 2.5C6.51563 2.5 4.5 4.51562 4.5 7C4.5 9.48438 6.51563 11.5 9 11.5C11.4844 11.5 13.5 9.48438 13.5 7C13.5 4.51562 11.4844 2.5 9 2.5Z"
			fill="#AFB0B9"
		/>
	</svg>
);

export default EyeOpen;
