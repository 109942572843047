import types, { SubjectsActions, SubjectStateType } from './types';
import { CLEAR_REDUCERS } from '../types';

const initialState: SubjectStateType = {
	data: null,
	loading: false,
	error: null,
	globalPermissions: null,
	selectedSubjectId: null,
};

const userReducer = (
	state = initialState,
	action: SubjectsActions
): SubjectStateType => {
	switch (action.type) {
		case types.GET_SUBJECTS:
			return {
				loading: true,
				error: null,
				data: null,
				globalPermissions: null,
				selectedSubjectId: null,
			};

		case types.GET_SUBJECTS_SUCCESS:
			return {
				error: null,
				loading: false,
				data: action.payload.data,
				globalPermissions: action.payload.globalPermissions,
				selectedSubjectId: null,
			};

		case types.GET_SUBJECTS_ERROR:
			return {
				error: action.payload.error,
				loading: false,
				data: null,
				globalPermissions: null,
				selectedSubjectId: null,
			};

		case CLEAR_REDUCERS:
			return initialState; // it clears the store on logout

		default:
			return state;
	}
};

export default userReducer;
