import React from 'react';
import ReactDOM from 'react-dom';
// Redux
import ReduxProvider from './app/redux/ReduxProvider';
import 'react-toastify/dist/ReactToastify.css';
import { ClickToComponent } from 'click-to-react-component';

// Apps
import { App } from './app/App';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import QueryClientProvider from '@Configs/Providers/QueryClientProvider';
import * as Sentry from '@sentry/react';
import Error500View from './app/pages/Error/components/Error500View';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

// Chart.register(...registerables)

const GlobalStyles = createGlobalStyle`


@keyframes fadeIn {
		from {
			opacity: 0;
			scale: 0.95;
		}
		to {
			opacity: 1;
			scale: 1;
		}
	}
	
	@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700');
	html,body{
		font-family: 'Nunito', sans-serif !important ;
		font-size: 14px !important;
		
	}
	
	@media (min-width: 1366px) {
		html,body{
			font-size: 14px !important;
		}
	}
	@media (min-width: 1920px) {
		html,body{
			font-size: 15px !important; 
		}
	}
	@media (min-width: 2560px) {
		html,body{
			font-size: 16px !important;
		}
	}
`;

// const ErrorBoundary =
// 	process.env.NODE_ENV === 'development'
// 		? ErrorBoundaryComponent
// 		: React.Fragment;

// handleConsoleLog(['log', 'error', 'warn']);

Sentry.init({
	dsn: 'https://316f65f5cd8a489caf79b8c9dd74f66e@o394744.ingest.sentry.io/4505545287729152',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			// tracePropagationTargets: ['localhost', 'api.test.e-valuados.com'],
		}),
		new Sentry.Replay({
			blockAllMedia: true,
			maskAllInputs: false,
			maskAllText: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
	<Sentry.ErrorBoundary fallback={<Error500View />}>
		<GlobalStyles />
		<ClickToComponent />
		<MetronicI18nProvider>
			<GoogleOAuthProvider clientId="389410407399-kf52k3b5rm5vakcj96uj1754q04k4u2m.apps.googleusercontent.com">
				<QueryClientProvider>
					<ReduxProvider>
						<App basename={PUBLIC_URL} />
						<ToastContainer />
					</ReduxProvider>
				</QueryClientProvider>
			</GoogleOAuthProvider>
		</MetronicI18nProvider>
	</Sentry.ErrorBoundary>,
	document.getElementById('root')
);
