import * as React from 'react';
const ScreenMovileIlustration = () => (
	<svg
		width={360}
		height={270}
		viewBox="0 0 360 270"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_5442_70455)">
			<path
				d="M109.757 57.3887C113.673 57.3887 116.848 53.8816 116.848 49.5553C116.848 45.2291 113.673 41.722 109.757 41.722C105.841 41.722 102.666 45.2291 102.666 49.5553C102.666 53.8816 105.841 57.3887 109.757 57.3887Z"
				fill="#FFC700"
			/>
			<path
				d="M270.554 182.91C273.465 182.91 275.825 180.637 275.825 177.834C275.825 175.031 273.465 172.758 270.554 172.758C267.643 172.758 265.283 175.031 265.283 177.834C265.283 180.637 267.643 182.91 270.554 182.91Z"
				fill="#009EF7"
			/>
			<path
				d="M109.82 152.266C111.986 152.266 113.741 150.512 113.741 148.349C113.741 146.186 111.986 144.433 109.82 144.433C107.654 144.433 105.898 146.186 105.898 148.349C105.898 150.512 107.654 152.266 109.82 152.266Z"
				fill="#FFC700"
			/>
			<path
				d="M102.509 54.788C105.958 54.788 108.753 51.8561 108.753 48.2393C108.753 44.6226 105.958 41.6907 102.509 41.6907C99.061 41.6907 96.2656 44.6226 96.2656 48.2393C96.2656 51.8561 99.061 54.788 102.509 54.788Z"
				fill="#B398EA"
			/>
			<path
				d="M269.11 174.889C271.242 174.889 272.97 173.093 272.97 170.878C272.97 168.663 271.242 166.867 269.11 166.867C266.979 166.867 265.251 168.663 265.251 170.878C265.251 173.093 266.979 174.889 269.11 174.889Z"
				fill="#165593"
			/>
			<path
				d="M292.673 78.3507C295.012 78.3507 296.909 76.3306 296.909 73.8387C296.909 71.3468 295.012 69.3267 292.673 69.3267C290.334 69.3267 288.438 71.3468 288.438 73.8387C288.438 76.3306 290.334 78.3507 292.673 78.3507Z"
				fill="#165593"
			/>
			<path
				d="M228.699 92.1687C228.009 91.072 226.88 92.106 226.409 92.858C225.436 94.4247 224.809 96.3673 225.531 98.1533C226.064 99.5007 227.382 100.629 228.919 100.409C230.331 100.221 231.398 99.062 231.366 97.652C231.366 97.276 230.833 96.994 230.551 97.3073C228.888 99.4067 226.848 102.383 228.511 105.078C229.672 106.927 232.527 107.177 233.657 105.172C233.374 105.141 233.092 105.109 232.81 105.047C232.966 105.924 233.123 106.801 233.249 107.679C233.343 108.274 234.253 108.023 234.159 107.428C234.002 106.551 233.845 105.673 233.719 104.796C233.657 104.389 233.029 104.357 232.872 104.671C232.37 105.548 231.241 105.861 230.331 105.423C229.327 104.921 228.888 103.793 228.888 102.697C228.888 100.879 230.174 99.2813 231.241 97.934C230.958 97.8087 230.707 97.7147 230.425 97.5893C230.456 98.78 229.296 99.6573 228.135 99.4067C227.256 99.2187 226.691 98.4667 226.378 97.652C226.064 96.806 226.095 95.96 226.346 95.114C226.378 95.0513 227.664 92.2627 227.852 92.576C228.197 93.14 229.013 92.67 228.699 92.1687Z"
				fill="#FFC700"
			/>
			<path
				d="M100.595 94.9887C101.286 93.1087 103.576 93.9233 104.298 95.2393C104.549 95.678 104.674 96.1793 104.737 96.6807C104.862 97.6207 104.768 97.9967 103.953 98.5607C103.074 99.438 102.854 99.0933 103.325 97.5267C103.545 97.4327 103.796 97.3387 104.015 97.2447C104.611 97.088 105.302 97.1193 105.898 97.3073C107.404 97.8087 108.157 99.4067 107.937 100.879C107.874 101.255 108.251 101.631 108.627 101.412C110.667 100.253 113.365 101.537 113.836 103.825C113.961 104.42 114.871 104.169 114.746 103.574C114.149 100.691 110.73 99.1873 108.157 100.629C108.376 100.817 108.627 100.973 108.847 101.161C109.255 98.404 107.153 95.8347 104.235 96.2733C103.231 96.43 101.725 97.0567 101.599 98.2473C101.505 99.2187 102.415 99.9393 103.325 100.033C106.023 100.284 105.961 96.3673 105.082 94.8007C103.89 92.67 100.595 92.1373 99.6541 94.7693C99.4659 95.302 100.376 95.5527 100.595 94.9887Z"
				fill="#009EF7"
			/>
			<path
				d="M52.0895 241.159C52.7798 247.958 53.972 254.663 55.6349 261.306C55.7918 261.901 56.7017 261.651 56.5448 261.055C54.9133 254.507 53.721 247.864 53.0308 241.159C52.968 240.563 52.0268 240.563 52.0895 241.159Z"
				fill="#E8E8E8"
			/>
			<path
				d="M55.8232 261.619C62.0041 263.217 68.436 263.217 74.6168 261.619C75.213 261.463 74.962 260.554 74.3659 260.711C68.3419 262.277 62.0982 262.277 56.0742 260.711C55.5095 260.554 55.2585 261.463 55.8232 261.619Z"
				fill="#E8E8E8"
			/>
			<path
				d="M75.3385 260.679C75.7464 254.005 76.2798 247.331 76.9073 240.657C76.97 240.062 76.0288 240.062 75.966 240.657C75.3385 247.331 74.8051 254.005 74.3973 260.679C74.3659 261.275 75.3071 261.275 75.3385 260.679Z"
				fill="#E8E8E8"
			/>
			<path
				d="M52.623 241.66C60.4353 243.258 68.4673 243.195 76.2797 241.441C76.8758 241.315 76.6248 240.407 76.0287 240.532C68.4046 242.224 60.5295 242.318 52.874 240.751C52.2778 240.626 52.0268 241.535 52.623 241.66Z"
				fill="#E8E8E8"
			/>
			<path
				d="M52.5289 243.916C52.7799 245.201 54.4114 245.138 55.4154 245.232C57.9568 245.451 60.5295 245.514 63.0709 245.451C65.5181 245.389 67.9654 245.201 70.4126 244.919C72.3579 244.668 74.2404 244.417 75.966 243.446C76.4994 243.164 76.0288 242.349 75.4954 242.631C72.3893 244.355 68.0909 244.292 64.6083 244.449C62.8826 244.511 61.1256 244.543 59.4 244.511C58.4901 244.48 53.627 244.699 53.4388 243.665C53.3133 243.07 52.4348 243.321 52.5289 243.916Z"
				fill="#E8E8E8"
			/>
			<path
				d="M54.3172 254.789C55.8232 257.452 57.7371 259.802 60.0275 261.839C60.4667 262.246 61.157 261.588 60.6863 261.181C58.4273 259.207 56.5762 256.919 55.133 254.319C54.8506 253.786 54.0348 254.256 54.3172 254.789Z"
				fill="#E8E8E8"
			/>
			<path
				d="M53.0936 247.551C56.2939 252.94 60.1844 257.891 64.6396 262.34C65.0789 262.779 65.7378 262.089 65.2985 261.682C60.906 257.295 57.0783 252.407 53.9094 247.081C53.5956 246.579 52.7799 247.049 53.0936 247.551Z"
				fill="#E8E8E8"
			/>
			<path
				d="M58.3332 245.295C61.659 251.217 65.5181 256.825 69.8792 262.058C70.2557 262.528 70.9146 261.87 70.5381 261.4C66.2397 256.23 62.412 250.684 59.149 244.825C58.8666 244.292 58.0508 244.762 58.3332 245.295Z"
				fill="#E8E8E8"
			/>
			<path
				d="M64.3258 245.514C67.5574 250.339 70.8204 255.133 74.1148 259.927C74.4599 260.429 75.2757 259.959 74.9306 259.457C71.6362 254.663 68.3732 249.869 65.1416 245.044C64.7964 244.543 63.9807 245.013 64.3258 245.514Z"
				fill="#E8E8E8"
			/>
			<path
				d="M69.3458 245.232C70.9773 247.864 72.7343 250.433 74.5541 252.94C74.8992 253.41 75.715 252.94 75.3698 252.47C73.5501 249.963 71.7931 247.394 70.1616 244.762C69.8478 244.261 69.0321 244.731 69.3458 245.232Z"
				fill="#E8E8E8"
			/>
			<path
				d="M42.2064 213.585C42.5829 215.747 42.9594 217.941 43.3673 220.103C43.4928 220.698 43.5869 221.325 44.0889 221.701C44.4341 221.983 45.5636 221.951 45.7832 222.265C45.8146 222.077 45.8459 221.889 45.8459 221.701C45.3753 222.171 44.9047 222.641 44.4027 223.079C44.2772 223.205 44.2458 223.393 44.2772 223.549C44.5596 224.395 44.7478 225.649 45.3126 226.369C45.5949 226.745 46.0969 226.683 46.3166 226.902C46.5048 227.09 46.4421 226.965 46.3479 227.529C46.2852 227.999 46.2852 228.5 46.3479 229.001C46.4421 229.753 46.6617 230.474 46.9441 231.163C48.5756 235.111 51.7444 238.307 54.7878 241.253C55.2271 241.66 55.8859 241.002 55.4467 240.595C53.5014 238.715 51.5562 236.772 49.9561 234.579C48.3873 232.417 46.5362 229.503 47.5088 226.745C47.5716 226.589 47.5088 226.369 47.3833 226.275C46.7872 225.805 46.1911 225.993 45.7832 225.241C44.9988 223.831 45.4381 223.361 46.4421 222.39C46.5989 222.265 46.6303 221.983 46.5048 221.826C46.2538 221.45 46.0656 221.325 45.6577 221.199C45.4067 221.105 45.0302 221.199 44.7792 221.074C44.2458 220.792 44.2458 220.103 44.1517 219.601C43.7752 217.533 43.3987 215.434 43.0536 213.366C42.9908 212.739 42.0809 212.99 42.2064 213.585Z"
				fill="#E8E8E8"
			/>
			<path
				d="M42.8967 213.46C55.133 217.533 63.5101 228.939 63.7611 241.848C63.7611 242.443 64.7023 242.443 64.7023 241.848C64.4513 228.531 55.7918 216.781 43.1477 212.551C42.583 212.363 42.332 213.272 42.8967 213.46Z"
				fill="#E8E8E8"
			/>
			<path
				d="M61.6904 229.001C64.7337 220.573 69.2204 212.426 76.4052 206.88C76.8759 206.504 76.4052 205.689 75.9346 206.065C68.5301 211.799 63.918 220.071 60.7805 228.751C60.5922 229.315 61.5021 229.565 61.6904 229.001Z"
				fill="#E8E8E8"
			/>
			<path
				d="M75.5895 206.692C75.8091 209.575 76.0601 212.457 76.2798 215.371C76.3425 215.967 77.2838 215.967 77.221 215.371C77.0014 212.489 76.7504 209.606 76.5308 206.692C76.4994 206.097 75.5581 206.097 75.5895 206.692Z"
				fill="#E8E8E8"
			/>
			<path
				d="M76.6877 215.058C76.2798 215.152 75.8719 215.246 75.4954 215.371C74.8993 215.528 75.1503 216.437 75.7464 216.28C76.1543 216.186 76.5621 216.092 76.9386 215.967C77.5348 215.81 77.2838 214.933 76.6877 215.058Z"
				fill="#E8E8E8"
			/>
			<path
				d="M75.0248 216.217C75.1503 216.813 75.6523 217.189 76.217 217.251C76.468 217.283 76.6877 217.032 76.6877 216.781C76.6877 216.499 76.468 216.343 76.217 216.311C76.3112 216.311 76.217 216.311 76.1857 216.311C76.1543 216.311 76.0915 216.28 76.1543 216.311C76.1229 216.28 76.0915 216.28 76.0602 216.249C75.9974 216.217 76.1229 216.311 76.0288 216.217C75.9974 216.186 75.9974 216.186 75.966 216.155C76.0288 216.217 75.966 216.155 75.966 216.123C75.9347 216.092 75.9347 216.029 75.9347 216.092C75.9347 216.061 75.9033 215.998 75.9033 215.967C75.8405 215.716 75.5582 215.559 75.3385 215.653C75.0875 215.716 74.962 215.967 75.0248 216.217Z"
				fill="#E8E8E8"
			/>
			<path
				d="M75.7464 216.907C75.7778 218.693 75.715 220.447 75.5267 222.202C75.464 222.766 75.5581 224.082 75.1816 224.521C75.1502 224.552 74.5855 224.646 74.46 224.74C73.9894 225.116 74.6483 225.774 75.1189 225.398C75.2758 225.273 75.6523 225.398 75.9033 225.116C76.2798 224.74 76.217 223.863 76.2797 223.424C76.5621 221.262 76.6876 219.069 76.6563 216.875C76.6876 216.311 75.7464 216.311 75.7464 216.907Z"
				fill="#E8E8E8"
			/>
			<path
				d="M74.0835 225.555C75.0875 226.745 74.8052 228.719 74.711 230.161C74.5855 231.821 74.3032 233.357 73.4874 234.798C72.3265 236.929 70.695 238.715 69.8479 241.033C69.6283 241.597 70.5382 241.848 70.7578 241.284C71.8873 238.245 74.4914 235.989 75.2758 232.793C75.8405 230.505 76.3739 226.839 74.7424 224.897C74.3659 224.458 73.707 225.116 74.0835 225.555Z"
				fill="#E8E8E8"
			/>
			<path
				d="M66.679 241.472C67.5575 232.573 69.9106 223.894 73.6756 215.81C73.9266 215.277 73.1109 214.776 72.8599 215.34C69.0321 223.612 66.6162 232.417 65.7377 241.503C65.675 242.099 66.6162 242.067 66.679 241.472Z"
				fill="#E8E8E8"
			/>
			<path
				d="M48.1677 218.693C53.6583 225.586 57.6743 233.513 60.0275 241.973C60.1843 242.569 61.0942 242.318 60.9373 241.723C58.5215 233.075 54.4113 225.053 48.8266 218.035C48.4501 217.565 47.7912 218.223 48.1677 218.693Z"
				fill="#E8E8E8"
			/>
			<path
				d="M48.7638 230.255C51.3993 230.098 53.8466 230.725 56.0742 232.103C56.5762 232.417 57.0782 231.602 56.5448 231.289C54.1917 229.847 51.5248 229.158 48.7638 229.315C48.1363 229.346 48.1363 230.286 48.7638 230.255Z"
				fill="#E8E8E8"
			/>
			<path
				d="M54.5054 226.056C54.3172 224.333 54.5368 222.672 55.1957 221.043C55.4153 220.479 54.5054 220.228 54.2858 220.792C53.5955 222.484 53.3759 224.239 53.5642 226.056C53.6269 226.651 54.5682 226.651 54.5054 226.056Z"
				fill="#E8E8E8"
			/>
			<path
				d="M64.671 220.855C65.7064 223.393 67.2124 226.087 67.5889 228.782C67.683 229.377 68.5929 229.127 68.4988 228.531C68.0909 225.805 66.5849 223.111 65.5809 220.604C65.3613 220.04 64.4514 220.291 64.671 220.855Z"
				fill="#E8E8E8"
			/>
			<path
				d="M70.444 223.831C71.3538 222.797 72.2323 221.575 73.3932 220.792C74.3658 220.134 75.4326 219.695 76.1856 218.787C76.5621 218.317 75.9032 217.659 75.5267 218.129C74.711 219.1 73.5187 219.507 72.5461 220.259C71.4793 221.074 70.6636 222.171 69.7851 223.173C69.3772 223.643 70.0361 224.301 70.444 223.831Z"
				fill="#E8E8E8"
			/>
			<path
				d="M70.7578 241.159C72.2638 240.751 73.8011 240.595 75.3699 240.689C75.966 240.72 75.966 239.78 75.3699 239.749C73.707 239.655 72.1069 239.811 70.5068 240.25C69.942 240.407 70.1616 241.315 70.7578 241.159Z"
				fill="#E8E8E8"
			/>
			<path
				d="M70.6323 241.535C72.3893 241.691 74.0835 241.472 75.7778 240.939C76.3425 240.751 76.0915 239.843 75.5268 240.031C73.9267 240.532 72.2951 240.72 70.6323 240.595C70.0361 240.563 70.0361 241.503 70.6323 241.535Z"
				fill="#E8E8E8"
			/>
			<path
				d="M53.0308 241.378C53.4387 241.378 53.8152 241.378 54.2231 241.378C54.8192 241.378 54.8192 240.438 54.2231 240.438C53.8152 240.438 53.4387 240.438 53.0308 240.438C52.4347 240.438 52.4347 241.378 53.0308 241.378Z"
				fill="#E8E8E8"
			/>
			<path
				d="M76.0288 248.961C85.9746 248.961 95.9519 248.961 105.898 248.961C106.494 248.961 106.494 248.021 105.898 248.021C95.9519 248.021 85.9746 248.021 76.0288 248.021C75.4326 248.021 75.4326 248.961 76.0288 248.961Z"
				fill="#E8E8E8"
			/>
			<path
				d="M244.387 242.757C250.317 242.757 256.215 242.757 262.145 242.757C262.741 242.757 262.741 241.817 262.145 241.817C256.215 241.817 250.317 241.817 244.387 241.817C243.791 241.817 243.791 242.757 244.387 242.757Z"
				fill="#E8E8E8"
			/>
			<path
				d="M298.226 243.321C323.797 243.321 349.368 243.321 374.938 243.321C382.217 243.321 389.496 243.321 396.775 243.321C397.371 243.321 397.371 242.381 396.775 242.381C371.205 242.381 345.634 242.381 320.063 242.381C312.784 242.381 305.505 242.381 298.226 242.381C297.63 242.381 297.63 243.321 298.226 243.321Z"
				fill="#E8E8E8"
			/>
			<path
				d="M260.357 140.453C257.972 145.373 255.4 150.198 252.67 154.929C252.544 155.18 252.639 155.587 252.952 155.619C266.412 157.655 278.46 164.329 290.069 171.066C290.602 171.379 291.073 170.565 290.54 170.251C278.837 163.483 266.726 156.778 253.172 154.71C253.266 154.929 253.36 155.18 253.454 155.399C256.215 150.668 258.757 145.843 261.141 140.923C261.424 140.391 260.639 139.889 260.357 140.453Z"
				fill="#E8E8E8"
			/>
			<path
				d="M262.428 157.53C263.369 154.835 264.31 152.109 265.251 149.415C265.659 148.287 265.942 147.033 266.475 145.937C266.6 145.279 267.008 144.777 267.636 144.401C268.012 144.495 268.389 144.589 268.765 144.683C269.33 144.84 269.895 144.965 270.428 145.122C271.715 145.435 273.785 145.435 273.566 147.159C273.409 148.381 272.687 149.697 272.279 150.887C271.338 153.551 270.428 156.214 269.487 158.877C269.299 159.441 270.209 159.692 270.397 159.128C271.62 155.619 272.844 152.078 274.068 148.569C274.35 147.754 275.134 146.375 274.507 145.498C273.817 144.558 271.746 144.495 270.679 144.213C269.644 143.963 267.824 143.023 266.757 143.524C265.847 143.931 265.659 145.498 265.377 146.281C264.09 149.947 262.804 153.645 261.518 157.311C261.329 157.843 262.239 158.094 262.428 157.53Z"
				fill="#E8E8E8"
			/>
			<path
				d="M274.538 160.883C276.546 156.653 278.586 152.391 280.594 148.161C281.598 146.031 282.602 143.931 283.637 141.801C284.108 140.829 284.484 139.67 285.08 138.761C285.582 137.978 285.708 137.79 286.586 138.135C287.057 138.323 287.622 138.793 288.03 139.075C288.563 139.451 289.41 139.858 289.787 140.359C290.257 140.955 290.069 141.581 289.787 142.333C289.41 143.336 288.845 144.307 288.375 145.279C287.339 147.409 286.304 149.54 285.206 151.671C283.135 155.713 280.97 159.723 278.743 163.703C278.429 164.235 279.245 164.705 279.558 164.173C282.288 159.347 284.892 154.459 287.339 149.509C288.563 147.065 289.912 144.558 290.947 142.02C291.669 140.171 290.1 139.419 288.72 138.448C287.622 137.696 285.896 135.973 284.704 137.383C283.951 138.26 283.543 139.733 283.041 140.798C282.413 142.083 281.817 143.367 281.19 144.683C278.68 149.947 276.17 155.211 273.691 160.475C273.472 160.945 274.287 161.447 274.538 160.883Z"
				fill="#E8E8E8"
			/>
			<path
				d="M284.955 167.4C287.653 163.17 290.696 159.159 294.054 155.462C293.865 155.493 293.677 155.525 293.489 155.525C294.681 156.339 295.842 157.123 297.034 157.937C296.971 157.718 296.909 157.499 296.877 157.279C294.21 161.447 291.544 165.583 288.877 169.75C288.563 170.251 289.347 170.721 289.692 170.22C292.359 166.053 295.026 161.917 297.693 157.749C297.85 157.53 297.724 157.248 297.536 157.091C296.344 156.277 295.183 155.493 293.991 154.679C293.834 154.553 293.552 154.616 293.426 154.741C290.006 158.533 286.9 162.575 284.17 166.867C283.825 167.463 284.641 167.933 284.955 167.4Z"
				fill="#E8E8E8"
			/>
			<path
				d="M322.385 137.602C319.248 136.411 317.271 133.278 317.836 129.925C318.401 126.51 321.758 123.471 325.209 125.507C327.593 126.917 328.817 130.113 327.907 132.714C326.966 135.503 323.891 137.163 321.036 136.725C320.44 136.631 320.189 137.539 320.785 137.633C324.142 138.135 327.688 136.286 328.817 132.965C329.947 129.643 328.127 125.633 324.864 124.285C321.036 122.719 317.553 126.009 316.895 129.675C316.204 133.56 318.495 137.132 322.103 138.511C322.699 138.73 322.95 137.821 322.385 137.602Z"
				fill="#E8E8E8"
			/>
			<path
				d="M328.817 137.007C322.573 136.819 324.927 126.823 330.449 127.199C335.971 127.607 333.712 138.855 327.656 136.787C327.091 136.599 326.84 137.508 327.405 137.696C334.402 140.077 337.539 127.356 330.856 126.291C327.593 125.758 324.644 128.829 324.142 131.868C323.64 134.876 325.68 137.821 328.817 137.915C329.413 137.978 329.413 137.038 328.817 137.007Z"
				fill="#E8E8E8"
			/>
			<path
				d="M324.142 108.65C323.609 108.086 323.358 107.177 323.766 106.488C324.174 105.83 325.178 105.517 325.805 106.018C326.37 106.457 326.495 107.397 325.962 107.867C325.335 108.431 324.331 108.149 323.734 107.679C323.264 107.303 322.605 107.961 323.076 108.337C324.048 109.089 325.554 109.465 326.59 108.556C327.531 107.71 327.437 106.143 326.495 105.329C325.523 104.483 323.985 104.733 323.17 105.673C322.26 106.739 322.511 108.337 323.452 109.308C323.891 109.747 324.55 109.089 324.142 108.65Z"
				fill="#E8E8E8"
			/>
			<path
				d="M233.092 132.244C232.213 126.917 235.32 120.494 241.344 120.557C244.418 120.588 247.399 121.841 250.505 121.403C252.701 121.089 254.584 119.836 255.902 118.113C257.219 116.389 257.972 114.227 258.098 112.065C258.255 108.869 257.031 105.611 257.91 102.446C258.349 100.848 259.29 99.3753 260.89 98.78C262.428 98.2473 264.09 98.5607 265.691 98.498C268.012 98.404 269.832 97.4013 270.993 95.3647C272.624 92.5133 272.248 89.098 273.377 86.09C274.727 82.4553 278.523 79.3533 282.539 81.1393C283.072 81.39 283.574 80.5753 283.01 80.3247C277.55 77.8807 273.001 82.8 271.934 87.7507C271.244 90.978 271.369 95.9913 267.51 97.276C264.812 98.1847 261.769 96.7747 259.29 98.6233C257.251 100.127 256.655 102.885 256.686 105.297C256.78 108.838 257.878 112.285 256.309 115.669C254.866 118.865 252.011 120.776 248.466 120.557C245.328 120.369 242.222 119.084 239.053 119.899C233.688 121.246 231.398 127.575 232.245 132.526C232.308 133.059 233.217 132.808 233.092 132.244Z"
				fill="#E8E8E8"
			/>
			<path
				d="M272.75 109.903C271.589 107.271 273.189 104.765 275.731 103.919C277.582 103.292 279.621 103.261 281.221 102.007C282.476 101.036 282.884 99.814 282.727 98.2473C282.57 96.7747 282.57 95.6153 283.731 94.6127C284.704 93.7667 285.99 93.4533 287.245 93.3907C290.696 93.2653 295.434 95.02 297.944 91.6673C299.105 90.1007 298.76 88.158 299.293 86.4033C300.015 83.9593 302.368 82.4553 304.878 82.5807C305.474 82.612 305.474 81.672 304.878 81.6407C302.964 81.5467 301.113 82.2673 299.795 83.646C298.164 85.3693 298.383 87.312 297.881 89.474C296.532 95.396 288.281 91.2287 284.39 93.0773C282.947 93.7667 281.786 94.9887 281.661 96.618C281.504 98.5293 282.382 100.096 280.437 101.412C279.402 102.101 278.209 102.321 277.017 102.603C275.04 103.073 273.095 103.668 272.091 105.579C271.275 107.083 271.275 108.807 271.966 110.342C272.185 110.937 272.97 110.436 272.75 109.903Z"
				fill="#E8E8E8"
			/>
			<path
				d="M328.66 68.1673C328.535 67.8853 328.409 67.572 328.315 67.2587C328.723 66.7887 329.099 66.3187 329.507 65.8487C329.633 65.8173 330.198 66.1933 330.386 66.1933C330.856 66.256 331.264 66.1307 331.641 65.8487C332.802 64.94 332.394 63.4987 332.739 62.2767C333.304 60.1773 335.782 61.6187 336.912 60.052C338.041 58.5167 337.633 55.258 340.645 56.1353C341.242 56.292 341.493 55.3833 340.896 55.2267C339.171 54.7253 337.602 55.54 336.975 57.2007C336.786 57.6707 336.724 58.2033 336.535 58.6733C336.127 59.77 335.688 59.9267 334.496 59.9893C333.617 60.052 332.927 59.9893 332.331 60.7413C331.829 61.3993 331.829 62.2453 331.735 63.0287C331.641 64.188 331.735 65.1593 330.009 65.034C329.664 65.0027 329.382 64.6893 329.005 64.6893C326.935 64.658 327.28 67.196 327.782 68.418C327.97 68.982 328.911 68.7313 328.66 68.1673Z"
				fill="#E8E8E8"
			/>
			<path
				d="M334.559 70.768C334.057 70.11 333.21 68.1987 334.684 67.6973C335.218 67.5093 335.908 67.7287 336.473 67.7287C336.975 67.7287 337.414 67.7287 337.822 67.384C339.077 66.3813 338.167 64.5953 338.826 63.342C339.893 61.3053 342.026 62.9033 343.532 61.838C344.599 61.086 344.881 59.6447 345.069 58.454C345.164 57.8587 344.254 57.608 344.16 58.2033C343.94 59.6133 343.689 61.18 341.932 61.3367C341.022 61.4307 340.332 61.1173 339.485 61.5247C338.104 62.1827 337.665 63.4047 337.634 64.8147C337.602 66.0053 337.885 66.6007 335.877 66.6947C335.626 66.6947 335.375 66.632 335.124 66.632C334.402 66.6947 333.774 67.0707 333.367 67.666C332.488 68.9193 332.99 70.3607 333.869 71.4573C334.276 71.9273 334.935 71.238 334.559 70.768Z"
				fill="#E8E8E8"
			/>
			<path
				d="M67.8084 87.7507C70.4125 84.6173 74.1148 82.048 78.3818 82.4867C83.0253 82.9567 85.3157 86.654 85.7549 90.978C86.5079 98.1533 84.7509 106.739 89.614 112.849C91.5279 115.261 94.383 116.546 97.4578 115.794C101.16 114.917 104.047 112.003 107.341 110.279C111.231 108.243 116.597 107.146 120.111 110.561C122.683 113.068 123.248 116.891 124.095 120.212C124.88 123.22 126.229 126.541 128.927 128.327C130.558 129.393 132.347 129.487 134.229 129.361C136.331 129.205 140.63 129.079 140.191 132.369C140.128 132.965 141.069 132.965 141.132 132.369C141.289 131.085 140.693 130.051 139.657 129.299C138.026 128.108 136.143 128.296 134.229 128.421C132.064 128.578 130.213 128.39 128.55 126.855C127.044 125.476 126.135 123.533 125.476 121.622C124.378 118.332 124.064 114.729 122.181 111.721C120.33 108.744 117.13 107.303 113.679 107.428C109.318 107.585 105.647 110.091 102.164 112.41C100.501 113.507 98.7128 114.666 96.6734 115.011C94.2262 115.418 92.0613 114.353 90.4925 112.535C87.5433 109.089 87.0727 104.169 86.9158 99.8453C86.7589 95.8033 87.2923 91.4167 85.9432 87.5C84.7195 83.928 81.6134 81.7033 77.8798 81.5153C73.55 81.296 69.7537 83.8967 67.0868 87.0927C66.7417 87.5627 67.4005 88.2207 67.8084 87.7507Z"
				fill="#E8E8E8"
			/>
			<path
				d="M51.9641 136.098C56.8586 144.245 61.2198 152.673 65.0789 161.353C65.3299 161.917 66.1456 161.415 65.8946 160.883C62.0355 152.203 57.643 143.775 52.7799 135.628C52.4661 135.095 51.6504 135.565 51.9641 136.098Z"
				fill="#E8E8E8"
			/>
			<path
				d="M52.5602 135.722C60.1844 130.991 68.0281 126.573 76.0915 122.562C76.6249 122.28 76.1542 121.465 75.6209 121.747C67.5889 125.758 59.7451 130.176 52.0896 134.907C51.5562 135.252 52.0269 136.067 52.5602 135.722Z"
				fill="#E8E8E8"
			/>
			<path
				d="M75.5267 122.155C79.5114 130.521 83.7156 138.761 88.1709 146.877C88.4532 147.409 89.269 146.939 88.9866 146.407C84.5314 138.291 80.2957 130.051 76.3425 121.685C76.0601 121.121 75.2757 121.591 75.5267 122.155Z"
				fill="#E8E8E8"
			/>
			<path
				d="M66.1456 161.541C73.6442 157.029 81.0487 152.391 88.3904 147.629C88.8924 147.315 88.4218 146.501 87.9198 146.814C80.5781 151.577 73.1736 156.214 65.6749 160.726C65.1729 161.039 65.6436 161.854 66.1456 161.541Z"
				fill="#E8E8E8"
			/>
			<path
				d="M65.5495 132.965C65.3612 138.981 65.7063 144.965 66.5848 150.919C66.679 151.514 67.5888 151.263 67.4947 150.668C66.6162 144.809 66.3025 138.887 66.4907 132.965C66.5221 132.369 65.5808 132.369 65.5495 132.965Z"
				fill="#E8E8E8"
			/>
			<path
				d="M65.5809 132.651C69.942 136.067 74.3031 139.419 78.727 142.741C79.1976 143.117 79.6682 142.302 79.1976 141.926C74.8365 138.667 70.5067 135.346 66.2397 131.993C65.7691 131.617 65.1102 132.275 65.5809 132.651Z"
				fill="#E8E8E8"
			/>
			<path
				d="M67.5575 151.107C71.1656 147.879 75.8719 146.156 79.2918 142.678C79.731 142.239 79.0408 141.581 78.6329 142.02C75.213 145.467 70.5381 147.221 66.8986 150.449C66.428 150.825 67.1183 151.483 67.5575 151.107Z"
				fill="#E8E8E8"
			/>
			<path
				d="M53.219 141.017C54.223 140.516 55.227 140.046 56.231 139.545C56.7644 139.294 56.2938 138.479 55.7604 138.73C54.7564 139.231 53.7524 139.701 52.7484 140.203C52.215 140.485 52.6857 141.268 53.219 141.017Z"
				fill="#E8E8E8"
			/>
			<path
				d="M56.4507 146.344C57.2978 145.78 58.2077 145.31 59.1489 144.903C59.7137 144.683 59.4627 143.775 58.8979 143.994C57.8625 144.433 56.8899 144.934 55.9487 145.529C55.4781 145.874 55.9487 146.689 56.4507 146.344Z"
				fill="#E8E8E8"
			/>
			<path
				d="M59.996 152.485C60.8745 152.047 61.753 151.608 62.6315 151.201C63.1649 150.919 62.6943 150.135 62.1609 150.386C61.2824 150.825 60.4039 151.263 59.5254 151.671C58.992 151.921 59.4626 152.736 59.996 152.485Z"
				fill="#E8E8E8"
			/>
			<path
				d="M61.8159 158.627C62.7885 157.781 63.7925 157.06 64.922 156.433C65.4554 156.12 64.9848 155.337 64.4514 155.619C63.2905 156.277 62.1924 157.06 61.157 157.969C60.6864 158.376 61.3453 159.034 61.8159 158.627Z"
				fill="#E8E8E8"
			/>
			<path
				d="M77.3151 128.327C78.0367 127.826 78.7897 127.481 79.6368 127.262C80.2329 127.105 79.9819 126.228 79.3858 126.353C78.4759 126.573 77.5974 126.949 76.8131 127.513C76.3424 127.857 76.8131 128.672 77.3151 128.327Z"
				fill="#E8E8E8"
			/>
			<path
				d="M80.1075 133.685C81.1115 133.059 82.1155 132.432 83.0881 131.774C83.5901 131.461 83.1195 130.646 82.6175 130.959C81.6135 131.586 80.6095 132.213 79.6368 132.871C79.1035 133.215 79.5741 134.03 80.1075 133.685Z"
				fill="#E8E8E8"
			/>
			<path
				d="M83.3078 140.109C84.3745 139.639 85.4726 139.169 86.5394 138.699C87.1041 138.448 86.6021 137.633 86.0688 137.884C85.002 138.354 83.9039 138.824 82.8371 139.294C82.2724 139.545 82.7744 140.359 83.3078 140.109Z"
				fill="#E8E8E8"
			/>
			<path
				d="M85.598 144.307C86.4452 143.493 87.3864 142.803 88.4218 142.208C88.9552 141.926 88.4845 141.111 87.9512 141.393C86.853 142.02 85.849 142.741 84.9392 143.618C84.4999 144.057 85.1588 144.715 85.598 144.307Z"
				fill="#E8E8E8"
			/>
			<path
				d="M68.8125 157.405C69.5969 158.564 70.2558 159.786 70.7578 161.071C70.9774 161.635 71.8873 161.384 71.6676 160.82C71.1343 159.441 70.4754 158.125 69.6283 156.903C69.2831 156.433 68.4674 156.903 68.8125 157.405Z"
				fill="#E8E8E8"
			/>
			<path
				d="M73.6129 154.804C74.209 155.869 74.7737 156.903 75.3699 157.969C75.6522 158.501 76.468 158.031 76.1856 157.499C75.5895 156.433 75.0247 155.399 74.4286 154.334C74.1462 153.801 73.3305 154.271 73.6129 154.804Z"
				fill="#E8E8E8"
			/>
			<path
				d="M79.1662 150.825C79.7937 151.953 80.4212 153.081 81.0487 154.209C81.3311 154.741 82.1468 154.271 81.8645 153.739C81.237 152.611 80.6095 151.483 79.982 150.355C79.6996 149.822 78.8838 150.292 79.1662 150.825Z"
				fill="#E8E8E8"
			/>
			<path
				d="M84.1548 147.848C84.6568 149.039 85.1588 150.229 85.6922 151.389C85.9432 151.953 86.7276 151.451 86.5079 150.919C86.0059 149.728 85.5039 148.537 84.9706 147.378C84.7196 146.845 83.9038 147.315 84.1548 147.848Z"
				fill="#E8E8E8"
			/>
			<path
				d="M55.6978 130.897C56.2939 131.868 56.8586 132.839 57.4548 133.779C57.7685 134.312 58.5843 133.811 58.2705 133.309C57.6744 132.338 57.1096 131.367 56.5135 130.427C56.1998 129.925 55.384 130.395 55.6978 130.897Z"
				fill="#E8E8E8"
			/>
			<path
				d="M61.0942 127.763C61.659 129.017 62.2237 130.27 62.7885 131.523C63.0395 132.087 63.8552 131.586 63.6042 131.053C63.0395 129.8 62.4747 128.547 61.91 127.293C61.6276 126.729 60.8432 127.199 61.0942 127.763Z"
				fill="#E8E8E8"
			/>
			<path
				d="M65.9573 124.755C66.8044 125.883 67.4633 127.105 67.9025 128.421C68.0908 128.985 69.0007 128.735 68.8124 128.171C68.3418 126.761 67.6829 125.445 66.773 124.285C66.4279 123.815 65.6122 124.254 65.9573 124.755Z"
				fill="#E8E8E8"
			/>
			<path
				d="M71.1969 122.343C71.8558 123.345 72.4833 124.317 73.1422 125.319C73.4559 125.821 74.2717 125.351 73.9579 124.849C73.299 123.847 72.6715 122.875 72.0127 121.873C71.6675 121.371 70.8518 121.841 71.1969 122.343Z"
				fill="#E8E8E8"
			/>
			<path
				d="M119.985 60.2087C116.942 59.2687 116.785 56.0413 118.228 53.6287C119.64 51.2787 122.37 50.652 124.503 52.5947C126.26 54.224 126.417 57.0127 124.786 58.7987C123.154 60.616 120.487 60.5847 118.605 59.206C118.134 58.83 117.663 59.6447 118.134 60.0207C120.519 61.7753 124.001 61.4933 125.789 59.018C127.641 56.5113 126.982 53.002 124.378 51.31C118.103 47.268 112.706 58.924 119.734 61.1173C120.33 61.3053 120.581 60.3967 119.985 60.2087Z"
				fill="#E8E8E8"
			/>
			<path
				d="M125.036 61.0233C122.683 61.744 122.119 58.924 123.154 57.42C124.001 56.198 125.664 55.4773 127.17 55.8847C128.613 56.2607 129.649 57.7333 129.272 59.206C128.801 61.0547 126.856 61.7127 125.131 61.4307C124.534 61.3367 124.283 62.2453 124.88 62.3393C127.201 62.7467 129.711 61.744 130.213 59.2373C130.653 57.0753 128.958 55.1013 126.856 54.8507C124.534 54.5687 121.993 56.2607 121.679 58.642C121.397 60.71 123.279 62.5587 125.35 61.9007C125.884 61.744 125.633 60.8353 125.036 61.0233Z"
				fill="#E8E8E8"
			/>
			<path
				d="M105.019 81.4213C104.266 82.9567 105.333 84.492 106.902 84.8993C108.094 85.2127 109.349 84.9307 110.573 84.9933C111.89 85.056 113.208 85.3693 114.4 85.902C117.036 87.0927 118.73 89.2547 118.824 92.1687C118.918 94.6127 118.197 97.1507 119.264 99.4693C121.523 104.357 127.735 100.629 130.935 103.793C133.602 106.457 132.504 111.313 136.081 113.35C136.614 113.663 137.085 112.849 136.551 112.535C133.351 110.718 134.104 105.987 131.845 103.355C130.653 101.976 128.927 101.6 127.17 101.475C125.037 101.349 122.276 101.788 120.707 99.9707C118.887 97.84 119.86 94.644 119.766 92.1373C119.703 90.1633 119.044 88.3773 117.663 86.9673C116.44 85.7453 114.84 84.8993 113.177 84.4607C112.33 84.2413 111.451 84.0847 110.573 84.0533C109.443 83.9907 104.549 84.5547 105.866 81.8913C106.117 81.3587 105.302 80.8887 105.019 81.4213Z"
				fill="#E8E8E8"
			/>
			<path
				d="M31.6645 250.559C37.3433 250.559 42.9908 250.559 48.6697 250.559C49.2658 250.559 49.2658 249.619 48.6697 249.619C42.9908 249.619 37.3433 249.619 31.6645 249.619C31.037 249.619 31.037 250.559 31.6645 250.559Z"
				fill="#E8E8E8"
			/>
			<path
				d="M72.2323 265.912C82.3665 265.912 92.532 265.912 102.666 265.912C103.262 265.912 103.262 264.972 102.666 264.972C92.532 264.972 82.3665 264.972 72.2323 264.972C71.6048 264.972 71.6048 265.912 72.2323 265.912Z"
				fill="#E8E8E8"
			/>
			<path
				d="M240.998 264.283C246.113 263.029 251.258 262.904 256.466 263.719C258.663 264.063 260.89 264.408 263.118 264.22C265.283 264.032 267.385 263.374 269.487 262.81C271.871 262.152 274.256 261.651 276.766 261.839C279.339 262.027 281.817 262.747 284.327 263.311C286.492 263.813 288.688 264.157 290.916 264.126C293.269 264.063 295.591 263.656 297.881 263.311C304.156 262.309 310.055 262.935 316.267 263.969C316.863 264.063 317.114 263.155 316.518 263.061C311.498 262.246 306.447 261.4 301.333 261.901C295.999 262.434 291.042 263.75 285.645 262.653C281.127 261.745 276.829 260.335 272.185 261.212C269.707 261.682 267.353 262.559 264.875 263.029C262.114 263.562 259.447 263.249 256.686 262.81C251.321 261.995 246.018 262.058 240.716 263.374C240.183 263.531 240.434 264.439 240.998 264.283Z"
				fill="#E8E8E8"
			/>
			<path
				d="M289.19 254.757C299.042 254.225 308.894 253.692 318.746 253.191C323.609 252.94 328.315 253.034 333.147 253.692C335.312 253.974 337.382 254.037 339.547 253.786C341.963 253.504 344.473 253.128 346.732 254.287C347.266 254.569 347.736 253.755 347.203 253.473C343.375 251.53 338.951 253.347 334.841 252.971C332.394 252.752 330.009 252.282 327.562 252.157C325.021 252.031 322.479 252.094 319.938 252.219C309.678 252.689 299.419 253.316 289.159 253.849C288.594 253.849 288.594 254.789 289.19 254.757Z"
				fill="#E8E8E8"
			/>
			<path
				d="M145.901 28.844C144.677 27.998 143.014 26.6507 143.516 24.9587C144.05 23.204 145.367 24.05 146.591 23.6427C148.223 23.11 148.128 21.136 147.658 19.8513C147.187 18.598 146.183 15.5587 148.787 16.5927C149.383 16.8433 149.823 17.2507 150.513 17.0313C151.14 16.8433 151.454 16.2793 151.611 15.684C151.737 15.1513 151.642 14.5873 151.611 14.0233C151.548 13.6473 151.517 13.24 151.517 12.864C151.391 11.9553 151.956 11.5793 153.148 11.736C153.18 12.3313 154.121 12.3313 154.09 11.736C154.027 10.8587 153.368 10.232 152.427 10.2633C151.329 10.2947 150.733 11.36 150.607 12.3627C150.513 13.052 151.078 14.65 150.764 15.214C150.387 15.4647 150.011 15.7467 149.603 15.9973C149.446 15.9033 149.289 15.8093 149.132 15.7153C148.85 15.6213 148.63 15.4333 148.348 15.3707C147.375 15.1513 146.591 15.7467 146.246 16.624C145.65 18.128 146.717 19.3813 147.03 20.7913C147.438 22.734 145.964 22.5147 144.677 22.734C144.112 22.828 143.642 23.11 143.265 23.5173C141.289 25.8047 143.579 28.3427 145.462 29.6587C145.932 30.0033 146.403 29.1887 145.901 28.844Z"
				fill="#E8E8E8"
			/>
			<path
				d="M152.082 27.9667C151.046 26.776 150.262 25.1467 152.27 24.52C152.772 24.3633 153.337 24.426 153.807 24.1127C154.843 23.392 154.403 22.4207 154.215 21.418C154.121 20.854 153.901 20.102 154.247 19.538C154.498 19.1307 155.407 18.974 155.878 18.88C156.537 18.7233 157.227 18.6293 157.792 18.2533C158.639 17.658 158.953 16.5927 158.388 15.684C158.074 15.1827 157.259 15.6527 157.572 16.154C158.514 17.658 154.121 18.0653 153.368 19.162C152.49 20.4467 154.341 22.7027 152.803 23.5173C152.207 23.8307 151.454 23.6113 150.889 24.0187C150.482 24.3007 150.199 24.7393 150.074 25.2093C149.729 26.5567 150.638 27.716 151.454 28.656C151.831 29.0947 152.49 28.4367 152.082 27.9667Z"
				fill="#E8E8E8"
			/>
			<path
				d="M115.028 20.5407C114.212 19.9767 114.118 18.974 114.369 18.0967C114.526 17.5013 115.279 15.872 116 15.8093C117.193 15.684 117.789 17.188 118.103 18.0653C118.385 18.9113 118.479 19.7573 118.51 20.6347C118.51 21.23 119.452 21.23 119.452 20.6347C119.42 18.88 118.259 13.4907 115.373 15.0573C114.275 15.6527 113.459 17.376 113.334 18.5667C113.208 19.6947 113.647 20.6973 114.557 21.3553C115.028 21.7 115.498 20.8853 115.028 20.5407Z"
				fill="#E8E8E8"
			/>
			<path
				d="M114.651 21.042C113.522 21.324 112.392 21.606 111.231 21.888C110.573 22.0447 109.443 22.1073 108.91 22.6087C108 23.4547 108.251 26.0553 108.251 27.152C108.251 27.4653 108.565 27.6847 108.847 27.5907C109.004 27.5593 109.161 27.4967 109.349 27.4653C110.165 27.0267 110.573 27.3087 110.604 28.2487C111.231 28.8753 111.231 29.2513 110.604 29.314C110.227 29.4707 109.851 29.5333 109.443 29.5333C109.192 29.5647 108.972 29.7213 108.972 30.0033C109.035 32.0087 109.192 33.9827 109.412 35.988C109.474 36.5833 110.416 36.5833 110.353 35.988C110.133 33.9827 109.976 32.0087 109.914 30.0033C109.757 30.16 109.6 30.3167 109.443 30.4733C110.761 30.3167 112.863 29.784 112.581 27.998C112.267 26.0867 109.945 26.306 108.627 26.682C108.816 26.8387 109.035 26.9953 109.223 27.1207C109.223 26.4 109.223 25.7107 109.286 24.99C109.38 23.862 109.318 23.4547 110.353 23.0473C111.765 22.4833 113.428 22.2953 114.902 21.9193C115.498 21.8253 115.247 20.9167 114.651 21.042Z"
				fill="#E8E8E8"
			/>
			<path
				d="M119.169 21.324C120.487 21.2613 121.836 21.2613 123.154 21.418C124.409 21.5747 124.346 21.7627 124.535 22.8593C124.66 23.5487 124.691 24.2693 124.754 24.9587C125.005 27.6533 125.193 30.348 125.35 33.0427C125.382 33.638 126.323 33.638 126.292 33.0427C126.103 29.3767 125.978 25.6167 125.382 21.982C125.225 21.0733 125.068 20.7913 124.127 20.6347C122.495 20.3213 120.801 20.3213 119.169 20.4153C118.573 20.4153 118.573 21.3553 119.169 21.324Z"
				fill="#E8E8E8"
			/>
			<path
				d="M110.102 36.646C111.953 36.3327 113.804 36.0193 115.624 35.706C117.726 35.3613 119.734 35.518 121.836 35.3613C122.809 35.2673 127.421 34.7973 125.727 32.886C125.319 32.4473 124.66 33.1053 125.068 33.544C125.319 33.826 121.052 34.4527 120.958 34.4527C119.64 34.484 118.322 34.4213 117.004 34.578C114.62 34.86 112.235 35.3613 109.851 35.7687C109.255 35.8313 109.506 36.74 110.102 36.646Z"
				fill="#E8E8E8"
			/>
			<path
				d="M176.115 234.485C179.849 237.367 183.425 236.333 190.83 237.587C197.136 238.652 204.227 241.002 211.757 245.702C203.725 255.102 195.693 265.035 187.724 275.5C185.81 278.038 183.896 280.576 182.045 283.114C185.12 283.835 188.822 284.963 192.838 286.78C196.76 288.535 199.96 290.509 202.501 292.295C214.455 281.892 225.123 271.834 234.629 262.34C239.022 257.953 245.234 251.248 246.207 241.754C246.269 241.221 246.269 240.751 246.269 240.281C246.05 224.521 226.189 213.115 214.11 208.917C200.901 204.342 178.186 201.867 171.848 212.802C168.114 219.257 170.311 230.004 176.115 234.485Z"
				fill="#FFC700"
			/>
			<path
				opacity={0.26}
				d="M199.301 218.724C199.081 221.701 198.548 225.241 197.45 229.158C196.666 231.947 195.724 234.422 194.783 236.521C193.748 236.929 192.744 237.336 191.708 237.743C198.454 240.407 205.231 243.07 211.977 245.733C206.957 251.467 201.905 257.483 196.822 263.75C191.583 270.205 186.594 276.534 181.857 282.769C185.214 283.866 188.539 284.963 191.897 286.028C203.913 272.805 215.93 259.551 227.946 246.329C229.201 242.725 229.139 239.905 228.762 237.931C227.037 228.281 215.208 220.416 199.301 218.724Z"
				fill="#6D6C6C"
			/>
			<path
				opacity={0.23}
				d="M211.914 245.671C213.012 247.331 214.079 249.023 215.177 250.684C205.827 261.933 196.477 273.15 187.128 284.399C185.465 283.96 183.77 283.49 182.108 283.051C188.1 274.842 194.563 266.413 201.56 257.922C205.043 253.692 208.494 249.619 211.914 245.671Z"
				fill="#6D6C6C"
			/>
			<path
				d="M180.696 260.178C189.951 261.87 199.238 263.531 208.494 265.223C219.193 288.535 220.385 296.055 218.377 297.339C216.934 298.248 213.922 295.992 210.565 293.454C202.156 287.093 197.481 279.511 194.94 274.403C189.355 273.651 183.77 272.868 178.186 272.116C179.001 268.137 179.849 264.157 180.696 260.178Z"
				fill="#161616"
				stroke="#161616"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d="M161.62 208.259C144.74 213.209 134.449 223.487 127.829 230.255C118.887 239.435 107.906 250.684 111.075 258.987C112.706 263.217 117.663 265.818 140.755 269.829C158.2 272.868 173.323 274.497 185.12 275.406C186.594 270.017 188.069 264.659 189.512 259.269C177.652 255.447 165.824 251.655 153.964 247.833C161.243 246.297 168.899 244.48 176.868 242.255C182.923 240.563 188.696 238.809 194.187 236.96C199.27 226.714 200.368 215.653 195.065 209.387C187.661 200.613 171.064 205.47 161.62 208.259Z"
				fill="#FFC700"
			/>
			<path
				opacity={0.29}
				d="M199.176 219.1C199.019 222.202 198.485 226.181 197.042 230.568C196.164 233.231 195.16 235.55 194.156 237.461C193.308 237.524 192.461 237.618 191.614 237.681C184.461 240.031 176.837 242.255 168.773 244.323C163.565 245.671 158.482 246.83 153.556 247.895C165.447 251.624 177.339 255.321 189.23 259.05C188.979 260.335 188.665 261.682 188.288 263.092C187.849 264.721 187.347 266.225 186.877 267.604C170.467 263.562 154.058 259.52 137.649 255.478C136.896 254.569 134.418 251.279 134.794 246.924C135.202 242.193 139.72 238.84 145.493 235.769C162.184 226.933 190.171 220.949 199.176 219.1Z"
				fill="#6D6C6C"
			/>
			<path
				opacity={0.24}
				d="M145.116 248.553C148.097 248.334 151.109 248.115 154.09 247.864C165.793 251.749 177.495 255.666 189.198 259.551C188.696 261.4 188.194 263.28 187.692 265.129C173.511 259.583 159.298 254.068 145.116 248.553Z"
				fill="#6D6C6C"
			/>
			<path
				d="M157.196 137.257C161.839 134.531 169.903 130.646 180.633 129.518C197.074 127.795 209.686 133.685 214.675 136.349C213.765 148.663 212.353 158.501 211.224 165.269C209.875 173.353 208.776 177.834 208.274 185.26C207.647 194.566 208.431 202.211 209.153 207.225C203.694 208.917 195.442 210.765 185.277 210.546C175.488 210.327 167.581 208.196 162.279 206.347C163.691 195.13 163.22 186.231 162.53 180.184C161.745 173.447 160.679 170.157 159.392 162.011C157.729 151.483 157.29 142.866 157.196 137.257Z"
				fill="white"
				stroke="#161616"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				opacity={0.14}
				d="M191.144 133.247C191.803 135.628 192.556 138.918 193.058 142.866C193.403 145.529 194.815 157.593 190.422 173.51C187.724 183.317 184.869 193.689 175.519 202.211C172.507 204.969 169.589 206.817 167.456 207.977C172.193 209.199 178.374 210.358 185.684 210.421C195.254 210.515 203.16 208.791 208.651 207.099C208.337 204.749 207.929 201.209 207.772 196.947C207.647 193.219 207.647 187.265 210.22 170.502C212.259 157.311 213.702 151.639 214.424 139.701C214.518 138.323 214.549 137.163 214.612 136.38C212.322 135.033 209.341 133.56 205.67 132.369C202.282 131.273 199.238 130.709 196.728 130.458C194.909 131.367 193.026 132.307 191.144 133.247Z"
				fill="#6D6C6C"
			/>
			<path
				opacity={0.17}
				d="M213.702 141.487C211.945 151.42 209.624 159.441 207.804 165.019C202.47 181.312 198.736 192.279 188.665 200.3C182.421 205.282 175.927 207.381 171.754 208.384C175.99 209.575 181.982 210.734 189.23 210.577C197.576 210.389 204.321 208.509 208.745 206.911C208.149 203.089 207.459 197.355 207.647 190.367C207.867 181.312 209.341 175.86 210.973 165.927C211.883 160.099 213.043 151.765 213.702 141.487Z"
				fill="#6D6C6C"
			/>
			<path
				d="M157.008 137.101C152.929 139.921 147.689 144.37 143.108 150.981C132.786 165.959 133.068 181.845 133.476 187.704C135.359 192.749 138.591 199.36 144.238 205.846C147.532 209.637 152.364 215.089 160.616 218.599C163.377 219.789 165.793 220.447 167.55 220.823C169.056 219.476 170.53 218.129 172.036 216.781C167.204 211.141 162.341 205.219 157.478 199.109C153.745 194.441 150.168 189.803 146.717 185.229C146.842 181.375 147.658 174.387 151.862 167.087C154.247 162.951 157.039 159.943 159.267 157.906C158.514 150.981 157.761 144.057 157.008 137.101Z"
				fill="white"
				stroke="#161616"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				opacity={0.13}
				d="M157.604 144.088C148.568 154.271 144.269 163.922 142.136 170.001C138.402 180.529 139.187 186.043 139.751 188.675C140.222 190.743 141.257 194.284 145.054 199.673C148.348 204.342 155.282 212.708 168.491 219.601C169.652 218.473 170.813 217.377 171.973 216.249C168.24 212.05 164.475 207.632 160.678 203.026C155.69 196.979 151.046 191.025 146.748 185.197C146.873 182.033 147.407 177.615 149.258 172.664C152.05 165.238 156.443 160.35 159.172 157.749C158.608 153.206 158.106 148.663 157.604 144.088Z"
				fill="#6D6C6C"
			/>
			<path
				opacity={0.16}
				d="M146.717 185.605C147.281 189.271 148.442 193.939 150.889 198.984C156.286 209.951 164.726 215.998 169.024 218.63C169.871 217.878 170.687 217.157 171.534 216.405C163.251 206.128 155 195.851 146.717 185.605Z"
				fill="#6D6C6C"
			/>
			<path
				d="M215.146 136.537C219.726 139.325 225.625 143.681 230.739 150.167C242.348 164.862 242.002 180.466 241.563 186.231C239.43 191.182 235.822 197.668 229.484 204.06C225.782 207.757 220.354 213.115 211.098 216.593C207.992 217.753 205.262 218.411 203.286 218.787C201.623 217.471 199.929 216.155 198.266 214.839C203.662 209.293 209.153 203.496 214.612 197.48C218.785 192.874 222.801 188.331 226.691 183.85C226.535 180.09 225.656 173.228 220.918 166.021C218.22 161.948 215.114 159.003 212.604 156.997C213.483 150.167 214.33 143.367 215.146 136.537Z"
				fill="white"
				stroke="#161616"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				opacity={0.16}
				d="M214.926 144.151C218.91 148.318 224.338 154.992 228.574 164.298C230.582 168.716 233.845 175.891 233.908 182.659C234.127 203.245 204.949 217.408 203.254 218.223C201.591 217.157 199.96 216.123 198.297 215.058C207.741 204.718 217.153 194.378 226.597 184.038C226.409 180.654 225.625 174.356 221.86 167.651C218.754 162.136 214.894 158.564 212.353 156.59C213.232 152.454 214.079 148.318 214.926 144.151Z"
				fill="#6D6C6C"
			/>
			<path
				opacity={0.12}
				d="M226.911 184.132C226.441 186.858 225.656 189.835 224.433 192.905C218.911 206.817 207.741 214.431 202.094 217.69C200.744 216.813 199.427 215.935 198.078 215.058C207.678 204.749 217.279 194.441 226.911 184.132Z"
				fill="#6D6C6C"
			/>
			<path
				d="M222.362 186.451H145.211V235.237H222.362V186.451Z"
				fill="white"
				stroke="#161616"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d="M182.672 215.497C178.594 215.998 176.617 211.047 177.684 207.757C178.719 204.499 182.578 202.963 185.716 204.091C188.822 205.219 190.328 209.011 189.23 212.05C188.006 215.434 183.739 216.813 180.602 215.309C180.068 215.058 179.566 215.873 180.131 216.123C182.923 217.471 186.343 216.969 188.571 214.776C190.736 212.614 191.05 209.199 189.7 206.535C186.626 200.457 177.119 202.399 176.46 209.073C176.115 212.645 178.688 216.938 182.672 216.468C183.269 216.374 183.269 215.434 182.672 215.497Z"
				fill="#161616"
			/>
			<path
				opacity={0.14}
				d="M174.17 186.325C184.618 189.96 191.74 199.266 191.959 209.261C192.242 221.795 181.7 233.576 167.33 234.767C185.622 234.923 203.913 235.08 222.236 235.237C222.174 218.881 222.142 202.525 222.079 186.2C206.11 186.2 190.14 186.263 174.17 186.325Z"
				fill="#6D6C6C"
			/>
			<path
				opacity={0.26}
				d="M222.236 186.169C221.954 196.133 219.977 203.903 218.314 209.011C215.867 216.437 214.11 221.857 209.059 226.651C202.188 233.231 193.622 234.453 189.857 234.767C200.556 234.861 211.255 234.955 221.954 235.08C221.985 225.774 222.048 216.468 222.111 207.131C222.111 200.143 222.174 193.156 222.236 186.169Z"
				fill="#6D6C6C"
			/>
			<path
				d="M181.512 111.971C179.786 114.071 181.449 116.64 181.135 123.502C180.978 126.917 180.413 129.675 179.974 131.523C181.449 132.213 184.053 133.184 187.41 133.278C191.551 133.372 194.689 132.087 196.226 131.335C195.536 129.957 194.626 127.826 194.062 125.069C192.65 118.05 195.16 114.76 193.089 112.159C190.673 109.151 183.833 109.12 181.512 111.971Z"
				fill="white"
				stroke="#161616"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d="M170.562 81.8287C168.585 86.9047 169.338 91.4793 169.777 93.4533C169.589 93.4847 165.918 94.268 164.914 97.088C163.91 99.9393 165.981 103.981 170.311 105.297C171.879 109.12 175.676 116.515 183.676 120.557C185.025 121.215 186.312 121.747 187.598 122.123C188.759 121.685 190.328 120.995 192.116 119.961C197.701 116.734 200.807 112.723 201.529 111.752C202.846 109.997 204.635 107.177 205.89 103.292C206.58 103.417 207.804 103.48 208.996 102.885C211.694 101.506 213.043 97.2133 211.161 94.4247C209.592 92.106 206.549 92.012 206.172 92.012C206.58 90.3827 207.835 84.5233 204.666 78.7267C201.027 72.0527 193.214 68.5433 185.873 69.546C175.017 70.956 170.75 81.3273 170.562 81.8287Z"
				fill="white"
				stroke="#161616"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d="M181.104 120.275C183.017 121.403 185.12 123.157 187.473 122.969C189.418 122.813 191.583 121.685 193.057 120.494C193.528 120.118 192.869 119.46 192.399 119.836C191.018 120.933 188.79 122.092 186.971 122.061C185.057 122.029 183.112 120.431 181.543 119.46C181.072 119.147 180.602 119.961 181.104 120.275Z"
				fill="#161616"
			/>
			<path
				d="M180.978 120.682C182.766 122.217 184.806 123.941 187.316 123.815C189.763 123.69 192.053 122.061 193.654 120.306C194.061 119.867 193.403 119.209 192.995 119.648C191.614 121.152 189.669 122.625 187.535 122.844C185.182 123.063 183.3 121.465 181.637 120.024C181.198 119.617 180.539 120.275 180.978 120.682Z"
				fill="#161616"
			/>
			<path
				d="M182.17 120.87C182.17 120.839 182.17 120.839 182.17 120.87C181.982 120.588 181.637 120.525 181.417 120.745C181.417 120.745 181.417 120.745 181.386 120.776C181.198 120.933 181.198 121.277 181.386 121.434C184.837 124.724 191.175 125.789 193.654 120.745C193.905 120.212 193.12 119.711 192.838 120.275C190.642 124.787 185.088 123.69 182.076 120.776C182.076 120.995 182.076 121.215 182.076 121.434C182.076 121.434 182.076 121.434 182.108 121.403C181.857 121.371 181.606 121.34 181.355 121.309V121.34C181.668 121.841 182.484 121.371 182.17 120.87Z"
				fill="#161616"
			/>
			<path
				d="M185.935 91.8867C185.527 96.9627 185.747 102.007 186.563 107.021C186.594 107.271 186.908 107.428 187.128 107.334C188.351 106.927 189.23 106.206 189.889 105.109C190.202 104.577 189.387 104.107 189.073 104.639C188.539 105.548 187.881 106.081 186.877 106.425C187.065 106.519 187.253 106.645 187.441 106.739C186.626 101.819 186.437 96.8373 186.845 91.8553C186.939 91.26 185.998 91.26 185.935 91.8867Z"
				fill="#161616"
			/>
			<path
				d="M180.351 96.8687C180.413 97.1507 180.884 98.9053 180.351 99.1873C179.817 99.4693 178.311 98.3413 177.841 96.8687C177.464 95.6153 177.778 94.0487 178.374 93.9233C179.001 93.7667 179.974 95.49 180.351 96.8687Z"
				fill="#161616"
				stroke="#161616"
				strokeMiterlimit={10}
			/>
			<path
				d="M196.54 96.8687C196.603 97.1507 197.073 98.9053 196.54 99.1873C196.007 99.4693 194.501 98.3413 194.03 96.8687C193.654 95.6153 193.967 94.0487 194.563 93.9233C195.191 93.7667 196.164 95.49 196.54 96.8687Z"
				fill="#161616"
				stroke="#161616"
				strokeMiterlimit={10}
			/>
			<path
				d="M184.806 111.157C188.634 112.786 192.116 110.499 194.94 108.023C195.411 107.616 194.72 106.958 194.281 107.365C191.834 109.496 188.696 111.783 185.308 110.342C184.712 110.123 184.241 110.937 184.806 111.157Z"
				fill="#161616"
			/>
			<path
				d="M195.85 101.851C196.446 101.851 196.446 100.911 195.85 100.911C195.254 100.911 195.254 101.851 195.85 101.851Z"
				fill="#161616"
			/>
			<path
				d="M199.333 100.096C199.364 100.065 199.395 100.033 199.427 100.002C199.615 99.814 199.615 99.532 199.427 99.344C199.238 99.156 198.956 99.156 198.768 99.344C198.736 99.3753 198.705 99.4067 198.674 99.438C198.485 99.626 198.485 99.908 198.674 100.096C198.862 100.284 199.144 100.284 199.333 100.096Z"
				fill="#161616"
			/>
			<path
				d="M198.297 102.383C198.328 102.383 198.36 102.383 198.391 102.383C198.642 102.383 198.862 102.164 198.862 101.913C198.862 101.663 198.642 101.443 198.391 101.443C198.36 101.443 198.328 101.443 198.297 101.443C198.046 101.443 197.826 101.663 197.826 101.913C197.826 102.164 198.015 102.383 198.297 102.383Z"
				fill="#161616"
			/>
			<path
				d="M176.648 91.26C177.841 90.2573 179.598 90.226 180.79 91.1973C181.26 91.5733 181.919 90.9153 181.449 90.5393C179.817 89.2233 177.558 89.2233 175.958 90.602C175.519 90.978 176.209 91.636 176.648 91.26Z"
				fill="#161616"
			/>
			<path
				d="M191.959 90.9153C193.308 89.7247 195.222 89.5053 196.822 90.3513C197.356 90.6333 197.826 89.8187 197.293 89.5367C195.316 88.4713 192.963 88.7533 191.3 90.2573C190.83 90.6647 191.489 91.3227 191.959 90.9153Z"
				fill="#161616"
			/>
			<path
				d="M176.899 101.757C177.495 101.757 177.495 100.817 176.899 100.817C176.303 100.817 176.303 101.757 176.899 101.757Z"
				fill="#161616"
			/>
			<path
				d="M180.225 101.318C180.821 101.318 180.821 100.378 180.225 100.378C179.629 100.378 179.629 101.318 180.225 101.318Z"
				fill="#161616"
			/>
			<path
				d="M178.562 102.039C178.531 102.07 178.499 102.101 178.468 102.133C178.28 102.321 178.28 102.603 178.468 102.791C178.656 102.979 178.939 102.979 179.127 102.791C179.158 102.759 179.19 102.728 179.221 102.697C179.409 102.509 179.409 102.227 179.221 102.039C179.033 101.851 178.75 101.851 178.562 102.039Z"
				fill="#161616"
			/>
			<path
				d="M175.676 71.6453C173.793 72.8047 169.683 75.3427 166.891 80.544C164.098 85.714 164.13 90.602 164.255 92.7013C159.674 100.942 157.604 108.211 156.6 113.162C154.968 121.027 155.847 123.909 156.976 125.821C159.737 130.521 165.291 131.68 166.64 131.962C173.574 133.435 179.096 129.894 180.351 129.079C180.696 128.045 181.041 126.761 181.229 125.319C181.574 122.844 181.386 120.713 181.104 119.178C179.535 117.893 177.715 116.17 175.958 113.914C173.291 110.467 171.785 107.083 170.938 104.639C168.177 104.357 166.106 102.477 165.918 100.378C165.73 98.404 167.11 95.866 168.93 95.866C170.279 95.866 171.252 97.2447 171.44 97.4953C172.475 96.9 173.919 95.866 175.205 94.2367C177.056 91.8867 177.37 89.7873 178.217 87.594C179.033 85.4633 180.602 82.518 183.99 79.4473C187.253 80.6067 189.857 81.7347 191.646 82.5807C197.387 85.244 200.65 86.8107 203.192 90.226C204.603 92.106 205.325 93.9547 205.702 95.114C208.619 92.8893 209.812 93.4847 210.063 93.61C211.914 94.644 211.851 100.315 208.965 101.882C207.866 102.477 206.611 102.321 205.827 102.133C204.917 105.297 202.909 110.467 198.297 115.042C196.76 116.577 195.191 117.768 193.779 118.677C193.654 119.836 193.591 121.434 193.905 123.314C194.187 125.037 194.689 126.447 195.16 127.45C202.784 132.463 212.259 131.555 217.404 126.165C221.985 121.34 221.985 114.039 220.26 109.057C218.691 104.514 215.647 101.788 214.141 100.597C214.549 98.8427 216.808 88.44 210.251 78.8833C208.557 76.408 203.819 70.4233 195.285 68.3553C186.249 66.162 178.813 69.734 175.676 71.6453Z"
				fill="#165593"
			/>
			<path
				opacity={0.17}
				d="M195.568 84.5233C199.113 88.9727 200.054 93.0773 200.368 95.208C201.654 104.169 196.446 111.439 193.811 115.167C191.646 118.207 189.418 120.369 187.818 121.779C189.198 125.476 190.548 129.142 191.928 132.839C193.34 132.369 194.752 131.899 196.164 131.429C195.505 130.27 194.815 128.829 194.281 127.074C193.309 123.941 193.246 121.183 193.34 119.303C195.066 118.081 197.074 116.421 199.019 114.227C202.909 109.841 204.855 105.329 205.859 102.289C206.486 102.415 207.835 102.571 209.122 101.851C211.82 100.315 211.381 96.4927 211.349 96.242C211.255 95.4273 211.067 94.1113 210.126 93.5787C209.027 92.9833 207.208 93.5473 205.764 95.2393C205.325 93.704 204.07 90.1633 200.682 87.3433C198.799 85.7767 196.885 84.962 195.568 84.5233Z"
				fill="#6D6C6C"
			/>
			<path
				opacity={0.19}
				d="M186.375 92.1687C186.186 92.1373 185.559 94.8633 183.645 106.895C184.743 106.895 185.841 106.895 186.939 106.895C186.72 105.297 186.469 102.979 186.343 100.159C186.186 95.3333 186.563 92.2 186.375 92.1687Z"
				fill="#6D6C6C"
			/>
			<g
				style={{
					mixBlendMode: 'multiply',
				}}
				opacity={0.35}
			>
				<path
					d="M213.953 100.911C213.42 101.224 215.428 103.919 215.146 107.929C215.051 109.183 214.863 112.034 212.604 113.945C212.447 114.071 209.09 116.828 205.764 115.073C203.788 114.039 202.596 111.846 202.658 109.465C201.717 111.219 200.18 113.538 197.764 115.794C196.258 117.204 194.783 118.238 193.497 119.021C193.403 120.4 193.403 122.28 193.905 124.411C194.187 125.57 194.532 126.573 194.877 127.387C195.85 128.014 197.293 128.86 199.176 129.518C200.368 129.925 206.925 132.213 213.326 129.048C214.455 128.484 216.965 127.199 218.785 124.442C219.005 124.097 219.82 122.875 220.417 120.807C222.55 113.726 219.413 107.334 219.13 106.801C217.405 103.323 214.612 100.535 213.953 100.911Z"
					fill="#0A466F"
				/>
			</g>
			<g
				style={{
					mixBlendMode: 'multiply',
				}}
				opacity={0.35}
			>
				<path
					d="M170.907 104.765C170.624 104.796 171.252 107.365 170.562 109.903C170.06 111.783 168.459 114.854 165.73 115.481C162.875 116.107 159.329 114.008 157.698 110.091C156.945 111.815 155.941 114.572 155.784 118.081C155.69 120.369 155.564 122.813 156.694 125.225C159.235 130.615 166.702 132.714 172.256 132.213C175.958 131.868 178.75 130.427 180.256 129.455C180.633 128.39 181.009 127.105 181.26 125.633C181.668 123.095 181.574 120.87 181.355 119.241C178.876 117.455 177.182 115.606 176.115 114.259C174.609 112.347 172.57 109.778 171.534 106.206C171.377 105.799 171.095 104.733 170.907 104.765Z"
					fill="#0A466F"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_5442_70455">
				<rect width={360} height={271} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default ScreenMovileIlustration;
