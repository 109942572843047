import * as React from 'react';

const CopyRelleno = () => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1196_8142)">
			<path
				d="M10.4447 2.69442V0.111084H7.43077C6.7174 0.111084 6.13911 0.689374 6.13911 1.40275V9.15275C6.13911 9.86613 6.7174 10.4444 7.43077 10.4444H12.5974C13.3108 10.4444 13.8891 9.86613 13.8891 9.15275V3.55553H11.33C10.8322 3.55553 10.4447 3.16803 10.4447 2.69442ZM11.3058 0.111084V2.69442H13.8891L11.3058 0.111084ZM5.27799 9.58331V3.55553H1.40299C0.689618 3.55553 0.111328 4.13382 0.111328 4.8472V12.5972C0.111328 13.3106 0.689618 13.8889 1.40299 13.8889H6.56966C7.28304 13.8889 7.86133 13.3106 7.86133 12.5972V11.3055H7.00022C6.0503 11.3055 5.27799 10.5332 5.27799 9.58331Z"
				fill="#A5A9BA"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1196_8142">
				<rect
					width={13.7778}
					height={13.7778}
					fill="white"
					transform="translate(0.111328 0.111084)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default CopyRelleno;
