import useLocalStorage from '@Hooks/useLocalStorage';
import { QuestionnaireFields } from '@Utils/helpers/Questionnaire';

export default function usePendingQuestionnaire() {
	const [storedQuestionnaire, setStoredQuestionnaire] =
		useLocalStorage<QuestionnaireFields | null>('questionnaire', null);
	console.log(storedQuestionnaire?.create_from, 'storedQuestionnaire');

	const deleteStoredQuestionnaire = () => {
		setStoredQuestionnaire(null);
	};

	return {
		storedQuestionnaire,
		deleteStoredQuestionnaire,
	};
}
