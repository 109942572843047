import React from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Error500ViewPropsType } from './types';
import { Button } from '@Components/Buttons';
import { useDispatch } from 'react-redux';
import { CLEAR_REDUCERS } from '@Redux/store/types';
import { useHistory } from 'react-router-dom';

const Error500View: React.FunctionComponent<Error500ViewPropsType> = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const logout = () => {
		dispatch({ type: CLEAR_REDUCERS });
		localStorage.clear();
		history.push('/');
	};
	return (
		<>
			<div className="row">
				<div className="col-md-6 px-20 py-20">
					<h5
						style={{
							height: 'auto',
							left: '120px',
							top: '80px',

							fontStyle: 'normal',
							fontWeight: '300',
							fontSize: '32px',
							lineHeight: '44px',

							color: '#4D4D4D',
						}}
					>
						Error 500
					</h5>
					<h1
						style={{
							height: 'auto',

							fontStyle: 'normal',
							fontWeight: '900',
							fontSize: '8rem',
							lineHeight: '191px',

							color: '#0095E8',
						}}
					>
						¡Oops!
					</h1>

					<div
						style={{
							height: 'auto',

							fontStyle: 'normal',
							fontWeight: '800',
							fontSize: '36px',
							lineHeight: '49px',
							color: '#5E6278',
						}}
					>
						Nuestro servidor no está <br /> teniendo un buen dia...
					</div>
					<div
						className="py-14"
						style={{
							height: 'auto',

							fontStyle: 'normal',
							fontWeight: '400',
							fontSize: '24px',
							lineHeight: '33px',
							color: '#000000',
						}}
					>
						Prueba ingresando más tarde. También puedes <br />
						volver atrás; ir al inicio o contactar con nosotros <br />
						si lo prefieres.
					</div>
					<button
						style={{
							background: '#009ef7',
							padding: '14px 29px',
							borderRadius: 8,
							border: 'none',
							color: '#fff',
							fontWeight: '700',
						}}
						onClick={logout}
					>
						Desloguear cuenta
					</button>
				</div>
				<div className="col-md-6 px-20 py-20">
					<div
						className="         
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-top
					d-flex
					flex-row-auto
          min-h-100px min-h-lg-350px
        "
						style={{
							backgroundImage: `url('${toAbsoluteUrl(
								/* '/media/illustrations/sketchy-1/pixeltrue-vision-1 1.svg' */
								'/media/illustrations/sketchy-1/pixeltrue-settings-1.png'
							)}')`,

							height: '80vh',
							/* position: 'absolute',
							right: '0px',
							top: '53px', */
						}}
					>
						<img
							src="/media/illustrations/sketchy-1/Logo Evaluados.png"
							alt="ev"
							style={{
								position: 'absolute',
								width: '54px',
								height: '54px',
								right: '80px',
								top: '80px',
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Error500View;
