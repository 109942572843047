import { IUsersApi } from '..';
import { getClientFromCurrentUrl, imgUrlGetter } from '@Utils/functions';

import UserRegister, {
	UserRegisterConfirmResponse,
	UserRegisterResponse,
} from '@Configs/schemas/UserRegister';
import graphQLClient, { GraphQLCatch } from '../graphQLClient';
import {
	AiChatSubscriptionData,
	AiChatSubscriptionType,
	PhotoUserResponse,
	User,
} from '@Configs/schemas/User/User';
import { LoginType, UpdateTeacherDto } from '@Api/generated';

function getAiChatSubscriptionData(userData: Partial<LoginType>) {
	if (!userData.teacher) return;

	const {
		teacher: { ai_chat_expenses, ai_chat_subscription_teacher },
	} = userData;

	const aiSubscriptionType =
		ai_chat_subscription_teacher?.[0]?.ai_chat_subscription;

	if (!aiSubscriptionType) return;

	const data: AiChatSubscriptionData = {
		expenses:
			ai_chat_expenses?.map((expense) => ({
				amount: expense.amount,
				date: '',
				id: expense.id,
			})) ?? [],
		subscription: {
			name: aiSubscriptionType.name as AiChatSubscriptionType['name'],
			tokens: aiSubscriptionType.value,
		},
	};

	return data;
}

@GraphQLCatch()
class UsersApiGraphQL implements IUsersApi {
	async getUserRouters(): Promise<string[]> {
		const { getRoutersForUser } = await graphQLClient.GetRoutersForUser();

		return getRoutersForUser;
	}
	async isExpiredToken(token: string): Promise<boolean> {
		const res = await graphQLClient.IsExpiredToken({
			token,
		});
		return res.verifyTokenExpiration;
	}

	async checkIfEmailIsAvailable(email: string): Promise<boolean> {
		const checkEmailPromise = await graphQLClient.CheckIfEmailExists({ email });

		return checkEmailPromise.checkEmail;
	}

	async loginUserWithEmailAndPassword(
		email: string,
		password: string
	): Promise<User> {
		const { loginweb: user } = await graphQLClient.LoginWeb({
			email,
			password,
			client: getClientFromCurrentUrl(),
		});

		if (!user.token) {
			throw {
				code: user.token,
			};
		}
		console.log(user.teacher.profile_picture, 'estotre');
		return {
			...user,
			photo: user.teacher.profile_picture
				? imgUrlGetter(
						user.teacher.profile_picture?.bucket_name,
						user.teacher.profile_picture?.path
				  )
				: '',

			subscriptions: [],
			// photo: graphQLClient.getImgUrlFromFile(),
			aiChatSubscriptionData: getAiChatSubscriptionData(user as LoginType),
		};
	}

	async confirmEmailMutation(
		token: string
	): Promise<UserRegisterConfirmResponse> {
		const { confirmEmail: confirmedUser } = await graphQLClient.ConfirmEmail({
			token,
		});
		return {
			status: 'success',
			values: {
				...confirmedUser,
				token: token,
				subscriptions: [],
			},
		};
	}

	async recoveryPassword(
		token = '',
		password: string
	): Promise<PasswordChangedType> {
		const { resetPassword } = await graphQLClient.RecoveryNewPassword({
			token,
			password,
		});
		return {
			status: 'success',
			values: resetPassword,
		};
	}
	async registerUser(data: UserRegister): Promise<UserRegisterResponse> {
		const { register } = await graphQLClient.Register({
			...data,
			client: getClientFromCurrentUrl(),
		});
		console.log(register, 'registerbackend');

		return {
			status: 'success',

			values: {
				...data,
				subscriptions: [],
				...register.user,
				token: '',
			},
		};
	}

	async sendRecoveryPasswordEmail(email: string): Promise<RecoveryEmailType> {
		const { forgotPassword } = await graphQLClient.SendRecoveryPasswordEmail({
			email,
		});
		return {
			status: 'success',
			values: forgotPassword,
		};
	}

	async teacherGoogleLogin(code: string): Promise<User> {
		const { teacherGoogleLogin: data } = await graphQLClient.TeacherGoogleLogin(
			{ code, client: getClientFromCurrentUrl() }
		);

		return {
			...data,
			subscriptions: [],
			aiChatSubscriptionData: getAiChatSubscriptionData(data as LoginType),
			photo: data.teacher.profile_picture
				? imgUrlGetter(
						data.teacher.profile_picture?.bucket_name,
						data.teacher.profile_picture?.path
				  )
				: '',
		};
	}

	async PutPhotoUserEdit(
		changes: UpdateTeacherDto,
		putTeacherId: number
	): Promise<PhotoUserResponse> {
		const { putTeacher } = await graphQLClient.PutPhotoUserEdit({
			changes,
			putTeacherId,
		});
		return putTeacher;
	}

	async PutUser(
		data: UpdateTeacherDto,
		updateUsersId: number
	): Promise<Partial<User>> {
		const { updateUsers } = await graphQLClient.UpdateUser({
			updateUsersId,
			data,
		});

		return updateUsers;
	}

	async refreshUserData(token: string): Promise<User> {
		const { refreshTeacherToken: data } =
			await graphQLClient.RefreshTeacherToken({ refreshToken: token });

		return {
			...data,
			subscriptions: [],
			photo: data.teacher.profile_picture
				? imgUrlGetter(
						data.teacher.profile_picture?.bucket_name,
						data.teacher.profile_picture?.path
				  )
				: '',
			// aiChatSubscriptionData: getAiChatSubscriptionData(data as LoginType),
		};
	}
}

export default new UsersApiGraphQL();

type PasswordChangedType = {
	values?: string;
	status?: string;
	error?: string;
};

type RecoveryEmailType = {
	values?: string;
	status?: string;
	error?: string;
};
