import * as React from 'react';
const MehFace = () => (
	<svg
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.025 13C9.025 11.8938 9.91875 11 11.025 11C12.125 11 13.025 11.8938 13.025 13C13.025 14.1062 12.125 15 11.025 15C9.91875 15 9.025 14.1062 9.025 13ZM23.025 13C23.025 14.1062 22.125 15 21.025 15C19.9187 15 19.025 14.1062 19.025 13C19.025 11.8938 19.9187 11 21.025 11C22.125 11 23.025 11.8938 23.025 13ZM20.5 20.5C21.3312 20.5 22 21.1688 22 22C22 22.8312 21.3312 23.5 20.5 23.5H11.5C10.6687 23.5 10 22.8312 10 22C10 21.1688 10.6687 20.5 11.5 20.5H20.5ZM32 16C32 24.8375 24.8375 32 16 32C7.1625 32 0 24.8375 0 16C0 7.1625 7.1625 0 16 0C24.8375 0 32 7.1625 32 16ZM16 29C23.1812 29 29 23.1812 29 16C29 8.81875 23.1812 3 16 3C8.81875 3 3 8.81875 3 16C3 23.1812 8.81875 29 16 29Z"
			fill="#7E8299"
		/>
	</svg>
);
export default MehFace;
