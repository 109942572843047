import * as React from 'react';

const ChevronRight = () => (
	<svg
		width={5}
		height={10}
		viewBox="0 0 5 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.3751 -5.46307e-08C4.53502 -4.06499e-08 4.69502 0.0697546 4.8169 0.209266C5.06104 0.488286 5.06104 0.9403 4.8169 1.21932L1.50791 5.00006L4.81689 8.78068C5.06104 9.0597 5.06104 9.51171 4.81689 9.79073C4.57275 10.0698 4.17725 10.0698 3.93311 9.79073L0.183105 5.50497C-0.0610357 5.22595 -0.0610357 4.77394 0.183105 4.49492L3.93311 0.209154C4.05479 0.0691966 4.21494 -6.8632e-08 4.3751 -5.46307e-08Z"
			fill="#5E6278"
		/>
	</svg>
);

export default ChevronRight;
