import * as React from 'react';

const CheckCircle = () => (
	<svg
		width={63}
		height={63}
		viewBox="0 0 63 63"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M63 31.5C63 48.897 48.897 63 31.5 63C14.103 63 0 48.897 0 31.5C0 14.103 14.103 0 31.5 0C48.897 0 63 14.103 63 31.5ZM2.83561 31.5C2.83561 47.3309 15.6691 60.1644 31.5 60.1644C47.3309 60.1644 60.1644 47.3309 60.1644 31.5C60.1644 15.6691 47.3309 2.83561 31.5 2.83561C15.6691 2.83561 2.83561 15.6691 2.83561 31.5Z"
			fill="#50CD89"
		/>
		<g clipPath="url(#clip0_1572_14182)">
			<path
				d="M47.3286 20.6854C48.2214 21.5969 48.2214 23.0698 47.3286 23.9813L29.0429 42.6479C28.15 43.5594 26.7071 43.5594 25.8143 42.6479L16.6694 33.3146C15.7769 32.4031 15.7769 30.9302 16.6694 30.0188C17.5621 29.1073 19.0093 29.1073 19.9021 30.0188L27.3643 37.6969L44.1 20.6854C44.9929 19.7725 46.4357 19.7725 47.3286 20.6854Z"
				fill="#50CD89"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1572_14182">
				<rect
					width={32}
					height={37.3333}
					fill="white"
					transform="translate(16 13)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default CheckCircle;
