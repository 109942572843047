import { ErrorType } from '@Configs/schemas';

export const unknownError = {
	code: 'unknown',
	message: {
		title: 'Error desconocido',
		description:
			'Ha ocurrido un error desconocido. Por favor, inténtelo de nuevo más tarde.',
	},
};
// const errors = [...UserErrors, ...GlobalErrors];

// export const getErrorByCode = (errorCode: string | number) => {
// 	const currentError = errors.find((error) => error.code == errorCode);
// 	return currentError || unknownError;
// };

const isErrorType = (error: unknown): error is ErrorType =>
	(error as ErrorType).code !== undefined;

export const getErrorInfo = (error: unknown): ErrorType =>
	isErrorType(error) ? error : unknownError;
