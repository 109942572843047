import * as React from 'react';

const OjoBloqueado = () => (
	<svg
		width={20}
		height={16}
		viewBox="0 0 20 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_662_4256)">
			<path
				d="M4.70937 2.89906C6.09374 1.82093 7.86874 0.999994 9.99999 0.999994C12.525 0.999994 14.5469 2.15124 16.0187 3.51874C17.4812 4.87499 18.4594 6.47187 18.9219 7.61562C19.025 7.86249 19.025 8.13749 18.9219 8.38437C18.5031 9.39374 17.6625 10.8156 16.425 12.0844L19.7125 14.6594C20.0375 14.9156 20.0969 15.3875 19.8406 15.7125C19.5844 16.0375 19.1125 16.0969 18.7875 15.8406L0.287369 1.34031C-0.0386306 1.08468 -0.0957869 0.613432 0.159744 0.287369C0.415307 -0.0386306 0.886557 -0.0957868 1.21281 0.159744L4.70937 2.89906ZM6.97187 4.67187L9.79374 6.88437C9.92499 6.61874 9.99999 6.31874 9.99999 5.97187C9.99999 5.64062 9.87812 5.30312 9.73749 5.01249C9.82499 5.00312 9.91249 4.97187 9.99999 4.97187C11.6562 4.97187 13 6.31562 13 7.97187C13 8.42812 12.9094 8.83437 12.7219 9.20312L13.9562 10.1469C14.3031 9.50937 14.5 8.77812 14.5 7.97187C14.5 5.51562 12.4844 3.47187 9.99999 3.47187C8.83437 3.47187 7.76874 3.94374 6.97187 4.67187ZM9.99999 15C7.47499 15 5.45312 13.85 3.98124 12.4812C2.51937 11.0969 1.54187 9.49999 1.07687 8.38437C0.974369 8.13749 0.974369 7.86249 1.07687 7.61562C1.37499 6.89999 1.88406 5.97499 2.59656 5.04687L5.54374 7.36874C5.51562 7.57499 5.49999 7.78437 5.49999 7.97187C5.49999 10.4844 7.51562 12.5 9.99999 12.5C10.5844 12.5 11.1437 12.3875 11.6562 12.1844L13.9437 13.9844C12.8094 14.5969 11.4937 15 9.99999 15Z"
				fill="#A1A5B7"
			/>
		</g>
		<defs>
			<clipPath id="clip0_662_4256">
				<rect width={20} height={16} fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default OjoBloqueado;
