import { combineReducers } from 'redux';
import user from './user/reducers';
import subjects from './subjects/reducers';

const rootReducer = combineReducers({
	user,
	subjects,
});

export default rootReducer;
