import graphQLClient, { GraphQLCatch, LogReturn } from '@Api/graphQLClient';
import { IBibliographysApi } from '..';
import {
	BibliographysType,
	BibliographyTypeDelete,
	BibliographyTypeGet,
} from './types';

@LogReturn()
@GraphQLCatch()
class BibliographysApiGraphQL implements IBibliographysApi {
	async getBibliography(): Promise<BibliographyTypeGet[]> {
		const { getTeacherBibliography } = await graphQLClient.getBibliography();

		const data = getTeacherBibliography.map((bibliography) => ({
			id: bibliography.id,
			title: bibliography.name,
			chapters: bibliography.chapter.map((chapter) => chapter.name),
		}));

		return data;
	}
	async postBibliographyWithChapters(
		payload: BibliographysType
	): Promise<string> {
		const { postBibliographyWithChapters } =
			await graphQLClient.PostBibliographyWithChapters(payload);
		return postBibliographyWithChapters.id;
	}

	async deleteBibliography(id: string): Promise<void> {
		console.log(id, 'algo hicimo');
		await graphQLClient.DeleteBibliographyById({
			deleteBibliographyByIdId: +id,
		});
	}
}

export default new BibliographysApiGraphQL();
