import * as React from 'react';

const IconoInformacion = () => (
	<svg
		width={36}
		height={36}
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={36} height={36} rx={18} fill="#ADE5FF" />
		<path
			d="M20 23.9999H19V16.9999C19 16.4471 18.5522 15.9999 18 15.9999H16C15.4478 15.9999 15 16.4471 15 16.9999C15 17.5527 15.4478 17.9718 16 17.9718H17V23.9718H16C15.4478 23.9718 15 24.419 15 24.9718C15 25.5246 15.4478 25.9718 16 25.9718H20C20.5522 25.9718 21 25.5246 21 24.9718C21 24.419 20.5531 23.9999 20 23.9999ZM18 13.9999C18.8284 13.9999 19.5 13.3284 19.5 12.4999C19.5 11.6715 18.8281 11.0002 18 11.0002C17.1719 11.0002 16.5 11.6718 16.5 12.5002C16.5 13.3287 17.1716 13.9999 18 13.9999Z"
			fill="#165593"
		/>
	</svg>
);

export default IconoInformacion;
