import * as React from 'react';

const CirculitoRojoError = () => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={24} height={24} rx={12} fill="#FFAABF" />
		<path
			d="M6.89015 6.8899C7.18769 6.59236 7.6701 6.59236 7.96765 6.8899L12.0003 10.9226L16.033 6.8899C16.3305 6.59236 16.813 6.59236 17.1105 6.8899C17.408 7.18745 17.408 7.66986 17.1105 7.9674L13.0778 12.0001L17.1105 16.0328C17.408 16.3303 17.408 16.8127 17.1105 17.1103C16.813 17.4078 16.3305 17.4078 16.033 17.1103L12.0003 13.0776L7.96765 17.1103C7.6701 17.4078 7.18769 17.4078 6.89015 17.1103C6.59261 16.8127 6.59261 16.3303 6.89015 16.0328L10.9228 12.0001L6.89015 7.9674C6.59261 7.66986 6.59261 7.18745 6.89015 6.8899Z"
			fill="#F1416C"
		/>
	</svg>
);

export default CirculitoRojoError;
