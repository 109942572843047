import * as React from 'react';

const TXTfile = () => (
	<svg
		width={56}
		height={56}
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			style={{
				mixBlendMode: 'darken',
			}}
		>
			<path
				d="M0 7.91853C0 3.55097 3.5875 0 8 0H28V15.8371C28 18.027 29.7875 19.7963 32 19.7963H48V37.613H22C17.5875 37.613 14 41.164 14 45.5316V63.3483H8C3.5875 63.3483 0 59.7973 0 55.4297V7.91853ZM48 15.8371H32V0L48 15.8371Z"
				fill="#5222D0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M35.039 43.3165C35.9883 42.6765 37.215 43.0382 37.7651 44.1202L40.0528 48.6202L42.3669 44.1378C42.9233 43.0601 44.1521 42.7079 45.0977 43.3552C46.0432 44.0025 46.3616 45.4139 45.8052 46.4916L42.7703 52.3701L42.3616 53.1618L45.7661 59.8586C46.3162 60.9407 45.9895 62.3495 45.0402 62.9895C44.0909 63.6294 42.8642 63.2677 42.3141 62.1857L40.0263 57.6854L37.7123 62.1676C37.1559 63.2453 35.927 63.5975 34.9815 62.9502C34.036 62.3029 33.7176 60.8915 34.274 59.8138L37.7174 53.1438L37.3135 52.3492L34.3131 46.4473C33.763 45.3653 34.0897 43.9564 35.039 43.3165ZM25 43.1528H30C31.1 43.1528 32 44.0437 32 45.1324C32 46.2212 31.1 47.1121 30 47.1121H27V51.0713L27 53.051L27 55.0306L27 60.9695C27 61.9903 26.209 62.837 25.2037 62.9389C25.1367 62.9457 25.0687 62.9491 25 62.9491C23.9 62.9491 23 62.0583 23 60.9695V55.0306V51.0713V47.1121H20C18.9 47.1121 18 46.2212 18 45.1324C18 44.0437 18.9 43.1528 20 43.1528H25ZM55 43.1528H60C61.1 43.1528 62 44.0437 62 45.1324C62 46.2212 61.1 47.1121 60 47.1121H57V51.0713V55.0306V60.9695C57 61.9903 56.209 62.837 55.2037 62.9389C55.1367 62.9457 55.0687 62.9491 55 62.9491C53.9 62.9491 53 62.0583 53 60.9695V55.0306V51.0713V47.1121H50C48.9 47.1121 48 46.2212 48 45.1324C48 44.0437 48.9 43.1528 50 43.1528H55Z"
				fill="#5222D0"
			/>
		</g>
	</svg>
);
export default TXTfile;
