import { ISubjectsApi } from '..';
import { CaseResult, SubjectStudents } from '@Configs/schemas/Subject';
import { Subject, Syllabus } from '@Configs/schemas/Subject/Subject';
import { SubjectFormSchema } from '@Utils/helpers/Subject';
import {
	PostSubjectMutationVariables,
	UpdateSubjectMutationVariables,
} from '../generated';
import graphQLClient, { GraphQLCatch } from '../graphQLClient';
import { Permissions } from '@Configs/schemas/Permissions';
import { StudentType, CaseResultType, CaseType } from '@Api/subjects/types';
import User from '@Configs/schemas/User/User';
import { getClientFromCurrentUrl } from '@Utils/functions';

@GraphQLCatch()
class SubjectsApiGQL implements ISubjectsApi {
	async getSubjectSyllabus(subjectId: string): Promise<Syllabus> {
		const { getSubjectSyllabus: syllabus } =
			await graphQLClient.GetSubjectSyllabus({ subjectId: +subjectId });

		const mappedData: Syllabus = {
			files: [],
			subjectId,
			bibliography: syllabus.bibliography,
			country: '',
			teacherEmails: [],
			description: syllabus.name,
			name: syllabus.name,
			id: syllabus.id,
			globalLearningGoal: syllabus.learning_goals.join(','),
			modules: (
				syllabus.syllabus_structure_children as Array<typeof syllabus>
			).map((u) => ({
				coreTopics: (
					u.syllabus_structure_children as Array<typeof syllabus>
				).map((c) => c.name),
				extraBibliography: u.bibliography,
				id: u.id,
				name: u.name,
				specificLearningGoal: u.learning_goals,
			})),
		};

		return mappedData;
	}
	async getTeacherSyllabus(): Promise<Syllabus[]> {
		const { getTeacherSyllabus: syllabus } =
			await graphQLClient.GetTeacherSyllabus();

		const mappedData = syllabus
			.filter((s) => !!s.subject?.id)
			.map<Syllabus>((s) => ({
				bibliography: s.bibliography,
				country: '',
				teacherEmails: [],
				description: s.name,
				name: s.name,
				id: s.id,
				globalLearningGoal: s.learning_goals.join('--'),
				modules: (s.syllabus_structure_children as typeof syllabus).map(
					(u) => ({
						id: u.id,
						name: u.name,
						extraBibliography: u.bibliography,
						specificLearningGoal: u.learning_goals,
						coreTopics: u.syllabus_structure_children.map(
							(c: (typeof syllabus)[number]) => c.name
						),
					})
				),
				subjectId: s.subject!.id,
				files:
					s.syllabus_files?.map((f) => ({
						id: f.fileId.toString(),
						name: f.file?.name ?? '',
					})) ?? [],
			}));

		return mappedData;
	}

	/**
	 * This function checks if a subject code is available
	 * @param code The code for the subject
	 * @param subjectId The ID of the subject to check (if it exists)
	 * @returns A promise that resolves to a boolean indicating if the code is available
	 */
	async checkIfCodeIsAvailable(
		code: string,
		subjectId?: string
	): Promise<boolean> {
		const checkSubjectCodePromise = graphQLClient.CheckIfSubjectCodeExists({
			code,
		});

		const checkSubjectPromise = subjectId
			? graphQLClient.GetSubjectByID({
					id: +subjectId,
			  })
			: null;

		const [{ checkSubjectCode }, subject] = await Promise.all([
			checkSubjectCodePromise,
			checkSubjectPromise,
		]);

		if (checkSubjectCode) {
			return subject?.getSubjectByID?.code === code;
		}

		return true;
	}
	async getSubjectStudents(subjectId: string): Promise<SubjectStudents> {
		const {
			getSubjectStudents: { cases },
		} = await graphQLClient.GetSubjectStudents({
			subjectId: +subjectId,
		});

		const questionnaires = cases.filter((e) => e.caseType.id === '1');

		const mappedData: SubjectStudents = [];

		function mapCaseResult(
			caseResult: CaseResultType
		): Omit<CaseResult, 'case'> {
			const mappedCaseResult: Omit<CaseResult, 'case'> = {
				id: caseResult.id,
				createdAt: caseResult.created_at.toString(),
				timeSpent: caseResult.time_spent,
				score: caseResult.score,
			};

			return mappedCaseResult;
		}

		const getAnsweredQuestionnairesCount = (
			questionnaires: CaseType[],
			studentId: string
		) => {
			return questionnaires.filter((c) =>
				c.caseResults.find((cr) => cr.student.id === studentId)
			).length;
		};

		const getStudentInfo = (student: StudentType) => ({
			id: student.id,
			fullName: User.getFullName(student?.user),
			mail: student.user.email,
			averageScore: null,
		});

		const getCasesResults = (c: CaseType) => {
			c.caseResults.forEach((cr) => {
				const studentCaseResult = {
					...mapCaseResult(cr),
					case: {
						description: c.description ?? '',
						id: c.id,
						name: c.name,
						caseTypeId: c.caseType.id,
					},
				};
				if (
					!mappedData.find(
						(s) => s.fullName === User.getFullName(cr.student.user)
					)
				) {
					const totalQuestionnaires = questionnaires.length;
					mappedData.push({
						...getStudentInfo(cr.student),
						answeredQuestionnaires: `${getAnsweredQuestionnairesCount(
							questionnaires,
							cr.student.id
						)}/${totalQuestionnaires}`,
						caseResults: [studentCaseResult],
					});
				} else {
					const index = mappedData.findIndex(
						(s) => s.fullName === User.getFullName(cr.student.user)
					);
					mappedData[index].caseResults.push(studentCaseResult);
				}
			});
		};

		questionnaires.forEach(getCasesResults);

		return mappedData.filter((student) => student.caseResults.length > 0);
	}

	async DuplicateSubject(id: number, data: SubjectFormSchema): Promise<number> {
		const { duplicateSubjectWithContents } =
			await graphQLClient.duplicateSubjectWithContents({
				subjectId: id,
				data: {
					client: getClientFromCurrentUrl(),
					code: data.subjectCode,
					date_from: data.subjectFrom,
					date_to: data.subjectTo,
					description: data.subjectDescription ?? 'Descripcion nula',
					name: data.subjectName,
					student_ammount: +(data.studentQuantity ?? 0),
					subject_status: 1,
				},
			});

		return +duplicateSubjectWithContents.id;
	}
	async updateSubject(data: SubjectFormSchema): Promise<string> {
		const values: UpdateSubjectMutationVariables = {
			data: {
				client: getClientFromCurrentUrl(),
				code: data.subjectCode,
				date_from: (data.subjectFrom as Date).toISOString(),
				date_to: (data.subjectTo as Date).toISOString(),
				description: data.subjectDescription ?? 'Descripcion nula',
				name: data.subjectName,
				student_ammount: +(data.studentQuantity ?? 0),
				subject_status: 1,
			},
			id: +(data.subjectId ?? 0),
		};

		const { updateSubject } = await graphQLClient.UpdateSubject(values);

		return updateSubject.id;
	}

	async DeleteSubjectById(id: number): Promise<void> {
		await graphQLClient.deleteSubjectById({ id });
	}
	async DeleteCaseResultBySubject(subjectId: number): Promise<void> {
		await graphQLClient.deleteCaseResultBySubject({ subjectId });
	}
	async GetSubjectByID(id: number): Promise<Subject> {
		const { getSubjectByID } = await graphQLClient.GetSubjectByID({ id });

		const mappedSubject: Subject = {
			id: getSubjectByID.id,
			code: getSubjectByID.code,
			createdAt: getSubjectByID.createdAt,
			dateFrom: getSubjectByID.dateFrom,
			dateTo: getSubjectByID.dateTo,
			name: getSubjectByID.name,
			updatedAt: getSubjectByID.updatedAt,
			description: getSubjectByID.description ?? 'Descripcion nula',
			studentAmount: getSubjectByID.student_ammount ?? 0,
			permissions: (getSubjectByID.permissions as Permissions) ?? [],
		};
		return mappedSubject;
	}

	async getSubjects(): Promise<{
		data: Subject[];
		globalPermissions: string[];
	}> {
		const { getSubjects } = await graphQLClient.GetSubjects();

		return {
			data: getSubjects.data.map((data) => ({
				id: data.id,
				code: data.code,
				createdAt: data.createdAt,
				dateFrom: data.dateFrom,
				dateTo: data.dateTo,
				name: data.name,
				updatedAt: data.updatedAt,
				description: data.description ?? 'Descripcion nula',
				studentAmount: data.studentAmmount ?? 0,
				permissions: (data.permissions as Permissions) ?? [],

				averageScore: data.averageScore ?? 0,
			})),
			globalPermissions: getSubjects.globalPermissions,
		};
	}
	async PostSubject(data: SubjectFormSchema): Promise<string> {
		const values: PostSubjectMutationVariables = {
			subject: {
				client: getClientFromCurrentUrl(),
				code: data.subjectCode,
				date_from: (data.subjectFrom as Date).toISOString(),
				date_to: (data.subjectTo as Date).toISOString(),
				description: data.subjectDescription ?? 'Descripcion nula',
				name: data.subjectName,
				student_ammount: +(data.studentQuantity ?? 0),
				subject_status: 1,
			},
		};

		const { postSubject } = await graphQLClient.PostSubject(values);
		return postSubject.id;
	}
}

export default new SubjectsApiGQL();
