import * as React from 'react';

const BellCentroAyuda = () => (
	<svg
		width={16}
		height={16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.7063 13.2937C15.0969 13.6844 15.0969 14.3156 14.7063 14.7063C14.3156 15.0969 13.6844 15.0969 13.2937 14.7063L12.8781 14.3188C11.55 15.3719 9.85 16 7.97188 16C6.15 16 4.45 15.3719 3.09437 14.3188L2.70719 14.7063C2.31656 15.0969 1.68344 15.0969 1.29281 14.7063C0.9025 14.3156 0.9025 13.6844 1.29281 13.2937L1.68 12.8781C0.627187 11.55 0 9.85 0 7.97188C0 6.15 0.627187 4.45 1.68 3.09437L1.29281 2.70719C0.9025 2.31656 0.9025 1.68344 1.29281 1.29281C1.68344 0.9025 2.31656 0.9025 2.70719 1.29281L3.09437 1.68C4.45 0.627187 6.15 0 7.97188 0C9.85 0 11.55 0.627187 12.8781 1.68L13.2937 1.29281C13.6844 0.9025 14.3156 0.9025 14.7063 1.29281C15.0969 1.68344 15.0969 2.31656 14.7063 2.70719L14.3188 3.09437C15.3719 4.45 16 6.15 16 7.97188C16 9.85 15.3719 11.55 14.3188 12.8781L14.7063 13.2937ZM9.66562 11.0781C9.16875 11.3469 8.60313 11.5 7.97188 11.5C7.39688 11.5 6.83125 11.3469 6.33437 11.0781L4.525 12.8906C5.50313 13.5906 6.70312 14 7.97188 14C9.29688 14 10.4969 13.5906 11.475 12.8906L9.66562 11.0781ZM14 7.97188C14 6.70312 13.5906 5.50313 12.8906 4.525L11.0781 6.33437C11.3469 6.83125 11.5 7.39687 11.5 8C11.5 8.60312 11.3469 9.16875 11.0781 9.66562L12.8906 11.475C13.5906 10.4969 14 9.29688 14 8V7.97188ZM7.97188 1.97187C6.70312 1.97187 5.50313 2.41062 4.525 3.10938L6.33437 4.92188C6.83125 4.65312 7.39688 4.47188 7.97188 4.47188C8.60313 4.47188 9.16875 4.65312 9.66562 4.92188L11.475 3.10938C10.4969 2.41062 9.29688 1.97187 8 1.97187H7.97188ZM4.92188 9.66562C4.65312 9.16875 4.47188 8.60313 4.47188 7.97188C4.47188 7.39688 4.65312 6.83125 4.92188 6.33437L3.10938 4.525C2.41062 5.50313 1.97187 6.70312 1.97187 7.97188C1.97187 9.29688 2.41062 10.4969 3.10938 11.475L4.92188 9.66562ZM7.97188 6.47188C7.17188 6.47188 6.47188 7.17188 6.47188 7.97188C6.47188 8.82812 7.17188 9.47188 7.97188 9.47188C8.82812 9.47188 9.5 8.82812 9.5 7.97188C9.5 7.17188 8.82812 6.47188 7.97188 6.47188Z"
			fill="#A5A9BA"
		/>
	</svg>
);

export default BellCentroAyuda;
