import PrivateRoute from '@Routing/PrivateRoute';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const DashboardPage = lazy(() => import('@Pages/Dashboard'));
const SubjectPage = lazy(() => import('@Pages/Subjects'));
const WelcomePage = lazy(() => import('@Pages/Welcome'));
const SurveyCreationPage = lazy(() => import('@Pages/SurveyCreation'));
const QuestionnaireCreationPage = lazy(
	() => import('@Pages/QuestionnaireCreation')
);
const QuestionnaireIACreationPage = lazy(
	() => import('@Pages/QuestionnaireIACreation')
);
const QuestionnaireStatisticsPage = lazy(
	() => import('@Pages/QuestionnaireStatistics')
);
const SurveyStatsPage = lazy(() => import('@Pages/SurveyStats'));
const BibliographyLoadPage = lazy(() => import('@Pages/BibliographyLoad'));

export const evaluadosAppRoutes = [
	{
		path: '/onboarding',
		element: (
			<PrivateRoute
				path="/onboarding"
				key="/onboarding"
				component={WelcomePage}
			/>
		),
	},
	{
		path: '/subjects',
		element: (
			<PrivateRoute path="/subjects" key="/subjects" component={SubjectPage} />
		),
	},
	{
		path: '/dashboard',
		element: (
			<PrivateRoute
				path="/dashboard"
				key="/dashboard"
				component={DashboardPage}
			/>
		),
	},
	{
		path: '/subject/:subjectId/questionnaire/:questionnaireId/statistics',
		element: (
			<PrivateRoute
				path="/subject/:subjectId/questionnaire/:questionnaireId/statistics"
				key="/subject/:subjectId/questionnaire/:questionnaireId/statistics"
				component={QuestionnaireStatisticsPage}
			/>
		),
	},
	{
		path: '/subject/:subjectId/survey/:surveyId/statistics',
		element: (
			<PrivateRoute
				path="/subject/:subjectId/survey/:surveyId/statistics"
				key="/subject/:subjectId/survey/:surveyId/statistics"
				component={SurveyStatsPage}
			/>
		),
	},
	{
		path: '/subject/:subjectId/questionnaire/:questionnaireId',
		element: (
			<PrivateRoute
				path="/subject/:subjectId/questionnaire/:questionnaireId"
				key="/subject/:subjectId/questionnaire/:questionnaireId"
				component={QuestionnaireCreationPage}
			/>
		),
	},
	{
		path: '/subject/:subjectId/questionnaireIA/:questionnaireId',
		element: (
			<PrivateRoute
				path="/subject/:subjectId/questionnaireIA/:questionnaireId"
				key="/subject/:subjectId/questionnaireIA/:questionnaireId"
				component={QuestionnaireIACreationPage}
			/>
		),
	},
	{
		path: '/subject/:subjectId/survey/:surveyId',
		element: (
			<PrivateRoute
				path="/subject/:subjectId/survey/:surveyId"
				key="/subject/:subjectId/survey/:surveyId"
				component={SurveyCreationPage}
			/>
		),
	},
	{
		path: '/bibliography',
		element: (
			<PrivateRoute
				path="/bibliography"
				key="/bibliography"
				component={BibliographyLoadPage}
			/>
		),
	},
	{
		path: '/',
		element: (
			<Redirect from="/" to="/dashboard" key="evaluados-app-redirect" exact />
		),
	},
];
