import { UpdateTeacherDto } from '@Api/generated';
import { ErrorType as ErrorType } from '@Configs/schemas';
import {
	AuthedUser,
	PhotoUserResponse,
	User,
} from '@Configs/schemas/User/User';
import { ClearReducersAction } from '../types';
import { getUserRoutersAction } from './actions';

// ACTION TYPES
export const SET_LOGIN_LOADING: 'SET_LOGIN_LOADING' = 'SET_LOGIN_LOADING';

// user login
export const LOGIN_USER_WITH_EMAIL_PASSWORD: 'LOGIN_USER_WITH_EMAIL_PASSWORD' =
	'LOGIN_USER_WITH_EMAIL_PASSWORD';
export const LOGIN_USER_WITH_EMAIL_PASSWORD_SUCCESS: 'LOGIN_USER_WITH_EMAIL_PASSWORD_SUCCESS' =
	'LOGIN_USER_WITH_EMAIL_PASSWORD_SUCCESS';
export const LOGIN_USER_WITH_EMAIL_PASSWORD_ERROR: 'LOGIN_USER_WITH_EMAIL_PASSWORD_ERROR' =
	'LOGIN_USER_WITH_EMAIL_PASSWORD_ERROR';

export const SET_REGISTER_CONFIRM_LOADING: 'SET_REGISTER_CONFIRM_LOADING' =
	'SET_REGISTER_CONFIRM_LOADING';
export const REGISTER_CONFIRM_USER: 'REGISTER_CONFIRM_USER' =
	'REGISTER_CONFIRM_USER';
export const REGISTER_CONFIRM_USER_SUCCESS: 'REGISTER_CONFIRM_USER_SUCCESS' =
	'REGISTER_CONFIRM_USER_SUCCESS';
export const REGISTER_CONFIRM_USER_ERROR: 'REGISTER_CONFIRM_USER_ERROR' =
	'REGISTER_CONFIRM_USER_ERROR';
export const REGISTER_CONFIRM_USER_STATUS: 'REGISTER_CONFIRM_USER_STATUS' =
	'REGISTER_CONFIRM_USER_STATUS';

export const TOGGLE_FIRST_USE = 'TOGGLE_FIRST_USE' as const;
export const SOCIAL_LOGIN_USER = 'SOCIAL_LOGIN_USER' as const;
export const SOCIAL_LOGIN_USER_SUCCESS = 'SOCIAL_LOGIN_USER_SUCCESS' as const;
export const SOCIAL_LOGIN_USER_ERROR = 'SOCIAL_LOGIN_USER_ERROR' as const;
export const UPDATE_USER = 'UPDATE_USER' as const;
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS' as const;
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR' as const;
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING' as const;
export const UPDATE_PHOTO_USER = 'UPDATE_PHOTO_USER' as const;
export const UPDATE_PHOTO_USER_SUCCESS = 'UPDATE_PHOTO_USER_SUCCESS' as const;
export const UPDATE_PHOTO_USER_ERROR = 'UPDATE_PHOTO_USER_ERROR' as const;
export const UPDATE_PHOTO_USER_LOADING = 'UPDATE_PHOTO_USER_LOADING' as const;
export const UPDATE_USER_AFTER_SUCCES = 'UPDATE_USER_AFTER_SUCCES' as const;
export const GET_USER_ROUTERS = 'GET_USER_ROUTERS' as const;
export const GET_USER_ROUTERS_SUCCESS = 'GET_USER_ROUTERS_SUCCESS' as const;
export const GET_USER_ROUTERS_ERROR = 'GET_USER_ROUTERS_ERROR' as const;

// ACTION CREATORS TYPES
export type SetLoginLoadingAction = {
	type: typeof SET_LOGIN_LOADING;
	payload: {
		loading: boolean;
	};
};

export type LoginUserWithEmailPasswordAction = {
	type: typeof LOGIN_USER_WITH_EMAIL_PASSWORD;
	payload: {
		email: string;
		password: string;
	};
};

export type LoginUserWithEmailPasswordSuccessAction = {
	type: typeof LOGIN_USER_WITH_EMAIL_PASSWORD_SUCCESS;
	payload: {
		data: User;
	};
};

export type LoginUserWithEmailPasswordErrorAction = {
	type: typeof LOGIN_USER_WITH_EMAIL_PASSWORD_ERROR;
	payload: {
		error: ErrorType;
	};
};

export type SetRegisterConfirmUserLoadingAction = {
	type: typeof SET_REGISTER_CONFIRM_LOADING;
	payload: {
		loading: boolean;
	};
};

export type RegisterConfirmUserAction = {
	type: typeof REGISTER_CONFIRM_USER;
	payload: {
		token: string | null;
	};
};

export type RegisterConfirmUserSuccessAction = {
	type: typeof REGISTER_CONFIRM_USER_SUCCESS;
	payload: {
		data: User;
		loading: boolean;
		status: string | null;
	};
};

export type RegisterConfirmUserErrorAction = {
	type: typeof REGISTER_CONFIRM_USER_ERROR;
	payload: {
		status: string | null;
		error: ErrorType;
	};
};

export type RegisterConfirmUserStatusAction = {
	type: typeof REGISTER_CONFIRM_USER_STATUS;
	payload: {
		status: string | null;
		loading: boolean;
	};
};

export type ToggleFirstUseAction = {
	type: typeof TOGGLE_FIRST_USE;
};

export type SocialLoginUserAction = {
	type: typeof SOCIAL_LOGIN_USER;
	payload: {
		code: string;
	};
};

export type SocialLoginUserSuccessAction = {
	type: typeof SOCIAL_LOGIN_USER_SUCCESS;
	payload: {
		data: User;
	};
};

export type SocialLoginUserErrorAction = {
	type: typeof SOCIAL_LOGIN_USER_ERROR;
	payload: {
		error: ErrorType;
	};
};

export type UpdateUserAction = {
	type: typeof UPDATE_USER;
	payload: {
		id: number;
		data: Partial<User>;
	};
};

export type UpdateUserSuccessAction = {
	type: typeof UPDATE_USER_SUCCESS;
	payload: {
		data: Partial<User>;
	};
};

export type UpdateUserErrorAction = {
	type: typeof UPDATE_USER_ERROR;
	payload: {
		error: ErrorType;
	};
};

export type UpdateLoadingAction = {
	type: typeof UPDATE_USER_LOADING;
	payload: {
		loading: boolean;
	};
};

export type UpdatePhotoUserAction = {
	type: typeof UPDATE_PHOTO_USER;
	payload: {
		putTeacherId: number;
		changes: UpdateTeacherDto;
	};
};

export type UpdatePhotoUserSuccessAction = {
	type: typeof UPDATE_PHOTO_USER_SUCCESS;
	payload: PhotoUserResponse;
};

export type UpdatePhotoUserErrorAction = {
	type: typeof UPDATE_PHOTO_USER_ERROR;
	payload: {
		error: ErrorType;
	};
};

export type UpdatePhotoLoadingAction = {
	type: typeof UPDATE_PHOTO_USER_LOADING;
	payload: {
		loading: boolean;
	};
};

export type UpdateAfterSuccesAction = {
	type: typeof UPDATE_USER_AFTER_SUCCES;
	payload: {
		modal: boolean;
		status: string | null;
	};
};

export type GetUserRoutersAction = {
	type: typeof GET_USER_ROUTERS;
};

export type GetUserRoutersSuccessAction = {
	type: typeof GET_USER_ROUTERS_SUCCESS;
	payload: {
		data: string[];
	};
};

export type GetUserRoutersErrorAction = {
	type: typeof GET_USER_ROUTERS_ERROR;
	payload: {
		error: ErrorType;
	};
};
export type UserActions =
	| SetLoginLoadingAction
	| ClearReducersAction
	| LoginUserWithEmailPasswordAction
	| LoginUserWithEmailPasswordSuccessAction
	| LoginUserWithEmailPasswordErrorAction
	| ClearReducersAction
	| SetRegisterConfirmUserLoadingAction
	| RegisterConfirmUserAction
	| RegisterConfirmUserSuccessAction
	| RegisterConfirmUserErrorAction
	| RegisterConfirmUserStatusAction
	| ToggleFirstUseAction
	| SocialLoginUserAction
	| SocialLoginUserSuccessAction
	| SocialLoginUserErrorAction
	| UpdateUserAction
	| UpdateUserSuccessAction
	| UpdateUserErrorAction
	| UpdateLoadingAction
	| UpdatePhotoUserAction
	| UpdatePhotoUserSuccessAction
	| UpdatePhotoUserErrorAction
	| UpdatePhotoLoadingAction
	| UpdateAfterSuccesAction
	| GetUserRoutersAction
	| GetUserRoutersSuccessAction
	| GetUserRoutersErrorAction;

// STATE TYPES
export type UserStateType = {
	data: User | null;
	login: {
		loading: boolean;
		error: ErrorType | null;
	};
	registerConfirm: {
		loading: boolean;
		status: string | null;
		error: ErrorType | null;
	};
	updateProfile: {
		loading: boolean;
		error: ErrorType | null;
		modal: boolean;
		status: string | null;
	};
};

const reduxTypes = {
	SET_LOGIN_LOADING,
	LOGIN_USER_WITH_EMAIL_PASSWORD,
	LOGIN_USER_WITH_EMAIL_PASSWORD_SUCCESS,
	LOGIN_USER_WITH_EMAIL_PASSWORD_ERROR,
	SET_REGISTER_CONFIRM_LOADING,
	REGISTER_CONFIRM_USER,
	REGISTER_CONFIRM_USER_SUCCESS,
	REGISTER_CONFIRM_USER_ERROR,
	REGISTER_CONFIRM_USER_STATUS,
	TOGGLE_FIRST_USE,
	SOCIAL_LOGIN_USER,
	SOCIAL_LOGIN_USER_SUCCESS,
	SOCIAL_LOGIN_USER_ERROR,
	UPDATE_USER,
	UPDATE_USER_LOADING,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_ERROR,
	UPDATE_PHOTO_USER,
	UPDATE_PHOTO_USER_LOADING,
	UPDATE_PHOTO_USER_SUCCESS,
	UPDATE_PHOTO_USER_ERROR,
	UPDATE_USER_AFTER_SUCCES,
	GET_USER_ROUTERS,
	GET_USER_ROUTERS_SUCCESS,
	GET_USER_ROUTERS_ERROR,
};

export default reduxTypes;
