import { SubjectStateType } from './subjects/types';
import { UserStateType } from './user/types';
export type ClearReducersAction = {
	type: typeof CLEAR_REDUCERS;
};

// REDUX
export const CLEAR_REDUCERS = 'CLEAR_REDUCERS';

export type StoreType = {
	user: UserStateType;
	subjects: SubjectStateType;
};
