import { QuestionnaireWithResults } from '@Configs/schemas/Case/Questionnaire';
import { Student } from '@Configs/schemas/Student';

export type statusType = 'active' | 'trash' | 'draft';

export interface Case {
	id: string;
	description: string;
	name: string;
	dateFrom: string;
	dateTo: string;
	subjectId: string;
	questions?: StepType[];
	caseStatus?: CaseStatusType;
}

export interface StepType {
	id: string;
	title: string;
	order?: number;
	options?: StepOptionType[];
	created_at: string;
}

export interface StepOptionType {
	id: string;
	text: string;
	created_at: string;
}

export interface CaseStatusType {
	id: string;
	status: statusType;
}

const LOW_USE_THRESHOLD = 4;

const stepTypes = {
	'1': 'multiple_choice',
	'2': 'single_choice',
	'3': 'free_text',
} as const;

function getStepTypeNameById(
	id: string
): (typeof stepTypes)[keyof typeof stepTypes] {
	return stepTypes[id as keyof typeof stepTypes] ?? stepTypes[1];
}

function getStepTypeIdByName(name: string): number {
	return +(
		(Object.keys(stepTypes) as Array<keyof typeof stepTypes>).find(
			(key) => stepTypes[key] === name
		) ?? 1
	);
}

const getQuestionnaireStudentScores = (
	questionnaire: QuestionnaireWithResults
): { [studentId: string]: number } => {
	const studentScores = {} as { [studentId: string]: number };
	for (const result of questionnaire.questionnaireResults) {
		studentScores[result.studentId] = result.score;
	}
	return studentScores;
};

export type StudentsByPerformance = {
	LowUse: {
		studentId: string;
		unansweredQuestionnairesAmount: number;
	}[];
	Disapproved: {
		studentId: string;
		doneQuestionnairesAmount: number;
		averageScore: number;
	}[];
	'0Right': {
		studentId: string;
		allIncorrectQuestionnaireIds: string[];
	}[];
};

function getLowUseStudents(
	students: Student[],
	data: QuestionnaireWithResults[]
) {
	const studentsAnsweredQuestionnaires = [] as [
		studentId: string,
		answeredQuestionnairesAmount: number
	][];

	for (const [index, student] of students.entries()) {
		studentsAnsweredQuestionnaires.push([student.id, 0]);
		for (const questionnaire of data) {
			if (
				questionnaire.questionnaireResults.some(
					(r) => r.studentId === student.id
				)
			) {
				studentsAnsweredQuestionnaires[index][1]++;
			}
		}
	}

	return studentsAnsweredQuestionnaires
		.filter((s) => s[1] < LOW_USE_THRESHOLD)
		.map((s) => ({
			studentId: s[0],
			unansweredQuestionnairesAmount: s[1],
		}));
}

function mapStudentsByPerformance(
	questionnaires: QuestionnaireWithResults[] | undefined,
	students: Student[] | undefined
) {
	const studentsByPerformance: StudentsByPerformance = {
		LowUse: [],
		Disapproved: [],
		'0Right': [],
	};

	if (!questionnaires || !students) return studentsByPerformance;

	const questionnairesById: {
		[questionnaireId: string]: { [studentId: string]: number };
	} = {};

	const answeredQuestionnaires = questionnaires.filter(
		(q) => q.questionnaireResults.length > 0
	);

	for (const questionnaire of answeredQuestionnaires) {
		const studentScores = getQuestionnaireStudentScores(questionnaire);
		questionnairesById[questionnaire.id] = studentScores;
	}

	const scoreListByStudent = {} as {
		[studentId: string]: number[];
	};

	Object.keys(questionnairesById).forEach((key) => {
		const studentIds = Object.keys(questionnairesById[key]);
		studentIds.forEach((studentId) => {
			const studentScore = questionnairesById[key][studentId];

			if (!scoreListByStudent[studentId]) {
				scoreListByStudent[studentId] = [studentScore];
			} else {
				scoreListByStudent[studentId].push(studentScore);
			}

			// Get students with 0 right answers
			if (studentScore !== 0) return;

			const student = studentsByPerformance['0Right'].find(
				(s) => s.studentId === studentId
			);
			if (student) {
				student.allIncorrectQuestionnaireIds.push(key);
			} else {
				studentsByPerformance['0Right'].push({
					studentId,
					allIncorrectQuestionnaireIds: [key],
				});
			}
		});
	});

	// Get students with disapproved average score
	Object.keys(scoreListByStudent).forEach((studentId) => {
		const studentScores = scoreListByStudent[studentId];
		const studentAverageScore =
			studentScores.reduce((acc, score) => acc + score, 0) /
			studentScores.length;
		console.log(studentAverageScore);

		if (studentAverageScore >= 40) return;

		studentsByPerformance.Disapproved.push({
			studentId,
			doneQuestionnairesAmount: scoreListByStudent[studentId].length,
			averageScore: studentAverageScore,
		});
	});

	studentsByPerformance.LowUse = getLowUseStudents(students, questionnaires);

	return studentsByPerformance;
}

export default {
	getStepTypeNameById,
	getStepTypeIdByName,
	mapStudentsByPerformance,
};
