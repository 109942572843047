import * as React from 'react';
const SadFace = () => (
	<svg
		width={32}
		height={32}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.2063 23.0812C10.3813 23.4062 9.56875 22.8188 9.9 22.0562C10.9437 19.6687 13.3937 17.9875 16.2437 17.9875C19.1 17.9875 21.55 19.6688 22.5875 22.0063C22.9187 22.825 22.0562 23.4062 21.2875 23.0812C19.7625 22.4375 18.05 22.075 16.2437 22.075C14.4375 22.075 12.7312 22.3812 11.2063 23.0812ZM13.025 13C13.025 14.1062 12.125 15 11.025 15C9.91875 15 9.025 14.1062 9.025 13C9.025 11.8938 9.91875 11 11.025 11C12.125 11 13.025 11.8938 13.025 13ZM19.025 13C19.025 11.8938 19.9187 11 21.025 11C22.125 11 23.025 11.8938 23.025 13C23.025 14.1062 22.125 15 21.025 15C19.9187 15 19.025 14.1062 19.025 13ZM32 16C32 24.8375 24.8375 32 16 32C7.1625 32 0 24.8375 0 16C0 7.1625 7.1625 0 16 0C24.8375 0 32 7.1625 32 16ZM16 3C8.81875 3 3 8.81875 3 16C3 23.1812 8.81875 29 16 29C23.1812 29 29 23.1812 29 16C29 8.81875 23.1812 3 16 3Z"
			fill="#7E8299"
		/>
	</svg>
);
export default SadFace;
