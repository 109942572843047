import * as React from 'react';

const BlueImage = () => (
	<svg
		width={36}
		height={36}
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={36} height={36} rx={6} fill="#F1FAFF" />
		<g clipPath="url(#clip0_1400_13169)">
			<path
				d="M25.4647 8C24.0506 8 10.4647 8 10.4647 8C9.11862 8 7.99951 9.11914 7.99951 10.5V25.5C7.99951 26.8809 9.11865 28 10.4644 28H25.4644C26.8452 28 27.9644 26.8809 27.9644 25.5V10.5C27.9647 9.11914 26.8788 8 25.4647 8ZM12.3397 11.75C13.3753 11.75 14.2147 12.5895 14.2147 13.625C14.2147 14.6605 13.41 15.5 12.3397 15.5C11.2694 15.5 10.4647 14.6605 10.4647 13.625C10.4647 12.5895 11.3389 11.75 12.3397 11.75ZM25.4256 23.9219C25.3163 24.125 25.1053 24.25 24.8749 24.25H11.2034C10.9682 24.25 10.753 24.118 10.6464 23.9082C10.5397 23.6986 10.5598 23.4469 10.6985 23.2566L13.4328 19.5066C13.5506 19.3438 13.7381 19.25 13.9374 19.25C14.1366 19.25 14.3247 19.3454 14.4424 19.5068L15.7104 21.2455L19.3549 15.8119C19.4725 15.6055 19.6678 15.5 19.8749 15.5C20.0819 15.5 20.2792 15.6044 20.3948 15.7783L25.3948 23.2783C25.5233 23.4688 25.535 23.7148 25.4256 23.9219Z"
				fill="#009EF7"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1400_13169">
				<rect width={20} height={20} fill="white" transform="translate(8 8)" />
			</clipPath>
		</defs>
	</svg>
);

export default BlueImage;
