import * as React from 'react';

const MasIcon = () => (
	<svg
		width={15}
		height={15}
		viewBox="0 0 15 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.1879 7.7564C14.1879 8.33792 13.7168 8.80867 13.136 8.80867H8.40224V13.5424C8.40224 14.1239 7.93117 14.594 7.3503 14.594C6.76943 14.594 6.29836 14.1239 6.29836 13.5424V8.80867H1.56463C0.983767 8.80867 0.512695 8.33792 0.512695 7.7564C0.512695 7.17487 0.983767 6.70479 1.56463 6.70479H6.29836V1.97106C6.29836 1.38954 6.76943 0.918793 7.3503 0.918793C7.93117 0.918793 8.40224 1.38954 8.40224 1.97106V6.70479H13.136C13.7178 6.70446 14.1879 7.17455 14.1879 7.7564Z"
			fill="white"
		/>
	</svg>
);

export default MasIcon;
