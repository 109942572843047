import * as React from 'react';

const Encuesta = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 34 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0 8C0 3.58172 3.58172 0 8 0H26C30.4183 0 34 3.58172 34 8V28C34 32.4183 30.4183 36 26 36H8C3.58172 36 0 32.4183 0 28V8Z"
			fill="#F1FAFF"
		/>
		<g clipPath="url(#clip0_269_1692)">
			<path
				d="M19.375 15.8125C19.375 13.1547 16.8293 11 13.6875 11C10.5457 11 7.99999 13.1547 7.99999 15.8125C7.99999 16.8945 8.4271 17.8895 9.13941 18.6945C8.69124 19.5903 8.06671 20.2619 8.05686 20.2717C7.99944 20.332 7.98386 20.4214 8.01746 20.5001C8.05194 20.5785 8.12583 20.625 8.20944 20.625C9.25808 20.625 10.1443 20.3209 10.8273 19.9677C11.6695 20.3816 12.643 20.625 13.6875 20.625C16.8293 20.625 19.375 18.4703 19.375 15.8125ZM24.3953 23.1324C25.0844 22.3176 25.5 21.2977 25.5 20.1875C25.5 17.5297 23.1485 15.375 20.25 15.375C20.2414 15.375 20.2331 15.3761 20.2245 15.3762C20.2363 15.5199 20.25 15.6648 20.25 15.8125C20.25 18.5091 18.0713 20.7672 15.1613 21.3496C15.7301 23.4441 17.7645 25 20.25 25C21.1712 25 22.0361 24.781 22.7889 24.399C23.4547 24.7266 24.2996 25 25.2895 25C25.3731 25 25.447 24.9528 25.4814 24.8741C25.515 24.7954 25.4994 24.706 25.442 24.6457C25.4344 24.6363 24.841 23.9965 24.3953 23.1324Z"
				fill="#009EF7"
			/>
		</g>
		<defs>
			<clipPath id="clip0_269_1692">
				<rect
					width={17.5}
					height={14}
					fill="white"
					transform="translate(8 11)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default Encuesta;
