import * as S from './styled';
import CustomIcon from '@Assets/images/icons';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CLEAR_REDUCERS } from '@Redux/store/types';

type TokenLogOutModalProps = {
	show: boolean;
	toggleShow: () => void;
};

const LogoutModal = ({ show, toggleShow }: TokenLogOutModalProps) => {
	const dispatch = useDispatch();
	const logOut = () => {
		dispatch({ type: CLEAR_REDUCERS });
		localStorage.clear();
	};

	return (
		<Modal show={show} size="sm" centered>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					padding: '2rem',
				}}
			>
				<S.DivIconCustonContainer>
					<CustomIcon
						icon="circle-exclamation"
						style={{ width: '63px', height: '63px' }}
					/>
				</S.DivIconCustonContainer>
				<S.TitleModal>¡Tu sesión ha expirado!</S.TitleModal>
				<S.SubTitleModal>Vuelve a iniciar sesión</S.SubTitleModal>
				<button
					style={{
						background: '#009ef7',
						padding: '14px 29px',
						borderRadius: 8,
						border: 'none',
						color: '#fff',
					}}
					onClick={() => {
						toggleShow();
						logOut();
					}}
				>
					Aceptar
				</button>
			</div>
		</Modal>
	);
};

export default LogoutModal;
