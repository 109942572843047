/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { Suspense, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, selectUserRouters } from '@Redux/store/user/selectors';
import { Plans } from '@Configs/schemas/User/User';
import { evaluadosAppRoutes } from '@Routing/EvaluadosAppRoutes';
import { evaluadosAIRoutes } from '@Routing/EvaluadosAIRoutes';
import { sharedRoutes } from '@Routing/SharedRoutes';
import { adminRoutes } from './AdminRoutes';

import { getUserRoutersAction } from '@Redux/store/user/actions';
import { setGqlHeader } from '@Api/graphQLClient';

const Routes = () => {
	const history = useHistory();

	const userRouters = useSelector(selectUserRouters);

	const user = useSelector(selectUser);
	const isUserFirstUse = !!user.data?.is_first_use;

	const dispatch = useDispatch();

	useEffect(() => {
		if (user.data?.token) {
			setGqlHeader(user.data.token);
			dispatch(getUserRoutersAction());
		}
	}, [user.data?.token]);

	useEffect(() => {
		if (isUserFirstUse) history.replace('/onboarding');
	}, [isUserFirstUse]);

	const hasSubscription = (plan: string) => userRouters.includes(plan);

	if (user.data && userRouters.length === 0) return null;

	return (
		<Suspense fallback={FallbackView}>
			<Switch>
				{hasSubscription(Plans.EVALUADOS_APP) &&
					evaluadosAppRoutes.map((r) => r.element)}
				{hasSubscription(Plans.EVALUADOS_AI) &&
					evaluadosAIRoutes.map((r) => r.element)}
				{userRouters.includes('AdminDashboard') &&
					adminRoutes.map((r) => r.element)}
				{sharedRoutes.map((r) => r.element)}
			</Switch>
		</Suspense>
	);
};

export { Routes };
