import * as React from 'react';
const Detective = () => (
	<svg
		width={16}
		height={16}
		viewBox="0 0 16 16"
		style={{ marginTop: -4 }}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8 0.5C7.79063 0.5 7.6625 0.4125 7.51562 0.309375C7.30937 0.16875 7.0625 0 6.5 0C5.54688 0 4.875 1.36563 4.4375 2.79375C2.95938 3.06562 2 3.50625 2 4C2 4.44688 2.78125 4.84687 4.01875 5.12187C4.00625 5.24687 4 5.37187 4 5.5C4 6.03125 4.10313 6.5375 4.29063 7H2.41875C2.1875 7 2 7.1875 2 7.41875C2 7.47187 2.00937 7.525 2.03125 7.575L3.24375 10.6031C1.88125 11.6187 1 13.2437 1 15.0719C1 15.5844 1.41563 16 1.92813 16H14.0719C14.5844 16 15 15.5844 15 15.0719C15 13.2437 14.1187 11.6219 12.7594 10.6031L13.9688 7.575C13.9875 7.525 14 7.47187 14 7.41875C14 7.1875 13.8125 7 13.5813 7H11.7094C11.8969 6.5375 12 6.03125 12 5.5C12 5.37187 11.9937 5.24687 11.9812 5.12187C13.2188 4.84687 14 4.44688 14 4C14 3.50625 13.0406 3.06562 11.5625 2.79375C11.125 1.36563 10.4531 0 9.5 0C8.9375 0 8.69063 0.16875 8.48438 0.309375C8.3375 0.4125 8.20937 0.5 8 0.5ZM9.75 7H9.3625C8.84688 7 8.39062 6.66875 8.22813 6.18125C8.15625 5.9625 7.84688 5.9625 7.775 6.18125C7.6125 6.66875 7.15312 7 6.64062 7H6.25C5.55937 7 5 6.44063 5 5.75V5.3C5.88125 5.42813 6.90625 5.5 8 5.5C9.09375 5.5 10.1187 5.42813 11 5.3V5.75C11 6.44063 10.4406 7 9.75 7ZM7 10L7.5 11L6.5 15L5 9L7 10ZM11 9L9.5 15L8.5 11L9 10L11 9Z"
			fill="#7B61FF"
		/>
	</svg>
);
export default Detective;
