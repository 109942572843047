import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CLEAR_REDUCERS, StoreType } from '@Redux/store/types';
import fotoEva from '@Assets/images/logos/fotoEva.png'
import { useIntl } from 'react-intl';
import { selectUser } from '@Redux/store/user/selectors';
import User from '@Configs/schemas/User/User';



const HeaderUserMenu: FC = () => {
	
	/* const user = {
		first_name: 'Pedro del Castillo',
		email: 'john@example.com',
	}; */
	
	const dispatch = useDispatch();

	const {data:userData} = useSelector(selectUser);
	const {email=''}=userData||{}
	let {photo}=userData||{}

	const intl = useIntl();
	photo === null || photo === undefined || photo === '' ? photo = fotoEva : photo = photo
	
	const logout = () => {
		dispatch({ type: CLEAR_REDUCERS });
		localStorage.clear(); 
	};

	

	const canEdit = !email.includes('asuc');

	return (
		<div
			className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-900 menu-state-primary fw-bold py-4 fs-6  w-lg-325px"
			data-kt-menu="true"
		>
			<div className="menu-item px-3">
				<div className="menu-content d-flex align-items-center px-3">
					<div className="symbol symbol-50px me-5">
					<img src={ photo } alt="usuario" />
					</div>

					<div className="d-flex flex-column flex-wrap" style={{width:'288px'}}>
						<div style={{width:'189px'}} className="fw-bolder d-flex flex-wrap align-items-center fs-5">
							{User.getFullName(userData)}
							{/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span> */}
						</div>
						<div style={{width:'189px', wordBreak: 'break-word'}} className="fw-bold d-flex flex-wrap text-muted  fs-7">
							{email}
						</div>
					</div>
				</div>
			</div>

			<div className="separator my-2"></div>

			{canEdit && (
				<div className="menu-item fw-bolder bg-hover-light px-5 my-1">
					<Link to="/edit-profile" className="menu-link px-5">
					{intl.formatMessage({ id: "NAVBAR.EDITPROFILE.ITEM" })}
					</Link>
				</div>
			)}
			<div className="menu-item fw-bolder bg-hover-light px-5 my-1">
				<a  href="https://evaluados.ai/terminos-y-condiciones/" className="menu-link px-5">
					{intl.formatMessage({ id: "NAVBAR.PRIVACYPOLICY.ITEM" })}
				</a>
			</div>

			<div className="menu-item fw-bolder bg-hover-light px-5">
				<a onClick={logout} className="menu-link px-5">
					{intl.formatMessage({ id: "NAVBAR.LOGOUT.ITEM" })}
				</a>
			</div>
			{/* <div className="menu-item px-5">
				<Link to={'/crafted/pages/profile'} className="menu-link px-5">
					My Profile
				</Link>
			</div>

			<div className="menu-item px-5">
				<a href="#" className="menu-link px-5">
					<span className="menu-text">My Projects</span>
					<span className="menu-badge">
						<span className="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
					</span>
				</a>
			</div>

			<div
				className="menu-item px-5"
				data-kt-menu-trigger="hover"
				data-kt-menu-placement="left-start"
				data-kt-menu-flip="bottom"
			>
				<a href="#" className="menu-link px-5">
					<span className="menu-title">My Subscription</span>
					<span className="menu-arrow"></span>
				</a>

				<div className="menu-sub menu-sub-dropdown w-175px py-4">
					<div className="menu-item px-3">
						<a href="#" className="menu-link px-5">
							Referrals
						</a>
					</div>

					<div className="menu-item px-3">
						<a href="#" className="menu-link px-5">
							Billing
						</a>
					</div>

					<div className="menu-item px-3">
						<a href="#" className="menu-link px-5">
							Payments
						</a>
					</div>

					<div className="menu-item px-3">
						<a href="#" className="menu-link d-flex flex-stack px-5">
							Statements
							<i
								className="fas fa-exclamation-circle ms-2 fs-7"
								data-bs-toggle="tooltip"
								title="View your statements"
							></i>
						</a>
					</div>

					<div className="separator my-2"></div>

					<div className="menu-item px-3">
						<div className="menu-content px-3">
							<label className="form-check form-switch form-check-custom form-check-solid">
								<input
									className="form-check-input w-30px h-20px"
									type="checkbox"
									value="1"
									defaultChecked={true}
									name="notifications"
								/>
								<span className="form-check-label text-muted fs-7">Notifications</span>
							</label>
						</div>
					</div>
				</div>
			</div>

			<div className="menu-item px-5">
				<a href="#" className="menu-link px-5">
					My Statements
				</a>
			</div>

			<div className="separator my-2"></div> */}

			{/* <Languages /> */}

		</div>
	);
};

export { HeaderUserMenu };
