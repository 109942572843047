import * as React from 'react';
interface IProps {
	className?: string;
	style?: React.CSSProperties;
	color?: string;
}
const GreenPencil = (props: IProps) => (
	<svg
		width={46}
		height={43}
		viewBox="0 0 46 43"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16.666 21.9417C16.6827 19.4417 17.866 17.1 19.866 15.6L39.591 0.800418C41.141 -0.362749 43.2994 -0.247082 44.7243 1.075C46.141 2.39667 46.3994 4.545 45.3493 6.1725L31.8743 27.0083C30.6077 28.975 28.5493 30.2667 26.2743 30.5917L16.666 21.9417ZM24.666 33.3333C24.666 38.4917 20.491 42.6667 15.3327 42.6667H3.33268C1.86018 42.6667 0.666016 41.475 0.666016 40C0.666016 38.525 1.86018 37.3333 3.33268 37.3333H3.73352C5.20268 37.3333 6.19935 35.7583 6.04852 34.3C6.01602 33.9833 5.99935 33.6583 5.99935 33.3333C5.99935 28.3 9.99102 24.1917 14.9743 24.0083L24.6493 32.7083C24.6577 32.8417 24.666 33.125 24.666 33.3333Z"
			fill={props.color ? props.color : '#009F91'}
		/>
	</svg>
);

export default GreenPencil;
