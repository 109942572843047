import * as React from 'react';

const ArrowRight = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 12 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.7487 5.74915L7.4628 10.0351C7.29672 10.2038 7.07706 10.2869 6.85741 10.2869C6.63776 10.2869 6.41864 10.2031 6.25149 10.0357C5.91665 9.70089 5.91665 9.15846 6.25149 8.82362L9.07537 6.00095H0.857139C0.383814 6.00095 0 5.61789 0 5.14376C0 4.66963 0.383814 4.28658 0.857139 4.28658H9.07537L6.25203 1.46324C5.91719 1.1284 5.91719 0.585964 6.25203 0.251128C6.58686 -0.0837092 7.1293 -0.0837092 7.46414 0.251128L11.75 4.53704C12.0835 4.87322 12.0835 5.41431 11.7487 5.74915Z"
			fill="#A1A5B7"
		/>
	</svg>
);

export default ArrowRight;
