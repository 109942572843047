import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider } from '../_metronic/layout/core';
import { Routes } from '@Routing/Routes';
import NetworkStatusProvider from '@Components/Providers/NetworkStatusProvider';
import { LayoutSplashScreen } from '../_metronic/layout/core/MetronicSplashScreen';
import ResponsiveHandler from '@Components/ResponsiveHandler';
import { MasterInit } from '../_metronic/layout/MasterInit';
import LogoutMonitor from '@Components/Logout/Monitor';

type Props = {
	basename: string;
};
/* <LogoutMonitor /> */

const App: React.FC<Props> = ({ basename }) => {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<BrowserRouter basename={basename}>
				<I18nProvider>
					<LayoutProvider>
						<NetworkStatusProvider>
							<ResponsiveHandler>
								<Routes />
								<MasterInit />
								<LogoutMonitor />
							</ResponsiveHandler>
						</NetworkStatusProvider>
					</LayoutProvider>
				</I18nProvider>
			</BrowserRouter>
		</Suspense>
	);
};

export { App };
