import types, { SelectSubjectAction } from './types';
import {
	GetSubjectsErrorAction,
	GetSubjectsAction,
	GetSubjectsSuccessAction,
} from './types';

export const getSubjectsAction = (): GetSubjectsAction => ({
	type: types.GET_SUBJECTS,
});

export const getSubjectsSuccessAction = (
	payload: GetSubjectsSuccessAction['payload']
): GetSubjectsSuccessAction => ({
	type: types.GET_SUBJECTS_SUCCESS,
	payload,
});

export const getSubjectsErrorAction = (
	payload: GetSubjectsErrorAction['payload']
): GetSubjectsErrorAction => ({
	type: types.GET_SUBJECTS_ERROR,
	payload,
});

export const selectSubjectAction = (
	payload: SelectSubjectAction['payload']
): SelectSubjectAction => ({
	type: types.SELECT_SUBJECT,
	payload,
});
