import * as React from 'react';
type Props = {
	color?: string;
};
const FileImport = ({ color }: Props) => (
	<svg
		width={16}
		height={16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12 0V4H16L12 0ZM11 4V0H5.5C4.67188 0 4 0.671562 4 1.5V9H9.44063L8.22094 7.78031C7.92797 7.48734 7.92797 7.01281 8.22094 6.71969C8.51391 6.42656 8.98844 6.42672 9.28156 6.71969L11.7816 9.21969C12.0745 9.51266 12.0745 9.98719 11.7816 10.2803L9.28156 12.7803C8.98859 13.0733 8.51406 13.0733 8.22094 12.7803C8.07188 12.6344 8 12.4438 8 12.25C8 12.0562 8.07325 11.8663 8.21972 11.7197L9.44063 10.5H4V14.5C4 15.3281 4.67188 16 5.5 16H14.5C15.3284 16 16 15.3284 16 14.5V5H12.0281C11.4469 5 11 4.55312 11 4ZM0.75 9C0.335938 9 0 9.33438 0 9.75C0 10.1641 0.335938 10.5 0.75 10.5H4V9H0.75Z"
			fill={color ? color : '#009EF7'}
		/>
	</svg>
);

export default FileImport;
