import * as React from 'react';

const RedCircleWithX = () => (
	<svg
		width={36}
		height={36}
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={36} height={36} rx={18} fill="#FFAABF" />
		<path
			d="M10.3347 10.3347C10.781 9.88842 11.5047 9.88842 11.951 10.3347L18 16.3838L24.049 10.3347C24.4953 9.88842 25.219 9.88842 25.6653 10.3347C26.1116 10.781 26.1116 11.5047 25.6653 11.951L19.6162 18L25.6653 24.049C26.1116 24.4953 26.1116 25.219 25.6653 25.6653C25.219 26.1116 24.4953 26.1116 24.049 25.6653L18 19.6162L11.951 25.6653C11.5047 26.1116 10.781 26.1116 10.3347 25.6653C9.88842 25.219 9.88842 24.4953 10.3347 24.049L16.3838 18L10.3347 11.951C9.88842 11.5047 9.88842 10.781 10.3347 10.3347Z"
			fill="#F1416C"
		/>
	</svg>
);

export default RedCircleWithX;
