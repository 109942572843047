import graphQLClient, { GraphQLCatch, LogReturn } from '@Api/graphQLClient';
import { IAiChatSubscriptionsApi } from '@Api/index';
import {
	AcademicProduct,
	AcademicProductAnswer,
} from '@Configs/schemas/AcademicProduct/AcademicProduct';
import { AiChatSubscription } from '@Configs/schemas/AiChatSubscription/AiChatSubscription';
import crypto from 'crypto-js';

const JWTSecret = process.env.REACT_APP_JWT_SECRET ?? '';

@LogReturn()
@GraphQLCatch()
class AiChatSubscriptionsApiGraphQL implements IAiChatSubscriptionsApi {
	async getSubjectAcademicObjectAnswers(
		subjectId: string
	): Promise<AcademicProductAnswer[]> {
		const { getSubjectAcademicObjectAnswers: academicObjects } =
			await graphQLClient.GetSubjectAcademicObjectAnswers({
				subjectId: +subjectId,
			});

		return academicObjects.map((a) => {
			let academicProduct;
			if (a.academic_object) {
				academicProduct = {
					description: '',
					prompt: '',
					id: a.academic_object.id,
					name: a.academic_object.title,
				};
			}
			const mappedData = {
				id: a.id,
				subjectName: a.subject.name,
				clientId: '',
				subjectId: '',
				bibliography: a.bibliography,
				module: a.module,
				topic: a.topic,
				content: a.content,
				academic_product: academicProduct,
				reviews: a.academic_product_answer_review.map((r) => ({
					question: r.question,
					type_name: r.type_name,
					value: r.value,
				})) as AcademicProductAnswer['reviews'],
			};
			return mappedData;
		});
	}
	async getAcademicObjectsForSubject(
		subjectId: string
	): Promise<AcademicProduct[]> {
		const { getAcademicObjectsForSubject: academicObjects } =
			await graphQLClient.GetAcademicObjectsForSubject({
				subjectId: +subjectId,
			});

		return academicObjects.map((ao) => ({
			description: ao.description,
			id: ao.id,
			name: ao.title,
			prompt: '',
		}));
	}
	async deleteAcademicProductAnswer(id: number): Promise<void> {
		await graphQLClient.DeleteAcademicProductAnswer({
			academicProductAnswerId: id,
		});
	}
	async putAcademicProductResult(data: AcademicProductAnswer): Promise<string> {
		const academic_product = data.academic_product?.id
			? +data.academic_product?.id
			: undefined;
		const {
			updateAcademicProductAnswer: { id },
		} = await graphQLClient.UpdateAcademicProductAnswer({
			data: {
				id: +data.id,
				academic_product,
				content: data.content,
				module: data.module,
				topic: data.topic,
				bibliography: data.bibliography,
				client: +data.clientId,

				academic_product_answer_review: data.reviews.map((review) => ({
					...review,
				})),
			},
		});
		return id;
	}

	async postAcademicProductResult(
		data: AcademicProductAnswer
	): Promise<string> {
		const academic_product = data.academic_product?.id
			? +data.academic_product?.id
			: undefined;
		const { postAcademicProductAnswer: academicProductAnswer } =
			await graphQLClient.PostAcademicProductAnswer({
				data: {
					academic_product,
					content: data.content,
					module: data.module,
					topic: data.topic,
					bibliography: data.bibliography,
					client: +data.clientId,

					subject: +data.subjectId,
					academic_product_answer_review: data.reviews.map((review) => ({
						...review,
					})),
				},
			});

		return academicProductAnswer.id;
	}

	async postAiChatExpense(tokens: number): Promise<void> {
		const { addExpense } = await graphQLClient.AddExpense({
			value: tokens,
		});
		return;
	}
	async getAiChatSubscription(): Promise<AiChatSubscription> {
		const { getTeacherAIEnergy: res } =
			await graphQLClient.GetAiChatSubscriptionData();
		return res;
	}
}

export default new AiChatSubscriptionsApiGraphQL();
