import { CustomIconProps, CustomIconOptions } from '@Assets/images/icons';
import * as React from 'react';

const Megafono = ({
	primaryColor = '#5222D0',
	secondaryColor = '#B398EA',
}: CustomIconOptions) => (
	<svg
		width={36}
		height={36}
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width={36} height={36} rx={18} fill={secondaryColor} />
		<g clipPath="url(#clip0_857_6292)">
			<path
				d="M25 15.6126C25.5813 15.8876 26 16.6282 26 17.5001C26 18.372 25.5813 19.1126 25 19.3876V24.0001C25 24.4032 24.7563 24.7689 24.3813 24.9251C24.0094 25.0782 23.5781 24.9939 23.2937 24.7064L21.9281 23.3157C20.4281 21.8157 18.3938 21.0001 16.2719 21.0001H16V25.0001C16 25.5532 15.5531 26.0001 15 26.0001H13C12.4478 26.0001 12 25.5532 12 25.0001V21.0001C10.8953 21.0001 10 20.1032 10 19.0001V16.0001C10 14.897 10.8953 14.0001 12 14.0001H16.2719C18.3938 14.0001 20.4281 13.1564 21.9281 11.657L23.2937 10.293C23.5781 10.007 24.0094 9.92149 24.3813 10.0763C24.7563 10.2311 25 10.5957 25 11.0001V15.6126ZM16.2719 16.0001H16V19.0001H16.2719C18.7656 19.0001 21.1625 19.9314 23 21.6032V13.397C21.1625 15.0689 18.7656 16.0001 16.2719 16.0001Z"
				fill={primaryColor}
			/>
		</g>
		<defs>
			<clipPath id="clip0_857_6292">
				<rect
					width={16}
					height={16}
					fill="white"
					transform="translate(10 10)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default Megafono;
