import * as React from 'react';

const Ellipse = () => (
	<svg
		width={63}
		height={63}
		viewBox="0 0 63 63"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M63 31.5C63 48.897 48.897 63 31.5 63C14.103 63 0 48.897 0 31.5C0 14.103 14.103 0 31.5 0C48.897 0 63 14.103 63 31.5ZM2.83561 31.5C2.83561 47.3309 15.6691 60.1644 31.5 60.1644C47.3309 60.1644 60.1644 47.3309 60.1644 31.5C60.1644 15.6691 47.3309 2.83561 31.5 2.83561C15.6691 2.83561 2.83561 15.6691 2.83561 31.5Z"
			fill="#50CD89"
		/>
	</svg>
);

export default Ellipse;
