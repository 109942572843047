import * as React from 'react';

type Props = {
	color?: string;
};

const XmarkDanger = ({ color }: Props) => (
	<svg
		width={12}
		height={10}
		viewBox="0 1 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.334735 0.334735C0.781049 -0.111578 1.50467 -0.111578 1.95098 0.334735L8 6.38376L14.049 0.334735C14.4953 -0.111578 15.219 -0.111578 15.6653 0.334735C16.1116 0.781049 16.1116 1.50467 15.6653 1.95098L9.61624 8L15.6653 14.049C16.1116 14.4953 16.1116 15.219 15.6653 15.6653C15.219 16.1116 14.4953 16.1116 14.049 15.6653L8 9.61624L1.95098 15.6653C1.50467 16.1116 0.781049 16.1116 0.334735 15.6653C-0.111578 15.219 -0.111578 14.4953 0.334735 14.049L6.38376 8L0.334735 1.95098C-0.111578 1.50467 -0.111578 0.781049 0.334735 0.334735Z"
			fill={color ? color : '#F1416C'}
		/>
	</svg>
);

export default XmarkDanger;
