import * as React from 'react';
import { SVGProps } from 'react';

const PaperPlaneTop = ({
	width = 16,
	height = 17,
	...props
}: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15.1791 7.1349C13.3757 6.35477 11.5723 5.57465 9.76887 4.79452C7.05363 3.6294 4.35865 2.46428 1.65354 1.28902C1.65354 1.28902 1.64341 1.28902 1.63328 1.28902C0.82276 0.944551 -0.0384174 1.65376 0.00210859 2.52506V2.59599C0.0223716 2.86954 0.0932921 3.14309 0.225002 3.38624C0.630262 4.1461 1.57249 5.89885 2.09933 6.90187C2.2513 7.19569 2.54511 7.39832 2.87945 7.42871C4.0851 7.57055 5.29075 7.73266 6.4964 7.88463C7.06376 7.95555 7.621 8.01634 8.18836 8.09739C8.27954 8.10752 8.40112 8.15818 8.45178 8.2291C8.48217 8.26963 8.48217 8.31015 8.48217 8.35068C8.48217 8.3912 8.48217 8.43173 8.45178 8.47225C8.40112 8.54318 8.28967 8.59383 8.18836 8.60396C7.63113 8.68502 7.06376 8.74581 6.4964 8.81673C5.29075 8.9687 4.0851 9.12067 2.87945 9.27264C2.54511 9.31317 2.26143 9.50567 2.09933 9.79948C1.57249 10.7924 0.630262 12.5553 0.225002 13.3151C0.0932921 13.5583 0.0223716 13.8318 0.00210859 14.1054V14.1763C-0.0485489 15.0476 0.82276 15.7568 1.62315 15.4225C1.62315 15.4225 1.63328 15.4225 1.64341 15.4225C4.34852 14.2573 7.05363 13.0821 9.75874 11.917C11.5622 11.1368 13.3656 10.3567 15.169 9.57659C15.7161 9.34356 16.0099 8.87752 15.9997 8.36081C16.0099 7.8441 15.7161 7.37805 15.169 7.14503L15.1791 7.1349Z"
			fill="#5E6278"
		/>
	</svg>
);
export default PaperPlaneTop;
