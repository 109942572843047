import Api from '@Api/index';
import LogoutModal from '../Modal';
import { selectUser } from '@Redux/store/user/selectors';
import { User } from '@sentry/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// function areUserEqual(user1: User, user2: User) {
// 	const { token: token1, ...data1 } = user1;
// 	const { token: token2, ...data2 } = user2;

// 	return isEqual(data1, data2);
// }

async function shouldLogout(user: User) {
	if (!user.token) return true;
	const res = await Api.Users.isExpiredToken(user.token);

	return res;
}

export default function LogoutMonitor() {
	const user = useSelector(selectUser);

	const [isTokenExpired, setIsTokenExpired] = useState(false);

	useEffect(() => {
		if (!user.data) return;
		shouldLogout(user.data).then(setIsTokenExpired);
	}, []);

	const toggleShow = () => setIsTokenExpired(!isTokenExpired);

	return <LogoutModal show={isTokenExpired} toggleShow={toggleShow} />;
}
