import { useEffect, useState } from 'react';

export default function useLocalStorage<T>(key: string, initialValue: T) {
	const loadStoredValue = () => {
		try {
			// Get from local storage by key
			const item = localStorage.getItem(key);
			console.log('🚀 ~ file: useLocalStorage.tsx ~ line 13 ~ item', item);
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	};
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<T>(loadStoredValue);

	useEffect(() => {
		setStoredValue(loadStoredValue());
	}, []);
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: T | ((val: T) => T)) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage

			if (value === null) {
				localStorage.removeItem(key);
				return;
			}
			localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};
	return [storedValue, setValue] as const;
}
